/*Sidebar Css*/

.details-main-content {
    display: flex;
    width: calc(100% - 60px);
    justify-content: space-between;
    padding: 20px 30px;
    align-items: flex-start;
}

.details-main-content .details-sidebar {
    width: 25%;
    margin-right: 25px;
    border-radius: 8px;
    background-color: #FFFFFF;
}

.details-main-content .details-sidebar>div {
    margin-bottom: 24px;
}

.details-main-content .details-sidebar>div.latest-news {
    background-color: #FFF;
}

.latest-news h5 {
    text-align: center;
    margin: 4px 0px;
}

.details-main-content .details-sidebar>div.latest-news .news {
    padding: 14px 0;
    border-bottom: 0.5px solid rgba(56, 56, 56, 0.16);
}

.details-main-content .details-sidebar>div.latest-news h4 {
    padding: 8px 18px;
}

.details-main-content .details-sidebar>div.latest-news .news p {
    border-left: 5px solid #FD6C73;
    padding: 0px 13px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #707070;
}

.details-main-content .details-sidebar>div.latest-news .news a {

    color: #0A72B0;
    display: block;
    text-align: right;
    padding: 0 13px;
    margin-top: 10px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

}

.details-main-content .details-sidebar>div.latest-news .news a:hover {
    color: #095A8B;
    cursor: pointer;
}

.details-main-content .details-sidebar a.dsidebar {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #283B4C;

    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.details-main-content .details-sidebar a.active {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #FFF;
    background-color: #FD6C73;
    z-index: 1;
    transform: scale(1);
    transition: 700ms;
}


.details-main-content .details-sidebar a.dsidebar:hover {

    color: #FFF;
    background-color: #FD6C73;
    z-index: 1;
    transform: scale(1);
    transition: 700ms;
}

.details-main-content .details-sidebar>div.recommended {
    background-color: #FFF;
    padding: 24px 14px 0 14px;
}

.details-main-content .details-sidebar>div.recommended>div.recdiv {
    border-bottom: 1px solid rgba(0, 0, 0, 0.28);
    align-items: flex-start;
}

.details-main-content .details-sidebar>div.recommended div.recdiv img {
    width: 46px;
    height: 46px;
    border-radius: 28px;
    margin-right: 16px;
    margin-top: 12px;
}

.details-main-content .details-sidebar>div.recommended div.recdiv h4 {
    font-size: 16px;
    line-height: 32px;
    color: #4274B0;
}

.details-main-content .details-sidebar>div.recommended div.recdiv p {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #707070;
    opacity: 0.8;
}

.details-main-content .details-sidebar>div.recommended div.recdiv button {
    width: 121px;
    height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFF;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.38);
    font-size: 16px;
    line-height: 19px;
    color: #283B4C;
    opacity: 0.8;
    margin: 14px 0;
}

.details-main-content .details-sidebar>div.recommended div.more {
    justify-content: center;
    padding: 10px 0;
}

.details-main-content .details-sidebar>div.recommended div.more h4 {
    color: #4274B0;
    margin-right: 8px;
}


.details-main-content .detail-content-div {
    width: calc(75% - 25px);
}

.detail-container {
    background-color: #FFF;
    border-radius: 6px;
    margin-bottom: 24px;
    padding: 20px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}

.detail-container h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #283B4C;
    margin-bottom: 20px;
}

.detail-container p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #707070;
}

.detail-container table {
    width: 100%;
    border-collapse: collapse;
}

.detail-container table thead {
    background-color: #E7F2FF;
}

.detail-container table th {

    justify-content: flex-start;
    align-items: center;
    display: flex;
    height: 48px;
    width: 25%;
    padding-left: 35px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #283B4C;

}



.detail-container table tr {
    border-bottom: 1px solid #0003;

    width: 100%;
    display: flex;
    justify-content: space-between;
}

.detail-container table tr:nth-child(2n) {
    background-color: #F4F9FF;
}

.detail-container table td {
    justify-content: flex-start;
    align-items: center;
    display: flex;
    width: 25%;
    height: 48px;
    padding-left: 35px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #75787A;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #283B4C;
}

.detail-container table td p {
    width: 130px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #283B4C;
    background-color: rgba(202, 212, 157, 0.2);
}

.detail-container table td p.success {
    background-color: rgba(145, 196, 153, 0.2);
}

.detail-container table td p.danger {
    background-color: rgba(255, 188, 125, 0.2);
}

.detail-container table td p.warning {
    background-color: rgba(244, 246, 235, 1);
}

.detail-container select {

    height: 30px;
    border: 0.4px solid rgba(112, 112, 112, 0.38);
    border-radius: 5px;
    min-width: 100px;
    margin-left: 12px;
    color: #283B4C;
    padding: 0 10px;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    padding-right: 15px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAOCAYAAADez2d9AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACjSURBVHgBtc1NDsIgEIbhmXIBb+A51Cb+nIjEhXGlS7iVi6rHKCcphTRNQwp0aOHdfbOYB++P57tCfEHRtKp0d2X/b/M5nGo0XaBIAySlVMzOcuAE2cXGc37QhRwsLziHZlge0A95sW1gGApiI3isDQh4NhNhGWoZ6FsIimK2X0MFB0gIoWL/ohgNpEEkLA7SITLmB9OgJMwFYZ8KrY5zvoMV9bimf3KRZDWnAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
    background-size: 10px;
}

.detail-container .flex-row .viewall {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #0A72B0;
    cursor: pointer;
}

.detail-container .flex-row .viewall:hover {
    color: #095A8B;
}

.details-events-sec {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 50px;
}

.details-events-sec .flip-card h3 {
    position: absolute;
    top: -15%;
    left: 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #283B4C;

    display: inline-block;
    background-color: #E7F2FF;
    padding: 8px 24px;
    border-radius: 4px 4px 4px 0px;
}

.details-events-sec .flip-card .cardFront h4 {
    margin-top: 5px;
    color: #283B4C;
}

.details-events-sec .flip-card .cardFront h6 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.04em;
    color: #283B4C;
    text-transform: uppercase;
    margin-bottom: 10px;
    margin-top: 4px;
}

.details-events-sec .flip-card .cardFront p {
    margin: 10px 0;
}

.details-events-sec .flip-card .cardFront .click {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: 13px;
    margin-right: 14px;
    cursor: pointer;
}

.details-events-sec .flip-card .cardFront .click img {
    width: 20px;
    height: 15px;
    margin-left: 10px;
}

.file-section {
    padding: 10px 8px 0 10px;
}

.file-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 8px 8px 9px;
    border-bottom: 0.5px solid #D1E2E5;
}

.file-row:last-child {
    border-bottom: none;
}

.img-content {
    display: flex;
    align-items: center;
}

.file-row .img-content .pdf-thumbnail {
    height: 22px;
    width: 19px;
    padding: 7px 8.5px 6px 9px;
    border: 1px solid #DDDDDD;
    border-radius: 0.25rem;
    background-color: #FFFFFF;
}

.file-row .img-content .file-thumbnail {
    height: 2.25rem;
    width: 2.35rem;
    border-radius: 0.25rem;
}

.file-row .img-content .file-text {
    font-size: 0.75rem;
    line-height: 1rem;
    color: #707070;
    padding-left: 20px;
    padding-right: 20px;
}

.file-row .download-icon {
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 0.25rem;
    background: #FFFFFF;
    cursor: pointer;
}

/*  */
.details-events>div div.rightdiv h6,
.details-events-sec>div h6 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.04em;
    color: #283B4C;
    text-transform: uppercase;
    margin-bottom: 10px;
    margin-top: 4px;
}

.details-events-sec {
    display: flex; flex-direction: row;align-items: center;
}

.details-events-sec div>h4 {
    color: #283B4C;
}

.details-events-sec>div {
    width: calc(100% - 12px);
    margin-right: 20px;
    margin-bottom: 20px;
    max-width: 307px;
}

.details-events-sec>div:nth-child(3n) {
    margin-right: 0px;
}

.details-events-sec>div h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #283B4C;

    display: inline-block;
    background-color: #E7F2FF;
    padding: 8px 24px;
    border-radius: 4px 4px 4px 0px;
}

/* .details-events-sec>div>div {

    width: 50%;
    margin-right: 20px;
    margin-bottom: 20px; 
      display: flex;
    border: 0.5px solid rgba(0, 0, 0, 0.18);
    border-radius: 0px 4px 4px 4px;
    flex-direction: column;
    padding: 24px 20px;
} */

/* .details-events-sec>div h6 {
    margin: 10px 0;
}

.details-events-sec>div p {
    margin: 10px 0;
}

.details-events-sec>div div.click {
    margin-top: 10px;
    justify-content: flex-end;
    cursor: pointer;
} */

.details-events-sec>div div.click img {
    width: 20px;
    height: 15px;
    margin-left: 10px;
}

.details-events-sec>div div.click a {
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    color: #0A72B0;
}

.details-events-sec>div div.click a:hover {
    color: #095A8B;
}

.detail-info-table {
    width: 100%;
}

.detail-info-table .detail-div {
    margin: 30px;
    align-items: stretch;
}

.detail-info-table .detail-div div.info-tr {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.detail-info-table .detail-div div.separator {
    width: 1px;
    background-color: #CCC;
}

.detail-info-table .detail-div div.info-tr div:first-child {
    margin-top: -10px;
}

.detail-info-table .detail-div div.info-tr div {
    margin-left: 12px;
}

.detail-info-table .detail-div div.info-tr img {
    width: 25px;
}

.detail-info-table .detail-div div.info-tr p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #656565;
}

.details-title-section {
    width: calc(100% - 60px);
    justify-content: space-between;
    padding: 20px 30px;
    align-items: flex-start;
}

.details-title-section .claim {
    flex: 1;
    width: 20%;
}

.details-title-section .claim .a {
    /* padding-bottom: 4px;
    border-bottom: 1px solid #4274B0; */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #4274B0;
    cursor: pointer;
}

.details-title-section .title-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -70px;
    z-index: 98;
    width: 50%;
}

.details-title-section .profile-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -90px;
    z-index: 100;
    width: 25%;
}

.details-title-section .profile-main-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
    z-index: 1;
    width: 50%;
}

.details-title-section .profile-main-content div h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    color: #283B4C;
}

.details-title-section .profile-main-content p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #707070;
    margin-top: 6px;
}

.details-title-section .profile-main-content .separator {
    width: 1px;
    background-color: #999;
    margin: 0 20px;
    height: 4vh;
}

.details-title-section .profile-main .img-div {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    background-color: #FFF;
    justify-content: center;
    align-items: center;
    display: flex;
}

.details-title-section .profile-main img {
    width: 150px;
    height: 150px;
    border-radius: 150px;

}

/*  */

.details-title-section .title-main .img-div {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    background-color: #FFF;
    justify-content: center;
    align-items: center;
    display: flex;
}

.details-title-section .title-main .img-div img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.details-title-section .title-main h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #283B4C;
    max-width: 650px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.details-title-section .title-main p.location {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #707070;

}

.details-title-section .title-main .location .km_away {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-top: 10px;
    color: #4274B0;
    top: -6px;
}

.details-title-section .title-compare {
    width: 25%;
}

.details-title-section .title-compare>div {
    font-size: 15px;
    color: #626262;
    margin-left: 30px;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.details-title-section .title-compare div.view {
    margin-right: 20px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #626262;
}

.details-title-section .title-compare .compare,
input {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #0976B7;

}

.details-title-section .title-compare input {
    cursor: pointer;
}

.details-title-section .title-compare .detail-rating img {
    margin-left: 3px;
    height: 18px;
    width: 18px;
}

.details-title-section .title-compare .detail-rating p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #4274B0;
}

.details-title-section .title-compare .shortlist-div {
    margin-left: 17px;
    height: 30px;
    width: 30px;
    border-radius: 4px;
    background-color: #FFF;
    justify-content: center;
    cursor: pointer;
}

.details-title-section .title-compare .shortlist-div:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}


/* Others css */

.adm-html ul {
    padding-left: 1.25rem;
}

.details-admission a {
    /* border-bottom: 1px solid #4274B0;
    padding-bottom: 2px;  */

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #0A72B0;
}

.details-admission a {
    display: inline-block;
    margin: 20px 0 20px 0;
}

.details-admission>div {
    align-items: stretch;
    justify-content: space-between;
    margin-bottom: 30px;
}

.details-admission>div div.content {
    flex: 1;
    text-align: center;
}

.details-admission>div div.separator {
    width: 1px;
    background-color: rgba(0, 0, 0, 0.12);
    margin: 0 10px;
}

.details-admission>div div h4 {
    text-align: center;
    margin-bottom: 8px;

}

.details-admission>div div.content>div {
    display: inline-block;
    padding-bottom: 20px;
}

.details-admission>div div.content>div>p {
    padding: 8px 0;
}

.details-admission>div div.content>div>p p {
    text-align: start;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #707070;
    font-weight: 500;
}

.details-admission>div div.content>div>p p.dot {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #FD6C73;
    margin-right: 19px;
}

.details-alumni .alumni-links a.first,
.details-management a {
    /* border-bottom: 1px solid #4274B0;
    padding-bottom: 2px;  */

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #0A72B0;
}

.details-alumni .alumni-links a.first:hover {
    color: #095A8B;
}

.details-alumni>div {
    align-items: flex-start;
    margin-bottom: 28px;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.26);
    padding-bottom: 28px;
}

.details-alumni>div:last-child {
    border-bottom: 0px solid rgba(0, 0, 0, 0.26);
}

.details-alumni img.alimg {
    width: 35px;
    height: 28px;
    margin: 6px 24px 0 0;
}

.details-alumni h4 {
    color: #283B4C;
}

.details-alumni p.descr {
    margin-left: 64px;
    margin-top: 10px;

    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word;

}

.details-alumni p.descrless {
    margin-left: 64px;
    margin-top: 10px;
    overflow: visible;
}

.details-alumni .alumni-links {
    justify-content: space-between;
    width: calc(100% - 64px);
    margin-left: 64px;
    margin-top: 20px;
}

.details-alumni .alumni-links p.second {
    color: #0A72B0;
    cursor: pointer;
}

.details-alumni .alumni-links p.second:hover {
    color: #095A8B;
}

.details-alumni .alumni-links p.second img {
    width: 24px;
    height: 24px;
    margin-left: 10px;
    cursor: pointer;
}

.details-events>div {
    border: 0.5px solid rgba(0, 0, 0, 0.18);
    align-items: stretch;
    margin-bottom: 24px;
}

.details-events>div div.leftdiv {
    width: 180px;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.details-events>div div.leftdiv h4 {
    color: #283B4C;
}

.details-events>div div.leftdiv p {
    font-size: 18px;
}

.details-events>div div.rightdiv h4 {
    color: #283B4C;
}

.details-events>div div.rightdiv {
    padding: 12px 14px;
    flex: 1;
}

.details-facilities {
    flex-wrap: wrap;
}

.details-facilities div {
    width: calc(20% - 16px);
    margin: 0 10px 0px 10px;
}

.details-facilities div:nth-child(5n) {
    margin-right: 0;
}

.details-facilities div:nth-child(5n+1) {
    margin-left: 0;
}

.details-facilities div .img-container {
    height: 95px;
    border: 0.5px solid rgba(0, 0, 0, 0.26);
    border-radius: 4px;
    justify-content: center;
    width: 100%;
    z-index: 1;
    transform: scale(1.0);
    transition: 500ms;
}

.details-facilities div .img-container:hover {
    transform: scale(1.1);
    transition: 500ms;
}

.details-facilities div .img-container img {
    width: 46px;
    height: 47px;
}

.details-facilities div h4 {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #283B4C;
    text-align: center;
    margin-top: 8px;
    margin-bottom: 24px;
}

.details-facilities div p {
    text-align: center;
}

.details-facilities div p a {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    text-decoration-line: underline;
    color: #0A72B0;
    display: inline-block;
    margin-top: -10px;
    padding-bottom: 2px;
    margin-bottom: 24px;

}

.details-facilities div p a:hover {
    columns: #095A8B;
}

.answer_text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #707070;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 10px;
}

.details-management div>h4 {
    color: #283B4C;
}

.details-management>div {
    padding: 20px;
}

.details-management>div:nth-child(2n+1) {
    background-color: #F4F9FF;
}

.details-management img.comma {
    margin: 0px 14px 0 40px;
}

.feedback-div {
    width: 70%;
    min-width: 700px;
    max-width: 900px;
    background-color: #FFF;
    align-items: stretch;
    z-index: 1000;
}

.feedback-div .feedback-inner-div {
    padding: 15px 30px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.feedback-div .feedback-inner-div .close {
    display: flex;
    justify-content: space-between;
    flex: 0;
}

.feedback-div .feedback-inner-div img.teacher {
    width: 42px;
    height: 42px;
    border-radius: 21px;
    margin-right: 15px;
}

.feedback-div .feedback-inner-div .review-main {
    align-items: stretch;
}

.feedback-div .feedback-inner-div .left-div {
    flex: 1;
    border-right: 1px solid rgba(0, 0, 0, 0.18);
    padding: 20px 20px 20px 0;
}

.feedback-div .feedback-inner-div .left-div table {
    width: 100%;
}

.feedback-div .feedback-inner-div .left-div table tr td {
    color: #1E2228;
    font-size: 16px;
    line-height: 24px;
}

.feedback-div .feedback-inner-div .left-div table tr td:nth-child(3) {
    font-size: 16px;
    line-height: 24px;
    color: #707070;
}

.feedback-div .feedback-inner-div .left-div table tr td img {
    width: 20px;
    height: 20px;
    margin-right: 6px;
}

.feedback-div .feedback-inner-div .right-div {
    flex: 1;
    padding: 20px;
}

.feedback-div .feedback-inner-div .right-div {
    flex: 1;
    padding: 20px;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
}

.feedback-div .feedback-inner-div .right-div h4 {
    font-size: 16px;
    line-height: 24px;
    display: flex;
    color: #283B4C;
}

.feedback-div .feedback-inner-div .right-div input {
    border: 0px solid #939599;
    border-bottom: 1px solid #939599;
    padding: 10px 0;
    font-size: 16px;
    line-height: 18px;
    color: #939599;
    margin-bottom: 30px;
}

.feedback-div .feedback-inner-div .right-div button {
    width: 155px;
    height: 40px;
    border: 0px solid #0000;
    color: #FFF;
    font-size: 16px;
    justify-content: center;
    background-color: #4274B0;
    border-radius: 4px;
}

/*Review Css*/

.details-reviews .rating-div {
    align-items: stretch;
    border-bottom: 1px solid rgba(112, 112, 112, 0.38);
}

.details-reviews .rating-div .left-div {
    width: 30%;
    border-right: 1px solid rgba(112, 112, 112, 0.38);
    margin: 20px 0;

}

.details-reviews .rating-div .left-div .rating {
    width: 67px;
    height: 67px;
    justify-content: center;
    color: #FFF;
    margin-right: 16px;
}

.details-reviews .rating-div .left-div .basedon {
    font-size: 13px;
    line-height: 16px;
}

.details-reviews .rating-div .left-div .all-ratings {
    width: 80%;
}

.details-reviews .rating-div .left-div .all-ratings .ratings {
    justify-content: space-between;
    margin-bottom: 5px;
}

.details-reviews .rating-div .left-div .all-ratings .ratings img {
    width: 11px;
    height: 11px;
}

.details-reviews .rating-div .left-div .all-ratings .ratings p {
    font-size: 12px;
    color: #283B4C;
    line-height: 18px;
}

.details-reviews .rating-div .left-div .all-ratings .ratings p:last-child {
    width: 20px
}

.details-reviews .rating-div .left-div .all-ratings .ratings .rating-percent {
    width: 70%;
    height: 5px;
    background-color: #EFEFF0;
}

.details-reviews .rating-div .left-div .all-ratings .ratings .rating-percent>div {
    background-color: #FFC600;
    height: 4px;
}

.details-reviews .rating-div .right-div {
    width: calc(70% - 40px);
    padding: 20px;
}

.details-reviews .rating-div .right-div p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #707070;
    margin-top: 20px;
}

.details-reviews .rating-div .right-div button {
    width: 141px;
    height: 36px;
    justify-content: center;
    background-color: #FFF;
    border: 0.666667px solid #0976B7;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.04em;
    color: #0976B7;
    margin-top: 32px;
    cursor: pointer;
    line-height: 16px;
}

.details-reviews .rating-div .right-div button:hover {
    background-color: #0A72B0;
    color: #FFFFFF;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    border: 0.666667px solid #FFF;
}

.details-reviews .review-div {
    margin-top: 20px;
}

.details-reviews .review-div .review {
    align-items: flex-start;
    border-bottom: 1px solid #EFEFF0;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.details-reviews .review-div .review:last-child {
    border-bottom: 0px solid #EFEFF0;
}

.details-reviews .review-div .avatar-div {
    margin-right: 24px;
}

.details-reviews .review-div .avatar-div img {
    width: 48px;
    height: 48px;
    border-radius: 24px;
}

.details-reviews .review-div .heading-div {
    justify-content: space-between;
}

.details-reviews .review-div .heading-div h4 div {
    width: 60px;
    height: 26px;
    background-color: #FFC600;
    justify-content: center;
    margin-left: 24px;
    border-radius: 4px;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
}

.details-reviews .review-div .heading-div h4 div img {
    margin-left: 6px;
    width: 12px;
    height: 12px;
    margin-top: -3px;

}

.details-reviews .review-div .heading-div .share {
    position: absolute;
    right: 5%;
    background-color: white;
    padding: 4px;
    height: 18px;
    width: 18px;
    border: 1px solid #4274B0;
    border-radius: 4px;
}

.details-reviews .review-div .heading-div .share:hover {
    cursor: pointer;
    background-color: #E2F4FF;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.12);
}

.details-reviews .review-div .heading-div .share img {
    width: 30px;
    height: 30px;
    object-fit: contain;
}

.details-reviews .review-div .review-tags {
    margin: 10px 0;
}

.details-reviews .review-div .review-tags>div {
    display: inline-block;
    background: #F0F0F0;
    border-radius: 4px;
    padding: 0px 6px;
    margin: 0 10px 10px 0;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #283B4C;
}

.details-reviews .review-div .review-tags>div span {
    margin-left: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: right;

    color: #707070;

}

.details-reviews .review-div .review-tags>div img {
    height: 10px;
    width: 10px;
}

.details-reviews .review-div h4.tagline {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #283B4C;
    margin-bottom: 10px;
}

.details-reviews .review-div .helpful-div {
    margin-top: 16px;
}

.details-reviews .review-div .helpful-div p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #707070;
    margin-right: 26px;
}

.details-reviews .review-div .helpful-div .helpful-img {
    width: 75px;
    height: 36px;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #707070;

    justify-content: center;
    margin-right: 12px;
    cursor: pointer;
}

.details-reviews .review-div .helpful-div .helpful-img img {
    width: 20px;
    height: 16.23px;
    margin-right: 10px;
}

/*Scholarship Css*/

.details-scholar>div {
    align-items: flex-start;
    margin-bottom: 24px;
}

.details-scholar img {
    width: 38px;
    height: 39px;
    margin: 10px 24px 0 0;
}

.details-scholar h4 {
    color: #283B4C;
}

/*Sheading Css*/

.sheading-component {
    margin: 20px 0;
}

.sheading-component .separator {
    flex: 1;
    height: 1px;
    margin-right: 18px;
    background-color: rgba(0, 0, 0, 0.18);
}

.sheading-component .title {
    width: 120px;
    background: rgba(253, 108, 115, 0.2);
    border-radius: 4px;
    height: 24px;
    justify-content: center;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 24px;
    color: #707070;
}

/*Standard Css*/

.details-teacher a,
.details-standard a,
.details-alumni .alumni-links a.first,
.details-management a,
.details-admission a {
    /* border-bottom: 1px solid #4274B0;
    padding-bottom: 2px;  */

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #0A72B0;
}

.details-standard {
    width: 100%;
    border: 0.5px solid rgba(0, 0, 0, 0.18);
    margin-bottom: 16px;
    align-items: stretch;
}

.details-standard .stan-heading {
    width: 220px;
    justify-content: center;
    padding: 6px 12px;
    box-sizing: border-box;
}

.details-standard .stan-heading h4 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    line-height: 24px;
    color: #283B4C;
}

.details-standard .tinfo {
    justify-content: space-between;
    padding: 0px 24px;
    min-height: 50px;
    height: 100%;
}

.details-standard .tinfo>div {
    align-items: center;
}

.details-standard .tinfo .imgdiv {
    height: 30px;
    width: 30px;
    background-color: #FFF1F1;
    border-radius: 15px;
    justify-content: center;
    margin-right: 8px;
}

.details-standard .tinfo .imgdiv img {
    height: 24px;
    width: 24px;
}

.details-standard .tinfo>div h4 {
    color: #283B4C;
}

.details-standard .tinfo>div h4 span {
    background-color: #E8E8E8;
    width: 64px;
    height: 15px;
    border-radius: 2px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #707070;
    display: inline-block;
    text-align: center;
}

/*Details Sidebar css*/

.details-sidebar .recommend-content {
    padding: 15px;
    height: 60vh;
}

.details-sidebar .recommend-content .title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #283B4C;
    margin-bottom: 10px;
}

.details-sidebar .recommend-content .recommend-img-container {
    display: flex;
    flex-direction: column;
    margin-top: -60px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 45px;
}

.details-sidebar .recommend-content .recommend-user-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.details-sidebar .recommend-content .recommend-user-name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #4274B0;
}

.details-sidebar .recommend-content .recommend-user-courses {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #707070;
    margin-top: 5px;
}

.details-sidebar .recommend-content .recommend-user-btn {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #283B4C;

    width: 120px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.38);
    border-radius: 4px;
    margin: 20px 0px;
}

.details-sidebar .recommend-content .recommend-user-btn:hover {
    background-color: #095A8B;
    color: white;
    border: 1px solid #095A8B;
}

.details-sidebar .recommend-content .view-more {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #4274B0;
    text-align: center;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.details-sidebar .recommend-content .view-more img {
    width: 18px;
    height: 18px;
    margin-left: 10px;
}


.details-sidebar .recommend-content .filter-section-baseheight {
    min-height: 70px;
    overflow-y: hidden;

    animation-duration: 2s;
    animation-name: open;
}

.details-sidebar .recommend-content .filter-section-maxheight {
    min-height: 70px;
    height: 60vh;
    overflow-y: hidden;
}

.details-sidebar .recommend-content .filter-section-maxheight-active {
    max-height: 70vh;
    overflow-y: auto;
    animation-duration: 2s;
    animation-name: open;
}

/*Details Carousel Css*/

.detail-slider .react-multiple-carousel__arrow {
    background: rgb(255 255 255 / 50%);
}

.detail-slider {
    width: 100%;
    height: 300px;
    margin-top: 12px;
}

.detail-slider .slides {
    width: 100%;
    height: 300px;
}

.detail-slider .slides .overlay {
    width: 100%;
    height: 300px;
    background-color: #00000036;
}

.detail-slider-info {
    position: absolute;
    width: 100%;
    height: 300px;
    margin-top: -300px;
}

.detail-slider-info>div {
    width: calc(100% - 60px);
    padding: 16px 30px;
    height: 268px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}

.detail-slider .detail-slider-info .established {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
}

.detail-slider .detail-slider-info div a,
.detail-slider .detail-slider-info div .enquire-now-v {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    padding: 10px 30px;
    border-radius: 4px;
    margin-left: 14px;

}

.ApplyNow {
    background: #4274B0;
    border-radius: 4px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    padding: 10px 30px;
    outline: 0;
    color: #FFFFFF;
    margin-left: 10px;
    cursor: pointer;
    border: 0;
}

.ApplyNow:hover {
    box-shadow: #AAA 0px 5px 15px 0px;

}

.detail-slider .detail-slider-info div a:nth-child(2) {
    background-color: #0A72B0;
}

.detail-slider .detail-slider-info div .enquire-now-v {
    background-color: #FD6C73;
    cursor: pointer;
}

.detail-slider .detail-slider-info div .enquire-now-v:hover {
    box-shadow: #AAA 0px 5px 15px 0px;

}

.detail-slider .detail-slider-info div a:nth-child(2):hover {
    background-color: #095A8B;
}

/*School Details*/

.details-school-det {
    flex-wrap: wrap;
}

.details-school-det div {
    width: calc(33.33% - 16px);
    margin-right: 20px;
    border: 0.5px solid rgba(0, 0, 0, 0.18);
    height: 156px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 4px;
    margin-bottom: 20px;
    transform: scale(1.0);
    transition: 500ms;
}

.details-school-det div:hover {
    transform: scale(1.05);
    transition: 500ms;
    background-color: #EFEFF6;
}

.details-school-det div:nth-child(3n) {
    margin-right: 0;
}

.details-school-det div p {
    margin-top: 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #75787A;
    text-align: center;
}

/*Image Css*/

.details-school-img>img {
    margin-right: 20px;
    width: calc(25% - 15px);
    transform: scale(1.0);
    transition: 500ms;
    height: 156px;
    border-radius: 4px;
    object-fit: cover;
}

.details-school-img>img:hover {
    transform: scale(1.05);
    transition: 500ms;
    cursor: pointer;
}

.details-school-img div {
    width: calc(25% - 15px);
    transform: scale(1.0);
    transition: 500ms;
    position: relative;
}

.details-school-img div:hover {
    transform: scale(1.05);
    transition: 500ms;
}

.details-school-img div>img.vid-thumbnail {
    width: 100%;
    height: 156px;
    margin-bottom: -4px;
    border-radius: 4px
}

img.play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    cursor: pointer;
    transform: translate(-50%, -50%);
}

.details-school-img img:hover {
    /* transform: scale(1.05); */
    transition: 500ms;
}

.details-school-img img:nth-child(4n) {
    margin-right: 0px;
}

.details-dmsg {
    margin-top: 14px;
}

/*----*/


.details-dmsg img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-right: 20px;
}

.details-dmsg div>h4 {
    color: #283B4C;
}

/*Teacher Css*/

.details-teacher {
    width: 100%;
    border: 0.5px solid rgba(0, 0, 0, 0.18);
    margin-bottom: 16px;
}

.details-teacher .imgavatar {
    width: 100px;
    height: 100px;
}

.details-teacher .tinfo {
    justify-content: space-between;
    padding: 5px 24px;
}

.details-teacher .tinfo div h4 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #283B4C;
}

.details-teacher p.teacher-det {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #707070;
    padding: 3px 24px;
}

/*Rating Css*/

.popup-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 53vw;
    height: 47vh;
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
    overflow: hidden;
}

.popup-container p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #939599;
}

.review-header-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

}

.review-header {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #283B4C;
}

.review-column-45 {
    width: 45%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.review-column-45 .review-column-45-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
}

.review-column-45 textarea {
    border-color: #939599;
    border-width: 0.5px;
    padding: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.review-column-45 button {
    width: 155px;
    height: 35px;
    background: #4274B0;
    border-radius: 4px;
    border-width: 0px;
}

.review-column-45 button p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
}

.review-column-45 p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #1E2228;
}

/*Qualification Css*/

.details-qualification>div {
    align-items: flex-start;
    margin-bottom: 28px;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.26);
    padding-bottom: 28px;
}

.details-qualification>div div {
    align-items: flex-start;
}

.details-qualification>div div.detdiv {
    align-items: stretch;
    margin: 3px 0 6px 0;
}

.details-qualification>div div h6 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #283B4C;
}

.details-qualification>div div.separator {
    width: 1px;
    margin: 0 13px;
    background-color: rgba(0, 0, 0, 0.26);
}

.detail-qualification-coaching {
    background-color: #F4F4F4;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    width: 79px;
    height: 79px;

}

.details-qualification .detail-qualification-coaching img {
    margin: 10px;
    width: 57.69px;
    height: 56.26px;
    object-fit: contain;
}

.details-qualification>div:last-child {
    border-bottom: 0px solid rgba(0, 0, 0, 0.26);
}

/*Experience Css*/

.details-experience {
    display: flex;
    align-items: stretch;
}

.details-experience .left-div {
    width: 75px;
    margin-right: 32px;
}

.details-experience .left-div h4 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #283B4C;
    width: 75px;
}

.details-experience .left-div p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.04em;
}

.details-experience .middle-div {
    margin-right: 52px;
    align-items: center;
    flex-direction: column;
    display: flex;
}

.details-experience .middle-div .sline {
    height: 100%;
    width: 1px;
    background-color: rgba(0, 0, 0, 0.16);
}

.details-experience .middle-div img {
    width: 20px;
    height: 20px;
}

.details-experience div.right-div div {
    align-items: stretch;
    margin: 4px 0 10px 0;
}

.details-experience div.right-div div h6 {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #283B4C;
    letter-spacing: 0.04em;

}

.details-experience div.right-div {
    margin-right: 100px;
}

.details-experience div.right-div h4 {
    color: #283B4C;
}


.details-experience div.right-div div.separator {
    width: 1px;
    margin: 0 13px;
    background-color: rgba(0, 0, 0, 0.26);
}

.details-experience div.right-div p {
    margin-bottom: 45px;
}

/*Profile Section Css*/

.enquire-now {
    background-color: #0A72B0;
    width: 120px;
    padding: 5px 10px;
    border-radius: 5px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    cursor: pointer;
}

.enquire-now:hover {
    background-color: #095A8B;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

.map {
    font-size: 0.9em;
    color: rgba(66, 116, 176, 1);
    cursor: pointer;
}
.map:hover {
    text-decoration-line: underline;
}

/* Media Quiries By Vishakha */
.fee-structure table td label {
    display: none;
}

.flex-row.details-standard {
    align-items: stretch;
}

/*  less than or equal to 400px */
@media only screen and (max-width: 400px) {
    .detail-slider-info>div {
        width: 100%;
        padding: 8px;
        box-sizing: border-box;
    }

    .detail-slider .detail-slider-info div a:nth-child(1) {
        margin-left: 0px;
    }

    .detail-slider-info>div {
        align-items: center;
    }

    .details-title-section.flex-row {
        flex-wrap: wrap;
        padding: 8px 8px;
        width: 100%;
        box-sizing: border-box;
    }

    .details-title-section.flex-row>div {
        width: 100%;
    }

    .claim {
        margin: 50px 0px -50px;
        text-align: center;
    }

    .detail-info-table .detail-div {
        margin: 0px;
        flex-wrap: wrap;
    }

    .detail-info-table .detail-div div.info-tr {
        width: 100%;
        flex: none;
        justify-content: flex-start;
        margin-top: 8px;
    }

    .detail-info-table .detail-div div.separator {
        display: none;
    }

    .details-main-content {
        display: block;
        padding: 8px;
        width: 100%;
        box-sizing: border-box;
    }

    .details-main-content .details-sidebar,
    .details-main-content .detail-content-div {
        margin-right: 0px;
        width: 100%;
    }

    .detail-container {
        padding: 8px;
    }

    .details-school-det>div,
    .details-school-det>div:nth-child(3n) {
        width: calc(50% - 10px);
        margin: 4px;
    }

    .details-school-det div h4 {
        text-align: center;
    }

    .flex-row.img-space {
        flex-wrap: wrap;
    }

    .flex-row.img-space h2 {
        width: 100%;
    }

    .flex-row.img-space p {
        margin-left: auto;
        margin-top: 4px;
    }

    .details-main-content .details-sidebar>div:not(.latest-news)>div {
        display: flex;
        width: max-content;
    }

    .details-main-content .details-sidebar>div:not(.latest-news) {
        overflow-x: scroll;
        width: 100%;
    }

    .dsidebar {
        width: 150px;
    }

    .flex-row.details-school-img img,
    .details-school-img div {
        width: calc(50% - 10px);
        margin: 5px;
        height: auto;
        min-height: 102px;
    }

    .details-school-img div>img.vid-thumbnail {
        height: auto;
    }

    .flex-row.details-school-img {
        flex-wrap: wrap;
    }

    .details-standard .tinfo {
        flex-wrap: wrap;
        padding: 8px;
    }

    .details-standard .tinfo>div {
        width: 100%;
    }

    .flex-row.details-standard {
        display: block;
    }

    .details-standard .stan-heading {
        width: 100%;
    }

    .details-standard .tinfo>div:not(.flex-row) {
        margin-left: auto;
        width: max-content;
    }

    .fee-structure table td label {
        display: inline;
        width: 90px;
        color: rgba(0, 0, 0, 0.312);
    }

    .detail-container table thead {
        display: none;
    }

    .detail-container table tr {
        display: block;
        padding-bottom: 8px;
    }

    .detail-container table td {
        width: 100%;
        padding-left: 0px;
        min-height: 35px;
        height: auto;
    }

    .detail-container table td p {
        height: 25px;
    }

    .details-facilities>div:nth-child(n) {
        margin: 0;
        width: calc(50% - 8px);
    }

    .flex-row.details-facilities {
        justify-content: space-between;
    }

    .details-reviews .review-div .review {
        display: block;
    }

    .left-div>.flex-row {
        display: block;
    }

    .details-reviews .rating-div {
        align-items: center;
    }

    .details-reviews .rating-div .left-div {
        width: 40%;
        margin-right: 4px;
    }

    .details-reviews .rating-div .right-div {
        width: 60%;
        padding: 0px 0px 0px 16px;

        box-sizing: border-box;
    }

    .details-reviews .rating-div .left-div .all-ratings {
        width: 100%;
    }

    .details-reviews .rating-div .left-div .all-ratings .ratings img {
        margin-right: 4px;
    }

    .details-reviews .rating-div .left-div .all-ratings .ratings p:last-child {
        margin-left: 4px;
    }

    #sidebar_facilities p.flex-row {
        display: block;
    }

    #sidebar_facilities p.flex-row select {
        display: block;
        margin: 0px;
    }

    .details-title-section .title-compare>div:first-of-type {
        justify-content: center;
        margin-left: 0;
    }

    .details-teacher .tinfo {
        display: block;
    }

    .details-teacher .tinfo>div {
        margin-bottom: 8px;
    }

    .flex-row.details-teacher {
        padding: 8px;
        box-sizing: border-box;
    }

    .details-admission>div.flex-row {
        display: block;
    }

    .details-management>div>div.flex-row {
        display: block;
    }

    .details-management img.comma {
        margin-left: 0px;

    }

    .details-management>div>div.flex-row>div:last-child {
        margin-top: 12px;
    }

    .details-events>div.flex-row {

        display: block;
    }

    .details-events>div div.leftdiv {
        width: 100%;
    }

    .rightdiv>* {
        text-align: center;
    }

    .cardFront {
        border: 1px solid rgba(0, 0, 0, 0.38);
    }

    .details-events-sec .flip-card {
        height: auto;
        min-height: 200px;
        width: 100%;
        margin: 0px 0px 12px;
    }
}

.react-player__preview {
    border-radius: 4px;
}

.lat-news {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ach-title {
    max-width: 260px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ach-desc {
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Between 400px to 600px */
@media only screen and (max-width: 600px) and (min-width:400px) {}

/*  greater than or equal to 600px */
@media only screen and (min-width:600px) {}