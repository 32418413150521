/* Banner Css */

.home-logo-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.home-logo-content select:after {
    border-style: hidden;
}

.home-logo-content select {
    border-width: 0px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #283B4C;

    margin-left: 20px;
    background-color: #FFF;
}

/* Banner Image Css */
.home-banner-container {
    width: 100%;
    height: 40vh;
    margin-top: 50px;
}

.home-search-container {
    margin-top: 64px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 0px;
    background: #FFFFFF;
}

.home-search-container h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 2.5vw;
    line-height: 60px;
    color: #283B4C;
}

.home-search-container h2 {
    flex-direction: row;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    color: #0976B7;
}

.home-search-container-inner {
    width: 65%;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 30px;

}

.home-search-container-inner h1 {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    color: #283B4C;
}

.city-valid {
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #707070;
    margin-top: -15px;
}

.banner-text {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    color: #283B4C;
    z-index: 1;
}

.home-header-menu {
    margin-top: -90px;
    display: flex;
    width: 93%;
    height: fit-content;
    background: #FFFFFF;
    box-shadow: -1px -2px 32px rgba(0, 0, 0, 0.04), 0px 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    overflow-y: auto;
    /* z-index: 3; */
}

.home-header-menu::-webkit-scrollbar {
    display: none;
}

.home-header-menu div.header-menu {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #283B4C;
    /* margin-top: 12px;
    margin-bottom: 10px; */
    cursor: pointer;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    border-bottom: 3px solid #FFFFFF;
    padding: 0px 0px;
    min-width: 16.66%;
}

.home-header-menu div.header-menu:hover {
    border-bottom: 4px solid #FD6C73;
}

.home-header-menu div.header-menu:hover p {
    color: #FD6C73;
    inline-size: -webkit-fill-available;
    white-space: pre-wrap;
}

.home-header-menu div.header-menu:hover img {
    filter: grayscale(0%);
}

.home-header-menu div.active {
    border-bottom: 4px solid #FD6C73;

}

.home-header-menu div.active p {
    color: #FD6C73;
    inline-size: -webkit-fill-available;
    white-space: pre-wrap;
}

.home-header-menu div.active img {
    filter: grayscale(0%);
}
.home-header-menu .examples {
    font-size: 13px;
    line-height: 13px;
    color: #707070;
    padding-bottom: 10px;
}

/* Banner Image Css */

.home-banner-img-overlay {
    width: 100%;
    height: 100%;
    /* padding-left: 30px; */
    /* padding-right: 30px; */

    /* object-fit: cover; */
    /* position: absolute;*/
    /* display: block;  */
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.3);
}

.home-banner-header {
    color: #FFFFFF;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 2.5vw;
    line-height: 60px;
    margin: 30px;
    word-break: break-all;
}

.home-banner-text {
    margin-right: 30px;
    margin-top: 10px;
    margin-bottom: 30px;
    margin-left: 30px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.2vw;
    line-height: 24px;
    color: #FFFFFF;
    word-break: break-all;
}

.home-banner-btn {
    position: absolute;
    width: auto;
    margin-left: 30px;
    bottom: 0;
}

.home-banner-btn p a {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.5vw;
    line-height: 32px;
    display: flex;
    align-items: flex-end;
    text-decoration-line: underline;
    color: #FFFFFF;
    cursor: pointer;
}

.home-banner-btn:hover {
    z-index: 1;

    transform: scale(1.03);
    transition: 500ms;
}


.home-banner-card {
    height: 35vh;
}

/* Blog Css */
.blog-content {
    height: 339px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.blog-column-50 {
    /*     position:relative; */
    width: 650px;
    /* height: 339px; */
    border-radius: 4px;
    width: 50%;
    transform: scale(1.0);
    /* transition: 500ms;  */
    transition: 1.0s ease;
}

.blog-column-50:hover {
    transform: scale(1.03);
    transition: 500ms;
}

.blog-overlay-content-50 {

    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.3);


    /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.1) 100%);

    transition: background 0.5s ease; */

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    /* justify-content: flex-end; */
}

.blog-visibtn-content {
    position: absolute;
    width: 100%;
    /*     width: 650px; */
    height: 339px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.blog-column-50:hover .visit-btn {

    opacity: 1;
}

.blog-overlay-content-50 p {
    padding: 10px 15px;
}

.blog-views {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 0.8em;
    line-height: 24px;
    color: #FFFFFF;
}

.blog-news {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1em;
    line-height: 26px;
    color: #FFFFFF;
}

.blog-column-60 {
    position: relative;
    /*   width: 710px; */
    height: 339px;
    border-radius: 4px;
    display: block;
    width: 50%;
}


.blog-column-60-content {
    display: flex;
    flex-direction: row;
    position: absolute;
    justify-content: space-evenly;
    width: 100%;
}



.blog-column-60-child {
    /* position: absolute; */
    display: flex;
    width: 350px;
    height: 166px;
    border-radius: 4px;

    transform: scale(1.0);
    transition: 1.0s ease;
}


.blog-column-60-child:hover {
    transform: scale(1.03);
    transition: 500ms;
}

.blog-column-60-child:hover .visit-btn {
    opacity: 1;
}

.blog-child-img {
    position: absolute;
    display: flex;
    width: 350px;
    height: 166px;
    border-radius: 4px;
}

.blog-child-visibtn-content {

    width: 350px;
    height: 166px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.blog-child-overlay-content {
    position: absolute;
    display: flex;
    width: 350px;
    height: 166px;
    border-radius: 4px;

    background-color: rgba(0, 0, 0, 0.3);

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
}

.blog-child-overlay-content p {
    padding: 10px 15px;
}

.blog-child-views {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 0.7em;
    line-height: 24px;
    color: #FFFFFF;
}

.blog-child-news {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 0.8em;
    line-height: 16px;
    color: #FFFFFF;
}

/* Card Item Css */
.category-img-container {
    position: relative;
    text-align: center;
    width: 12vw;
    height: 183px;
    border-radius: 16px;
    z-index: 1;
    -webkit-box-shadow: 0px 70px 40px -21px rgba(0, 0, 0, 0.59);
    -moz-box-shadow: 0px 70px 40px -21px rgba(0, 0, 0, 0.59);
    box-shadow: 0px 70px 40px -21px rgba(0, 0, 0, 0.59);
}

.category-img-container:hover {
    transform: scale(1.0);
    transition: 500ms;

    background-color: #fff;
    color: #fff;
    opacity: .5;
    cursor: pointer;
}

.category-container {
    background-color: #FFFFFF;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    box-sizing: border-box;
    padding-bottom: 60px;
}


.category-container h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    color: #283B4C;
    padding-top: 20px;
    padding-bottom: 20px;
}

.category-card-container {
    position: relative;
    text-align: center;
    height: 183px;
    border-radius: 16px;
    z-index: 1;
    transform: scale(.9);
    transition: 500ms;
}

.category-card-container:hover {
    transform: scale(1.0);
    transition: 500ms;
}

.category-card-container:hover .category-content .category-text {
    color: #4274B0;
}

.category-content {
    box-shadow: 3px 10px 10px 2px rgba(84, 80, 80, 0.45) inset;
    -webkit-box-shadow: 3px 10px 10px 2px rgba(84, 80, 80, 0.45) inset;
    -moz-box-shadow: 3px 10px 10px 2px rgba(84, 80, 80, 0.45) inset;
    background-color: white;

    display: inline-flex;
    flex-direction: column;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    width: 12vw;
    bottom: 0;
    height: 35px;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    z-index: 10;
}

.category-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #202D4E;
}

/*Category Css*/

.slider-parent-div {
    padding-left: 30px;
    padding-right: 30px;
    height: 100%;
}

.slider-parent-div .slick-list {
    height: 310px;
    padding-top: 40px;
    padding-bottom: 40px;
}

/* Educator Css*/
.educator-content {
    display: flex;
    overflow: visible;
    min-width: 245px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 3px;
    margin-bottom: 2px;
}

.carousel-educator-content {
    margin-top: -3px;
    display: inline-flex;
    flex-direction: column;
    display: flex;
    background-color: #FFFFFF;
    padding-left: 10px;
    padding-right: 20px;
    padding-bottom: 10px;
    width: 18vw;
    min-height: 21vh;
    padding-top: 10px;
}

.educator-qua-container {
    /* display: flex; */
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 15px;
}

.educator-qua-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #283B4C;
    flex: 1;
}

.educator-qua-value-text {
    flex: 1;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #283B4C;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    /* number of lines to show */
    -webkit-box-orient: vertical;
}

.educator-all-content {
    flex-direction: row;
    align-items: center;
    display: flex;
}

/*Search Css*/
.home-search-div {
    width: 93%;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    padding-top: 10px;
    padding-right: 15px;
    padding-left: 10px;
    padding-bottom: 10px;
    margin-top: 15px;
    margin-bottom: 30px;
    background: rgba(26, 23, 75, 0.2);
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    clip-path: polygon(1px, 0, 0, 0, 0, 0);
    /* clip-path: polygon(0px 0%, calc(100% - 40px) 0%, 100% 40px, 100% calc(100% - 0px), calc(100% - 0px) 100%, 0px 100%, 0% calc(100% - 0px), 0% 0px); */

}

.home-search-div .loc-stand-div {
    display: flex;
    margin: 10px 10px;
    cursor: pointer;
}
.home-search-div .loc-stand-div:hover {
    box-shadow: 0 10px 12px -6px rgba(0,0,0,0.18);
}


.home-search-div .dropdown {
    position: absolute;

    background-color: #FFF;
    margin-left: 10px;
    margin-top: 12px;
    border-radius: 4px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
}

.home-search-div .arrow {
    width: 14px;
    height: 14px;
    background-color: #FFF;
    transform: rotate(45deg);
    margin-top: -7px;
    margin-left: 18px;
}

.home-search-div .dropdown ul {
    list-style: none;
    padding: 0 4px;
    overflow-y: auto;
    height: 300px;
}

.home-search-div .dropdown li {
    padding: 12px;
    border-bottom: 0.5px solid #CCC;
    cursor: pointer;
    transition: all 0.5s;
}

.home-search-div .dropdown li:hover {
    color: #4274B0;
    font-weight: bold;
}

.home-search-div .collegedd li {
    padding: 4px 12px 0 12px;
}

.home-search-div .collegedd img {
    width: 36px;
    height: 36px;
    border-radius: 18px;
    margin-right: 12px;
    margin-top: -4px;
}

.home-search-div .collegedd h4 {
    font-size: 18px;
    line-height: 24px;
}

.home-search-div .collegedd p {
    font-size: 14px;
    line-height: 20px;
    color: #666;
    padding-left: 0;
    font-weight: 500;
}

.home-search-div .dropdown li.active {
    color: #4274B0;
    font-weight: bold;
}

.home-search-div-input1 {
    flex: 2
}

.home-search-div-input2 {
    flex: 4;
}

.home-search-div-input3 {
    flex: 4;
}

.home-search-input-div {
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #FFF;
    padding: 0 15px;
    border-radius: 3px;
}

.margin-vertical {
    margin: 10px 10px;
}

.read-only-inp, .read-only-inp:focus-visible {
    width: 100%;
    cursor: pointer;
    border: none;
    outline: none;
}

.no-margin-left {
    margin-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    flex: 5;
}

.no-margin-right {
    margin-right: 0;
    position: relative;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.no-margin-left > input, .no-margin-right > div {
    color: #283B4C;
}

.no-margin-right > .placeholder-text {
    color: #707070;
}

.input-seperator {
    position: absolute;
    height: 65%;
    border-right: 0.5px solid #CECECE;;
    left: 100%;
}

.home-search-div p {
    padding-left: 15px;
    margin-bottom: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
}

.home-search-result-div {
    position: absolute;
    width: 60%;
    top: 320px;
}

.home-search-result-div-inner {
    width: calc(70% - 1px);
    background-color: #FFF;
    padding: 10px 0;
}

.hide-cross-div {
    height: 22px;
    width: 50px;
    transform: rotate(45deg);
    position: absolute;
    background-color: white;
    right: -15px;
    top: -2px;
    border-top-left-radius: 17px;
    border-top-right-radius: 20px;
}

/* Skill List Css */
.home-skill-list-div {
    width: calc(13.66% - 15px);
    float: left;
    border: 1px solid #FD6C73;
    border-radius: 10px;
    padding: 16px 1.5% 16px 1.5%;
    margin: 6px;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: all 1s;
}

.home-skill-list-div .imgdiv {
    height: 44px;
    transition: all 1s;
}

.home-skill-list-div .imgdiv img {
    height: 44px;
    transition: all 1s;
}

.home-skill-list-div .imgdiv>img:nth-child(2) {
    display: none;
}

.home-skill-list-div h4 {
    font-size: 20px;
    color: #283B4C;
    margin-top: 6px;
    font-weight: 400;
    line-height: 24px;
}

.home-skill-list-div p {
    font-size: 13px;
    line-height: 13px;
    color: #707070;
}

.home-skill-list-div:hover {
    background-color: #FFEFF0;
}

.home-skill-list-div:hover h4 {
    color: #283B4C;
}

.home-skill-list-div:hover p {
    color: #283B4C;
}

.home-skill-list-div:hover .imgdiv>img:nth-child(1) {
    display: none;
}

.home-skill-list-div:hover .imgdiv>img:nth-child(2) {
    display: inline-block;
}

/*Testimonial Css*/
.testimonial-container {
    position: relative;
    display: flex;
    width: auto;
    height: fit-content;
    background: #FFFFFF;
    padding: 30px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.figure-container {
    width: 40%;
    height: 100%;
    display: flex;
    /* border: 5px solid brown; */
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.figure-row-div {
    position: relative;
    width: 70%;
    height: 23vh;
    display: flex;
    justify-content: flex-end;
}

.figure-row-div2 {
    position: relative;
    width: 80%;
    height: 30vh;
    display: flex;
    justify-content: flex-start;
}

.figure-content1 {
    position: relative;
    width: 30%;
    height: fit-content;
    z-index: 2;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
}

.figure-content {
    width: fit-content;
    height: fit-content;
    z-index: 1;
    position: relative;
}


.img-fluid0 {
    width: 11vw;
    height: 11vw;
    border-radius: 50%;
    box-shadow: 0 0 0 2px #85A2C6;
    transform: scale(1.0);
    transition: 500ms;
    z-index: 1;
}

.img-fluid0-active {
    border-radius: 50%;
    /* z-index: 1; */
    width: 12vw;
    height: 12vw;
    box-shadow: 0 0 0 5px #4274B0;
    padding: 5.5px;
    transition: .5s ease-in-out;
    transform: scale(1.03);
}

.img-fluid0:hover {
    width: 12vw;
    height: 12vw;
    box-shadow: 0 0 0 7px #4274B0;
    padding: 4px;
    transition: .5s ease-in-out;
    transform: scale(1.03);
}

.img-fluid1 {
    width: 17vw;
    height: 17vw;
    border-radius: 50%;
    box-shadow: 0 0 0 3px #85A2C6;
    transform: scale(1.0);
    transition: 500ms;
    z-index: 1;
}

.img-fluid1-active {
    width: 18vw;
    height: 18vw;
    border-radius: 50%;
    box-shadow: 0 0 0 6px #4274B0;
    padding: 5.5px;
    transition: .5s ease-in-out;
    transform: scale(1.03);
    z-index: 1;
}

.img-fluid1:hover {
    width: 18vw;
    height: 18vw;
    box-shadow: 0 0 0 6px #4274B0;
    padding: 5.5px;
    transition: .5s ease-in-out;
    transform: scale(1.03);
}

.img-fluid2 {
    width: 11vw;
    height: 11vw;
    border-radius: 50%;
    box-shadow: 0 0 0 3px #85A2C6;
    transform: scale(1.0);
    transition: 500ms;
    z-index: 1;
}

.img-fluid2-active {
    width: 12vw;
    height: 12vw;
    border-radius: 50%;
    box-shadow: 0 0 0 5px #4274B0;
    padding: 5.5px;
    z-index: 1;
    transition: .5s ease-in-out;
    transform: scale(1.03);
}

.img-fluid2:hover {
    width: 12vw;
    height: 12vw;
    box-shadow: 0 0 0 5px #4274B0;
    padding: 5.5px;
    transition: .5s ease-in-out;
    transform: scale(1.03);
}

.indicator1 {
    background-color: #4274B0;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    margin: 0 auto;
    position: relative;
    animation: 2s pulse infinite ease-out;
}

.indicator1::before,
.indicator1::after {
    content: '';
    position: absolute;
    bottom: 19px;
    right: 16px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #4274B0;
}

.indicator1::after {
    height: 60px;
    width: 60px;
    right: 45px;
    bottom: 54px;
}

/* indicator 2 */
.indicator2 {
    background-color: #4274B0;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    margin: 0 auto;
    position: relative;
    animation: 2s pulse infinite ease-out;
}

.indicator2::before,
.indicator2::after {
    content: '';
    position: absolute;
    bottom: -10px;
    right: 30px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #4274B0;
}

.indicator2::after {
    height: 60px;
    width: 60px;
    right: 80px;
    bottom: -20px;
}

/* indicator 3 */
.indicator3 {
    background-color: #4274B0;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    margin: 0 auto;
    position: relative;
    animation: 2s pulse infinite ease-out;
}

.indicator3::before,
.indicator3::after {
    content: '';
    position: absolute;
    bottom: -45px;
    right: 18px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #4274B0;
}

.indicator3::after {
    height: 60px;
    width: 60px;
    right: 50px;
    bottom: -105px;

}

.testimonial-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 2.4vw;
    line-height: 32px;
    color: #283B4C;
}

.testimonial-card-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.2vw;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #707070;
}

.testimonial-card-name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 1.5vw;
    line-height: 32px;
    color: #283B4C;

    text-align: start;
    position: absolute;
    bottom: 0px;
}

.testimonial-slider {
    width: 45%;
    height: 40vh;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
}

.testimonial-card {
    padding-top: 10px;
    height: 22vw;
}

.testimonial-slider-content {
    width: 100%;
    margin-bottom: 20px;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.testimonial-title-content {
    width: 50%;
}

.testimonial-btn-container {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 20vh;
}

.testimonial-btn {
    height: 5vh;
    width: 5vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.12);
}

/*Whyus Css*/

.whyus-div .react-multi-carousel-list {
    position: inherit;
}

.whyus-div .react-multiple-carousel__arrow {
    background-color: #FFF0;
}

.whyus-div .react-multiple-carousel__arrow::before,
.whyus-div .react-multiple-carousel__arrow::after {
    font-size: 40px;
    font-weight: bold;
    color: #6B6B6B;
}

.home-why-card {
    margin: 4px 10px;
    margin-bottom: 30px;
}

.whyus-idiv {
    padding: 0 50px;
}

.home-why-card .avatar-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 20px;
    justify-content: center;
}

.home-why-card .avatar-content img {
    width: 160px;
    height: 160px;
    border-radius: 90px;
    border: 10px solid #F5F5F9;
}

.home-why-card .content-div {
    background-color: #FFF;
    margin-top: -80px;
    padding: 70px 0 30px 0;
    box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.16);
}

.home-why-card .content-div h5 {
    font-size: 20px;
    color: #4274B0;
    font-weight: 500;
    margin-bottom: 5px;
    text-align: center;
}

.home-why-card .content-div p {
    font-size: 16px;
    color: #283B4C;
    text-align: center;
    font-weight: 400;
}

.home-why-card p.content {
    font-size: 16px;
    color: #707070;
    padding: 20px 30px 20px 30px;
    text-align: left;
    line-height: 24px;
}

.home-section-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.home-main-heading {

    font-weight: 500;
    color: #000;
    font-size: 24px;
    margin-left: 8px;
}

/* Common css */

.carousel-img-container {
    position: relative;
    text-align: center;
    max-width: 20vw;
    height: 177px;
    border-radius: 8px;
    z-index: 1;
    transform: scale(1.0);
    transition: 500ms;
}

.carousel-img-container:hover {
    position: relative;
    text-align: center;
    max-width: 20vw;
    height: 177px;
    border-radius: 8px;
    z-index: 1;
    transform: scale(1.03);
    transition: 500ms;
    cursor: pointer;
}

.carousel-img-container:hover .overlay-img {
    display: block;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.01) 100%);
    border-radius: 8px;
}

.carousel-img-container:hover .visit-btn {
    background-color: #fff;
    opacity: 1;
}

.carousel-school-content {
    margin-top: 15px;
    display: inline-flex;
    flex-direction: column;
    display: flex;
}

.carousel-school-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #283B4C;
}

.certificate-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 8px;
    width: '10px'
}

.certificate-container-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.04em;
    width: 100%;
    color: #707070;
}

.carousel-header-row div {
    margin-bottom: 8px;
}

.carousel-header-row div h3 {
    color: #283B4C;
    font-family: 'Poppins';
    font-weight: 600;
}

.carousel-header-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
}

.carousel-header-row a {
    font-size: 1em;
}

.carousel-dash {
    width: 96px;
    height: 3px;
    background: #FD6C73;
    border-radius: 8px;
}

.slider-container {
    position: relative;
    display: flex;
    text-align: start;
}

.carousel-km-text-content {
    position: absolute;
    background-color: #000;
    border-radius: 8px 0px;
    padding: 2px 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 1;
}

.carousel-km-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 0.6em;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #FFFFFF;
    text-align: start;
}

.carousel-address-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 0.7em;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #FFFFFF;
    flex-direction: row;
    align-self: center;
    justify-content: center;
    border-radius: 8px 0px;
    padding: 2px 0px;
    z-index: 1;
    position: absolute;
    bottom: 8px;
    left: 5px;
}

.overlay-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgba(0, 0, 0, 0.3); */
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.01) 100%);
    transition: background 0.5s ease;
    border-radius: 8px;
}

.visit-btn-container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.visit-btn {
    /* position: absolute; */
    display: flex;
    width: 100px;
    z-index: 1;
    text-align: center;
    opacity: 0;
    transition: opacity .35s ease;
    background-color: #fff;
    border: solid 1px white;
    border-radius: 10px;

}

.visit-btn a {
    width: 200px;
    padding: 5px 20px;
    text-align: center;
    color: #174ea6;
    border-radius: 10px;
    z-index: 1;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.04em;

}

.home-padded-div {
    padding: 60px 15px 10px 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.home-padded-div-two {
    padding: 30px 20px 40px 20px;
}

/* CSS By Vishakha */
/* Website:: First Page */

.m-v-30 {
    margin: 30px;
}

.m-v-60-30 {
    margin: 60px 30px 30px 30px;
}

.category-class {

    height: 343px;
}

.ml-30 {
    margin-left: 30px;
}

/*  less than or equal to 400px */
@media only screen and (max-width: 400px) {

    .footer {
        width: initial;
        padding: 16px;
        display: block;
    }

    .footer .sections:first-child {
        width: 100%;
    }

    .footer .sections .inner-sections div {
        width: 50%;
    }

    .home-header-div {
        padding: 16px 0px;
    }

    .home-search-container-inner {
        width: initial;
        margin-top: 40px;
    }

    .home-search-div,
    .home-search-div-input1 {
        width: 100%;
        height: auto;
    }

    .home-header-div {
        display: block;
        height: auto;
    }

    .home-logo-spaceBetween {
        justify-content: space-between;
    }

    .home-header-div>.flex-row {
        justify-content: flex-end;
    }

    .home-logo {
        width: auto;
        height: 25px;
    }

    .home-header-menu {
        padding: 10px 0px 0px;
    }

    .m-v-30,
    .m-v-60-30 {
        margin: 8px 0px 8px 30px;
    }

    .slider-parent-div {
        padding-right: 0px;
    }

    .m-v-30.blog-d {
        margin: 8px
    }

    .slick-slider {
        width: calc(100% - 30px);
    }

    .carousel-header-row {
        flex-wrap: wrap;
    }

    .carousel-header-row>div {
        width: 100%;
    }

    .carousel-header-row>a {
        margin-left: auto;
        margin-right: 12px;
    }

    .footer .sections h3 {
        font-size: 16px;
    }

    .footer .sections .logo-div {
        width: 100%;
    }

    .slider-parent-div .slick-list,
    .category-class {
        height: 200px;
    }

    .category-card-container {
        height: 100px;
    }

    .category-img-container {
        height: auto;
    }

    .slider-parent-div .slick-list {
        padding-top: 25px;
    }

    .ml-30 {
        margin: 0px;
    }

    .home-logo-spaceBetween {
        justify-content: flex-end;
    }

}

.slick-track {
    margin-left: unset;
    margin-right: unset;
}

.slider-parent-div .slick-list .slick-track {
    margin-left: auto;
    margin-right: auto;
}
.padding-msg {
    padding: 4px 0 !important;
}

.padding-icon {
    padding: 3px 0 !important;
}

.margin-top-15 {
    margin-top: 15px;
}

.grad-container {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    background: rgba(246, 246, 246, 0.91);
    position: fixed;
    z-index: 99;
    top: 9%;
    left: 0;
    right: 0;
    bottom: 0;
}

.grad-img {
    height: 55vw;
    width: 55vw;
    background: transparent;
    object-fit: contain;
    transform: scale(1.5);
    position: absolute;
    top: 14%;
    right: 28%;
}

.signin-arrow {
    position: absolute;
    left: 66%;
    height: 24vw;
    top: 4%;
}

/* Between 400px to 600px */
@media only screen and (max-width: 600px) and (min-width:400px) {
    .footer {
        width: initial;
        padding: 16px;
        display: block;
    }

    .footer .sections:first-child {
        width: 100%;
    }

    .footer .sections .inner-sections div {
        width: 50%;
    }

    .home-header-div {
        padding: 16px 0px;
    }

    .home-search-container-inner {
        width: initial;
        margin-top: 40px;
    }

    .home-search-div,
    .home-search-div-input1 {
        width: 100%;
        height: auto;
    }

    .home-header-div {
        display: block;
        height: auto;
    }

    .home-logo-spaceBetween {
        justify-content: space-between;
    }

    .home-header-div>.flex-row {
        justify-content: flex-end;
    }

    .home-logo {
        width: auto;
        height: 25px;
    }

    .home-header-menu {
        padding: 10px 0px 0px;
    }

    .m-v-30,
    .m-v-60-30 {
        margin: 8px 0px 8px 30px;
    }

    .slider-parent-div {
        padding-right: 0px;
    }

    .m-v-30.blog-d {
        margin: 8px
    }

    .slick-slider {
        width: calc(100% - 30px);
    }

    .carousel-header-row {
        flex-wrap: wrap;
    }

    .carousel-header-row>div {
        width: 100%;
    }

    .carousel-header-row>a {
        margin-left: auto;
        margin-right: 12px;
    }

    .footer .sections h3 {
        font-size: 16px;
    }

    .footer .sections .logo-div {
        width: 100%;
    }

    .slider-parent-div .slick-list,
    .category-class {
        height: 200px;
    }

    .category-card-container {
        height: 100px;
    }

    .category-img-container {
        height: auto;
    }

    .slider-parent-div .slick-list {
        padding-top: 25px;
    }

    .ml-30 {
        margin: 0px;
    }

    .home-logo-spaceBetween {
        justify-content: flex-end;
    }


}

/*  greater than or equal to 600px */
@media only screen and (min-width:600px) {}