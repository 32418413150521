.home-header-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.search-collapse-button{
    display: none;
    margin-left: 0; font-size:20;}
.home-search-div2 {
    /* border: 2px solid; */
    width: 100%;
    display: flex;
    flex-direction: "row";
    align-items: "center";
    justify-content: center;
}
.home-search-div2-ext {
    /* border: 2px solid; */
    width: 100%;
    display: none;
    flex-direction: "column";
    align-items: "center";
    justify-content: center;
}


.home-search-input-div2 {
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #F1F3F4;
    padding: 0 15px;
    border-radius: 3px;
}

.search-bordered {
    background-color: #F1F3F4;
    height: 44px;
    margin-right: 10px;
    margin-left: 0px;
}

.home-search-input {
    width: 100%;
    border: 0;
    outline: 0;
    padding-right: 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #707070;

}

.search-bordered .home-search-input {
    background-color: #F1F3F4;
}

.home-search-div2 .no-margin-left {
    margin-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    flex: 5;
}

.home-search-div2 .no-margin-right {
    margin-right: 0;
    position: relative;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

@media only screen and (max-width: 768px) {
    .home-search-div2 {
        display: none;
    }
    .home-search-div2-ext {
        display:flex;
        /* height: 0px; */
        overflow: hidden;
        flex-direction: column;
        transition: all .3s ease;
        max-height: 0;
    }
   
    .example-appear {
        opacity: 0.01;
      }
      
    .example-appear.example-appear-active {
        opacity: 1;
        transition: opacity .5s ease-in;
    }
      
      .search-collapse-button{
        display: flex;
        margin-left: 0; font-size:20;}
}