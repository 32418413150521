.claim-popup-container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 424px;
    
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background-color: white;
    border-radius: 0.5rem;
    font-family: Poppins, Lato;
    overflow: hidden;
    border-width: 0;

}

.claim-header {
    display: flex;
    flex-direction: row;
    padding: 3% 7%;
    height: 6.69rem;
    background: rgba(239, 246, 255, 1);
    overflow: hidden;
    box-sizing: border-box;

}

.claim-header-div1 {
    box-sizing: border-box;
    display: flex;
    width: 3rem;
    flex-direction: column;

}

.claim-header-icon-container {
    display: flex;
    box-sizing: border-box;
    overflow: hidden;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    background: rgba(255, 214, 222, 1);
    align-items: center;
    justify-content: center;
}

.claim-header-div2 {
    box-sizing: border-box;
    display: flex;
    flex: 5;
    padding-bottom: 0.5rem;
    flex-direction: column;
    justify-content: space-between;
}

.claim-header-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.13em;
    line-height: 1.5rem;
    display: flex;
    align-items: center;

    color: #283B4C;

}

.claim-header-content {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 1rem;
    letter-spacing: 0.04em;
    color: #707070;
}

.claim-header-icon {}

.claim-form {
    /* background-color: blue; */
    box-sizing: border-box;
    flex-direction: column;
    display: flex;
    width: 100%;
    padding: 1.5rem 1.7rem 1.8rem 1.5rem;
    margin-bottom: 2.4rem;
}

.claim-form .element {
    display: flex;
    flex-direction: column;
    width: 100%
}

.claim-form-field-label {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 128.91%;
    /* identical to box height, or 21px */
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #283B4C;
    margin-bottom: 0.62rem;
}

.claim-input {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    outline: 0;
    border: 0 solid #000;
    border-bottom: 1px solid hsla(0, 0%, 41.6%, .26);
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 3px 2px;
    margin-top: 0;
    margin-bottom: 24px;
    /* margin-right: 20px; */
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #283b4c;
}

.claim-file-input {
    display: none; 
}

.wid-90 {
    width: 90%;
}

.claim-err-msg {
    color: red;
    font-size: 0.8em;
    margin-top: -1.5rem;
    margin-bottom: 0.385rem;
}
.claim-doc-list{
    display: flex;
    height: fit-content;
    flex-direction: column;
    /* height: fit-content; */
    margin-top: 0.3rem;
    gap: 5px;
}
.claim-doc-list > div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: fit-content;
    padding-right: 5px;
}
.claim-doc-list > div > div{
    display: flex;
    align-items: center;
    gap: 0.7rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 128.91%;
}
.claim-actions{
    display: flex;
    gap:1rem;
    position: absolute;
    right: 1.7rem;
    bottom: 1.5rem;
}
.claim-actions Button{
    width: 4rem;
    height: 1.875rem;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-align: left;
}
.claim-actions Button Typography{
font-family: Poppins;
font-size: 12px;
font-weight: 500;
line-height: 16px;
letter-spacing: 0.04em;
text-align: left;
}