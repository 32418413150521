.listing-headlist-container {
    width: 100%;

    overflow-x: auto;
    position: relative;
    left: 0%;
    right: 0%;
    top: 4.12%;
    bottom: 92.67%;

    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.listing-headlist-container::-webkit-scrollbar {
    display: none;
  }
.listing-headlist {
    min-width: 950px;
    display: flex;
    flex-direction: row;
    margin-left: 30px;


}
.listing-headlist .hlist {
    padding: 18px 12px;
    color: #283B4C;
    margin-right: 3px;
    text-transform: capitalize;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    flex:none;
    line-height: 32px;
    
}

.listing-headlist .active {
    padding: 18px 12px 15px 12px;
    color: #FD6C73;
    border-bottom: 3px solid #FD6C73;
}

.listing-headlist :hover {
    padding: 18px 12px 15px 12px;
    color: #FD6C73;
    border-bottom: 3px solid #FD6C73;
}