.listing-card-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    margin-bottom: 15px;
}

.listing-card-heading .lheading {
    display: flex;
    align-items: center;
}

.listing-card-heading .lheading h3 {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
}

.listing-card-heading .lheading p {
    font-size: 16px;
    font-weight: normal;
    color: #283B4C;
    margin: 0px;
}

.listing-card-heading .lheading h2 {
    color: #0976b7;
    font-size: 26px;
}

.listing-card-heading .heading-relevance {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    color: #283B4C;
}

.listing-card-heading .heading-relevance div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.listing-card-heading .heading-relevance div p {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #283B4C;

}

.listing-card-heading .listing-select select {
    height: 30px;
    border: 0.4px solid rgba(112, 112, 112, 0.38);
    border-radius: 5px;
    min-width: 100px;
    margin-left: 12px;
    color: #283B4C;
    padding: 0 10px;

    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    padding-right: 15px;
    background-image: url('../../assets/icon/arrowDown.png');
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
    background-size: 10px;

}


.listing-card-div .tip {
    border-bottom-left-radius: 4px;
    background-color: #000000;
    height: 20px;
    width: 80px;
    padding-left: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #FFF;
    font-size: 13px;
    right: 20px;
    font-weight: normal;
    letter-spacing: 0.04em;
    font-weight: 600;
}

.listing-card-div .tip img {
    width: 7px;
    height: 10px;
    margin-right: 5px;
}

.listing-card-div .tip {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 4px;
    left: 0;
    position: 'relative';
    width: fit-content;
    padding-right: 10px;
}

.listing-card-div .card {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
    margin-bottom: 15px;
    border-radius: 4px;
    background-color: #FFF;
}

.listing-card-div .card .info-div {
    display: flex;
    align-items: stretch;
    flex-direction: row;
}

.listing-card-div .card .info-div .school-img {
    width: 220px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.listing-card-div .card .info-div .edu-img {
    margin: 15px 0px 0 15px;
    display: flex;
}

.listing-card-div .card .info-div .edu-img>div {
    width: 220px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.listing-card-div .card .info-div .edu-img>div img {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    border: 3px solid #0976b7;
    margin-top: 19px;
}

.listing-card-div .card .info-div .edu-img>div p {
    font-size: 13px;
    margin-top: 19px;
}

.listing-card-div .card .info-div .school-img p {
    position: absolute;
    margin-top: 100px;
    align-self: center;
}

.listing-card-div .card .info-div .school-img .tip .loc-km-text {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #FFFFFF;

}

.listing-card-div .card .info-div .school-img .established {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #FFFFFF;
    margin-bottom: 5px;
    margin-left: 5px;
}

.listing-card-div .card .card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 43px;
    background-color: #F4F9FF;
    border: 0.5px solid rgba(0, 0, 0, 0.18);
}

.listing-card-div .card .card-footer .fee-div {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #283B4C;
    opacity: 0.9;

    width: 220px;
    text-align: center;
}

.listing-card-div .card .card-footer .enquire-div,
.listing-card-div .card .card-footer .enquire-div div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.listing-card-div .card .card-footer .enquire-div .compare {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #0976b7;
    margin-right: 20px;
}

.listing-card-div .card .card-footer .enquire-div .compare input {
    margin-right: 10px;
    cursor: pointer;
}

.listing-card-div .card .card-footer .enquire-div div img {
    width: 14px;
    height: 15px;
    margin-right: 8px;
}

.listing-card-div .card .card-footer .enquire-div .enquire {
    background-color: #0A72B0;

    margin: 0 20px;
    padding: 5px 10px;
    border-radius: 5px;

    display: flex;
    flex-direction: row;
    align-items: center;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;

}

.listing-card-div .card .card-footer .enquire-div .enquire:hover {
    background-color: #095A8B;
    cursor: pointer;
}

.listing-card-div .card .info-div .info-content {
    flex: 1;
    padding: 8px 20px;
}

.listing-card-div .card .info-div .info-content .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.listing-card-div .card .info-div .info-content .title .title-div h2 {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #354857;
    margin-bottom: 4px;
}

.listing-card-div .card .info-div .info-content .title .title-div p {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #283B4C;
    display: flex;
    align-items: center;
}

.listing-card-div .card .info-div .info-content .view-share {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 4px;
}

.listing-card-div .card .info-div .info-content .view-share div.view {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: #283B4C;
    padding: 0;
    margin: 0 0 0 30px;
}

.listing-card-div .card .info-div .info-content .view-share div.share {
    display: flex;
    flex-direction: row;
    align-items: center;

    font-size: 14px;
    font-weight: 400;
    color: #4274B0;
    background: #EBF4FF;
    border: 1px solid #4274B0;
    border-radius: 4px;
    padding: 3px 14px;
    margin: 0 0 0 30px;
    /* box-shadow: 0 3px 6px 0 #4274B055; */
}

.listing-card-div .card .info-div .info-content .view-share div.share:hover {
    background-color: #E2F4FF;
    cursor: pointer;
}

.listing-card-div .card .info-div .info-content .details {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #283B4C;
    margin: 18px 0;
}

.listing-card-div .card .info-div .info-content .info-table {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    margin-left: -10px;
}

.listing-card-div .card .info-div .info-content .info-table .separator {
    width: 1px;
    background-color: #DDD;
}

.listing-card-div .card .info-div .info-content .info-table .info-tr p {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #707070;
    opacity: 0.8;
    margin-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.listing-card-div .card .info-div .info-content .info-table .info-tr h4 {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #283B4C;
    padding-left: 10px;
    padding-right: 10px;
}

.listing-content {
    display: flex;
    flex-direction: row;
    padding: 5px 40px;
    background-color: #f6f6f6;
}

.listing-content .filterButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
    background-color: #FFF;
    font-size: 16px;
    color: #707070;
    padding: 12px 10px;
    margin-bottom: 10px;
    display: none;
    cursor: pointer;
}

.listing-content .listing-filters {
    flex: 2.5;
    background-color: white;
    height: max-content;
}

.listing-content .listing-content-div {
    flex: 8;
    margin-left: 30px;
}

.listing-pagination {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 60px;
}

.listing-pagination div {
    background-color: #FFF;
    border: 1px solid #DFE3E8;
    border-radius: 4px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    cursor: pointer;
    padding-bottom: 3px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
}

.listing-pagination div.active {
    color: #0A72B0;
    border: 1px solid #0A72B0;
}

.listing-pagination div.disabled {
    background-color: #919EAB;
    border: 1px solid #919EAB;
    color: #C4CDD5;
    cursor: not-allowed;
}

/* share-tooltip-containr */
.share-tooltip-container {

    width: 370px;
    height: 192px;
    border-radius: 4px;
    display: flex;
    z-index: 10;
    background-color: #FFFFFF !important;
    opacity: 1 !important;
    box-shadow: -1px -2px 16px rgba(0, 0, 0, 0.12), 1px 4px 16px rgba(0, 0, 0, 0.12);
}

.share-tooltip-content {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.share-tooltip-content .icon-content {
    margin-top: 10px;
}

.share-tooltip-content .input-content {
    margin-top: 10px;
    border: 1px solid;
}


.share-tooltip-content .icon-content div {
    margin-top: 10px;
}

.share-tooltip-content div p {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #283B4C;
}

.share-tooltip-content div>img {
    width: 26px;
    height: 26px;
    margin-right: 23px;
    cursor: pointer;
}

.share-tooltip-content div>img:nth-child(5) {
    margin-right: 0px;
}

.share-tooltip-content .btn-content {
    margin-top: 10px;
}

.share-tooltip-content input {
    height: 40px;
    width: 233px;
    padding-left: 10px;
    padding-right: 10px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    /* color: #BABABA; */
    margin-right: 10px;
    border: 1px solid rgba(0, 0, 0, 0.38);
    border-radius: 4px;
}

.share-tooltip-content .copyurl-btn {
    background-color: #0A72B0;
    height: 35px;
    width: 82px;
    padding: 4px;
    border-radius: 4px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.share-tooltip-content .copyurl-btn:hover {
    background-color: #095A8B;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

/* Media Quiries By Vishakha */

/*  less than or equal to 400px */
@media only screen and (max-width: 400px) {
    .listing-content {
        display: block;
        padding: 4px;
    }

    .listing-content .listing-content-div {
        margin-left: 0px;
    }

    .listing-filter-div .filter-box .option-div .onethird.option {
        margin-left: 4px;
        width: calc((100% / 3) - 4px);
    }

    .listing-filter-div .filter-box .filter__container .option-div {
        max-height: none;
        height: auto;
    }

    .listing-card-heading {
        flex-wrap: wrap;
    }

    .listing-card-heading .heading-relevance {
        margin-left: auto;
        margin-top: 2px;
    }
    .listing-card-div .card .info-div {
      flex-wrap: wrap;
     
    }
    .listing-card-div .card .info-div .school-img{
        width: 100%;
        height: 270px;
        background-position: center;
        background-repeat: no-repeat;
    }
    .listing-card-div .card .info-div .info-content .title{
        flex-wrap: wrap;
    }
    .listing-card-div .card .info-div .info-content .view-share div.view,
    .listing-card-div .card .info-div .info-content .view-share div.share{
        margin-left: 0px;
        margin-right: 16px;
    }
    .title-div{
        width: 100%;
    }
    .listing-card-div .card .info-div .info-content .view-share{
        margin-left: auto;
    }
    .listing-card-div .card .info-div .info-content .info-table{
        display: block;
    }
    .listing-card-div .card .info-div .info-content .info-table .info-tr p{
        margin: 12px 0px 0px;
    }
    .listing-card-div .card .card-footer{
        flex-wrap: wrap;
        height: auto;
        padding: 6px 0px;
    }
    .listing-card-div .card .card-footer .enquire-div{
        margin-left: auto;
      
    } 
    .listing-card-div .card .card-footer .enquire-div div{
        margin: 10px 10px ;
    }
}

/* Between 400px to 600px */
@media only screen and (max-width: 600px) and (min-width:400px) {
    .listing-content {
        display: block;
        padding: 4px;
    }

    .listing-content .listing-content-div {
        margin-left: 0px;
    }

    .listing-filter-div .filter-box .option-div .onethird.option {
        margin-left: 4px;
        width: calc((100% / 3) - 4px);
    }

    .listing-filter-div .filter-box .filter__container .option-div {
        max-height: none;
        height: auto;
    }

    .listing-card-heading {
        flex-wrap: wrap;
    }

    .listing-card-heading .heading-relevance {
        margin-left: auto;
        margin-top: 2px;
    }
    .listing-card-div .card .info-div {
      flex-wrap: wrap;
     
    }
    .listing-card-div .card .info-div .school-img{
        width: 100%;
        height: 270px;
        background-position: center;
        background-repeat: no-repeat;
    }
    .listing-card-div .card .info-div .info-content .title{
        flex-wrap: wrap;
    }
    .listing-card-div .card .info-div .info-content .view-share div.view,
    .listing-card-div .card .info-div .info-content .view-share div.share{
        margin-left: 0px;
        margin-right: 16px;
    }
    .title-div{
        width: 100%;
    }
    .listing-card-div .card .info-div .info-content .view-share{
        margin-left: auto;
    }
}

/*  greater than or equal to 600px */
@media only screen and (min-width:600px) and (max-width: 800px) {
    .listing-content {
        display: block;
        padding: 4px;
    }

    .listing-content .listing-content-div {
        margin-left: 0px;
    }

    .listing-filter-div .filter-box .option-div .onethird.option {
        margin-left: 4px;
        width: calc((100% / 3) - 4px);
    }

    .listing-filter-div .filter-box .filter__container .option-div {
        max-height: none;
        height: auto;
    }

    .listing-card-heading {
        flex-wrap: wrap;
    }

    .listing-card-heading .heading-relevance {
        margin-left: auto;
        margin-top: 2px;
    }
    .listing-card-div .card .info-div {
      flex-wrap: wrap;
     
    }
    
    .listing-card-div .card .info-div .school-img{
        width: 100%;
        height: 270px;
        background-position: center;
        background-repeat: no-repeat;
    }
    .listing-card-div .card .info-div .info-content .title{
        flex-wrap: wrap;
    }
    .listing-card-div .card .info-div .info-content .view-share div.view,
    .listing-card-div .card .info-div .info-content .view-share div.share{
        margin-left: 0px;
        margin-right: 16px;
    }
    .title-div{
        width: 100%;
    }
    .listing-card-div .card .info-div .info-content .view-share{
        margin-left: auto;
    }
}