.gallery-popup-header {
  box-sizing: border-box;
  display: flex;
  height: fit-content;
  justify-content: center;
  align-content: center;
  font-size: 1.5em;
  margin-top: 10px;
  .back {
    cursor: pointer;
    position: absolute;
    left: 4%;
    top: 4%;
  }
}
.image-vid-selector {
  div {
    div {
      line-height: "1.5em";
    }
    font-family: "Poppins", "Lato";
    font-size: 1.1em;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 5px;
  }
  .selected-category {
    border-bottom: 1px solid;
    color: red;
    padding: 0;
  }
  box-sizing: border-box;
  display: flex;
  height: fit-content;
  align-items: center;
  justify-content: center;
  gap: 4em;
}

.margin {
  margin: 10px;
}

.gallery {
  color: #4274b0;
}

.close {
  cursor: pointer;
  position: absolute;
  right: 1%;
}

.popup-label {
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 2rem;
  cursor: pointer;
}

.gallery-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 1.5rem 0.625rem 1.25rem;
  overflow-y: auto;
  max-height: 474px;
}

.container {
  position: relative;
  height: 209px;
  width: 209px;
  margin: 0.5rem 0.625rem;
  .gallery-img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 0.5rem;
  }

  .overlay {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: all 0.3s ease;
    border-radius: 0.5rem;
    .text {
      font-size: 11px;
      line-height: 16px;
      color: #ffffff;
      padding-bottom: 0.625rem;
    }
  }

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
    transition: 500ms;
    border-radius: 0.5rem;
    .gallery-img {
      border-radius: 0.5rem;
    }
    .overlay {
      opacity: 0.6;
      background: #000;
    }
  }
}

.thumbnail-wrapper {
  position: relative;
  .thumbnail-img {
    height: 209px;
    width: 209px;
    margin: 0.5rem 0.625rem;
    border-radius: 0.5rem;
  }
}

.thumbnail-wrapper:hover {
  transform: scale(1.05);
  transition: 500ms ease-out;
  cursor: pointer;
}

.preview-section {
  display: flex;
  .carousel-container {
    max-width: 30vw;
    padding-left: 7.5rem;
    padding-right: 8.875rem;
    border-right: 0.5px solid #bdbdbd;
  }
  .img-gallery {
    display: flex;
    flex-wrap: wrap;
    padding: 0 108px;
    .small-img {
      width: 82px;
      height: 82px;
      margin: 0 8px;
      border-radius: 8px;
      &__active {
        border: 2px solid #0097f9;
        width: 82px;
        height: 82px;
        margin: 0 6px;
        padding: 2px;
        border-radius: 8px;
      }
    }
    .small-img:hover {
      cursor: pointer;
    }
  }
}

.preview-img {
  max-width: 30vw;
  height: 30vw;
}

.play-icon {
  background: none;
  border: none;
  position: absolute;
}

.heading-section {
  padding-left: 7.5rem;
  padding-bottom: 1.75rem;
  span {
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    color: #fd6c73;
    border-bottom: 1px solid;
  }
}
