.top-section-back {
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.top-section {
  display: flex;
  padding: 4rem 1.875rem 1.875rem 0;
  box-sizing: border-box;
}

.top-section h1{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 3rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  background: linear-gradient(146.68deg, #78DDD7 0%, #17A8E6 50%, #175CE6 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.abroad-desc {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5rem;
  margin-bottom: 3.8rem;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;

  color: #283B4C;
}

.form-sec{
  flex: 1;
  height: fit-content;
  max-width:553px;
  background-color: white;
  padding: 5rem;
  box-sizing: border-box;
  border-radius: 8px;
  margin-left: 30px;
  margin-right: -30px;
}
.form-sec .div{
  display: flex;
}
.form-sec form > div{
  display: flex;
  justify-content: space-between;
}
.form-sec form div input{
  height: 1.5rem;
  margin-bottom: 2rem;
  width: 100%;
   
    outline: 0;
    border: 0 solid #000;
    border-bottom: 1px solid hsla(0, 0%, 41.6%, .26);
    
    padding: 3px 2px;
    /* margin-right: 20px; */
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #283b4c;
}

.abroad-err-msg {
  color: red;
  font-size: 0.8em;
  margin-top: -1.5rem;
  margin-bottom: 0.385rem;
}
.form-sec .abroad-button{
  background: #4274B0;
  box-shadow: 1px 2px 8px rgba(33, 131, 228, 0.12);
  border-radius: 5px;
  border: none;
  font-family: 'Lato';
  font-style: normal;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.171429px;
  color: white;
  display: flex;
  height: 3.1rem;
  justify-content: center;
  align-items: center;
  margin-top: 3.3rem;
  cursor: pointer;
  
}

.carousel-sec {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-bottom: 13.25rem;
  padding-left: 2rem;
}

.back-world {
}

.world-img {
  width: 100%;
  max-width: 568px;
  height: auto;
}

.country-carousel {
  width: 100%;
  max-width: 360px;
}

/* Card */

.country-carousel .card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 360px;
  height: 217px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%),
    rgba(255, 255, 255, 0.6);
  box-shadow: 4.45px 7.9px 16px rgba(205, 205, 205, 0.48),
    2.45px 4.9px 8px rgba(205, 205, 205, 0.46), 1px 1px 2px rgba(0, 0, 0, 0.08),
    -1px -1px 4px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  box-sizing: border-box;
  padding: 1rem 1.25rem;
}

.card .heading-1 {
  background: linear-gradient(166.66deg, #ff636e 8.48%, #ff406f 91.55%);
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.card .heading-2 {
  background: linear-gradient(135deg, #ffc853 14.62%, #ffbb54 43.61%, #ffa157 84.62%);
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.card .heading-3 {
  background: linear-gradient(166.66deg, #7497f3 8.46%, #856ef4 91.54%);
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.card .heading-4 {
  background: linear-gradient(135deg, #e246f0 14.64%, #8c46f0 85.35%);
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.desc-points ul {
  margin-left: 1rem;
  margin-top: 0.5rem;
}

.desc-points li {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  margin: 0.375rem 0;
  color: #4274b0;
}

.desc-points li span {
  color: #1e2228;
}

/*Stepper*/

.stepper-sec {
  margin: 1rem 0;
}

.icon-comp {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  box-sizing: border-box;
}

.active-ctry {
  border: 3px solid #ffffff;
  transform: scale(1.4);
}

.ctry-label {
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
}

.back-col-1 {
  background: linear-gradient(166.66deg, #ff636e 8.48%, #ff406f 91.55%);
}

.back-col-2 {
  background: linear-gradient(135deg, #ffc853 14.62%, #ffbb54 43.61%, #ffa157 84.62%);
}

.back-col-3 {
  background: linear-gradient(166.66deg, #7497f3 8.46%, #856ef4 91.54%);
}

.back-col-4 {
  background: linear-gradient(135deg, #e246f0 14.64%, #8c46f0 85.35%);
}

.connector-color {
  border-color: #fff !important;
  border-top-style: solid !important;
  border-top-width: 3px !important;
  transform: scale(1.3) !important;
}

/*Mid Section*/

.mid-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 14.375rem;
  background-color: #ffffff;
  text-align: center;
}

.mid-section .vision-text {
  font-weight: 600;
  font-size: 1.75rem;
  line-height: 3.75rem;
  color: #283b4c;
}

.bottom-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6.75rem 0;
  background: linear-gradient(
    359.12deg,
    #eef5ff 0.78%,
    #eef5ff 51.9%,
    rgba(238, 245, 255, 0) 105.19%
  );
}

.bottom-section .why-us-head {
  font-weight: 600;
  font-size: 2.5rem;
  line-height: 3rem;
  color: #283b4c;
}

.feature-sec {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 4.5rem;
}

/*Card Content*/

.card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  padding: 1.25rem;
  margin: 0.625rem;
  background: #ffffff;
  box-shadow: 2.45px 4.9px 16px rgba(205, 205, 205, 0.46);
  border-radius: 16px;
  max-width: 287px;
  min-height: 213px;
}

.card-content .icon-container {
  height: 52px;
  width: 52px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-content .icon-container img {
  object-fit: contain;
}

.card-content h3 {
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  padding: 1.5rem 0 1.125rem 0;
  color: #1e2228;
}

.card-content p {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
