.teacher-header {
  min-height: 3rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
}

.career-sec {
  overflow-y: scroll;
}

.close {
  cursor: pointer;
  position: absolute;
  right: 3%;
  top: 3%;
}

.padding-20 {
  padding: 1.25rem;
}

.ach-heading {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #283b4c;
}
.info-section {
  display: flex;
  background: #f4f9ff;
  padding: 12px 25px;
  position: relative;
  .seperator {
    position: absolute;
    left: 50%;
    top: 0;
    border-right: 1px solid #d7d7d7;
    height: 100%;
    transform: scale(0.6);
  }
  .img-container {
    flex: 0.7;
    .profile-img {
      width: 57.69px;
      height: 56.26px;
      object-fit: contain;
      border-radius: 0.25rem;
    }
  }
  .teacher-info {
    display: flex;
    flex: 7;
    flex-wrap: wrap;
    max-width: 933px;
    .item {
      display: flex;
      flex: 50%;
      justify-content: space-between;
      .title {
        flex: 1;
        font-size: 14px;
        line-height: 20px;
        color: #707070;
      }
      .value {
        flex: 1;
        font-size: 14px;
        line-height: 20px;
        color: #283b4c;
      }
    }
  }
}
