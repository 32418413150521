.filter__container {
    /* background: #FFFFFF; */
    border-radius: 8px;
    display: flex;
    flex-flow: column;
    overflow: hidden;
}

.filter_title_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}