.slantDiv {
    background: '#F4F9FF';
    width: '200px';
    height: '25px'
}

/* WishList */

/* CSS By Vishakha */
.flex-main {
    padding: 10px;
    display: flex;
    flex-direction: row;
}

.w-3-px {
    width: 300px;
    height: 50%;
    background: #FFFFFF;
    margin-left: 10px;

}

.p-18 {
    padding: 18px;
}

.w-75-p {

    width: 75%;
    margin-left: 10px;
    background: #FFFFFF;
    padding: 15px;
}

.dis-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 8px 0;
}

.flexv-p1,
.dis-row .expandable {
    width: 48%;
}

.fl-v {
    display: flex;
    flex-direction: row;
}

.bg-p {
    width: 20%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.bg-65 {
    width: 65%;
    margin-left: 20px;
    margin-bottom: 10px;
}

.b-div {
    display: flex;
    flex-direction: row;
}

.b-div>div {
    border-right: 0.5px solid rgba(0, 0, 0, 0.38);
    padding: 10px;
}

.flex-but {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.d-logo {
    display: flex;
    flex-direction: row;
    padding: 20px;
}

.d-logo>img {
    width: 40px;
    height: 40px;
}

.h-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ml-40 {
    display: flex;
    flex-direction: row;
    margin-left: 40px;
    justify-content: space-between;
}

.ml-40 .intiiiSmall {
    padding: 20px;
    width: 400px;
}

.dis-overall {
    margin-left: 30px;
    width: 250px;
}

.flex-hg {
    display: flex;
    flex-direction: row;
    background-color: #FFFFFF;
    justify-content: space-between;
    padding: 10px;
}

.flex-hg>div {
    display: flex;
}

.ClassStan {
    background: #F4F4F4;
    padding: 5px;
    width: 70%;
    display: flex;
    justify-content: center;
}

.flex-hg>div.w-50 {
    width: 50%;
}

.flex-hg>div.w-70 {
    width: 70%;
}

.flex-hg>div.w-40 {
    width: 40%;
}

.flex-hg>div.w-30 {
    width: 30%;
}

.flex-hg>div.n-flex {
    display: block;
}

/*  less than or equal to 400px */
@media only screen and (max-width: 400px) {
    .flex-main {
        display: block;
    }

    .p-18 {
        padding: 8px;
        box-sizing: border-box;
    }

    .w-3-px {
        width: 100%;
        margin-left: 0px;
    }

    .w-75-p {
        width: 100%;
        margin-left: 0px;
        box-sizing: border-box;
        padding: 2px;
    }

    .expandable {
        width: 100%;
    }

    .dis-row {
        flex-wrap: wrap;
    }

    .flexv-p1,
    .dis-row .expandable,
    .expandableShort,
    .expandableComp {
        width: 100%;
        margin: 0px 0px 8px 0px;
    }

    .fl-v {
        display: block;
    }

    .bg-p {
        width: 100%;
    }

    .bg-65 {
        width: 100%;
        margin: 0px;
    }

    .maindiv {
        padding: 4px;
    }

    .b-div {
        display: block;
    }

    .b-div>div {
        border-right: none;
    }

    .flex-but {
        flex-direction: row;
        justify-content: space-between;
    }

    .flex-main {
        padding: 4px;
    }

    .d-logo {
        padding: 4px;
    }

    .h-flex {
        display: block;
    }

    .ml-40 {
        margin: 0px;
        display: block;
    }

    .ml-40 .intiiiSmall {
        padding: 0px;
        width: 100%;
        margin: 0px;
        text-align: justify;
    }

    .dis-overall {
        padding: 0px;
        width: 100%;
        margin: 0px;
    }
    .flex-hg {
        display: block;
        padding: 0px;
    }
    .flex-hg>div:nth-child(n){
        width: 100%;
    }
}

/* Between 400px to 600px */
@media only screen and (max-width: 600px) and (min-width:400px) {}

/*  greater than or equal to 600px */
@media only screen and (min-width:600px) and (max-width: 800px) {}

/*  greater than or equal to 600px */
@media only screen and (min-width:800px) and (max-width: 1024px) {}