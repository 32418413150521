* {
    font-family: 'Poppins', 'Lato';
    padding: 0;
    margin: 0;
}

body {
    overflow-x: hidden;
    background-color: #F5F5F9;
}

a {
    text-decoration: none;
    color: #4274B0;
    cursor: pointer;
}

h4 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #707070;
}

h4.blueblock {
    background: #E7F2FF;
    padding: 8px 20px;
    margin-bottom: 25px;
    color: #283B4C;
}

p {
    color: #75787A;
    font-size: 16px;
}

.dark {
    color: #283B4C;
}

.blue {
    color: #4274B0;
}

.flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.blue-bg {
    background-color: #E7F2FF;

}

input.pac-target-input {
    outline: 0;
    border: 0px solid #CCC;
    font-size: 16px;
    background-color: #FFF0;
    width: 25vw;
}

.modal-container {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto !important;
    top: 0;
    left: 0;
}

body.modal-container {
    overflow-y: auto !important;
}

.react-multi-carousel-dot button {
    border: 2px solid #707070;
    width: 13px;
    height: 13px;
    border-radius: 6px;
    background-color: #FFF;
    margin: 0 10px
}

.react-multi-carousel-dot--active button {
    background-color: #FD6C73;
    border: 2px solid #FFF;
}

.react-multiple-carousel__arrow {
    background: rgb(255 255 255 / 50%);
}

.react-multiple-carousel__arrow--right {
    right: 10px;
}

.react-multiple-carousel__arrow--left {
    left: 10px;
}

.progressWrapper {
    height: 4px;
    background-color: #C4C4C4;
    overflow: hidden;
}

.progressContainer {
    background-color: #C4C4C4;
}

.progressContainer>div {
    background-color: #4274B0 !important;
}

.progressLabel {
    display: none;
}


.home-banner-div {
    box-sizing: border-box;
    width: 100%;
    height: 60vh;
    min-height: 570px;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home-logo-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 20px;
}

.profile-menu {
    margin: 10px;
}

.home-logo {
    width: 119px;
    height: 37px;
}

.home-logo-spaceBetween {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.banner-girl {
    display: flex;
    width: 35%;
    justify-content: flex-start;
    height: 100%;
}

.home-search-container .banner-girl img {
    display: flex;
    margin-top: 10px;
    width: calc(95% + 0px);
    height: calc(90% - 40px);
    object-fit: cover;
    background-size: cover;
}

.header-menu img {
    width: 45px;
    height: 51px;
    overflow: hidden;
    filter: grayscale(100%);
}


.header-menu p {
    text-align: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.2vw;
    line-height: 24px;
    color: #000000;
    border-bottom: 3px solid #FFFFFF;
    overflow: hidden;
    inline-size: 80%;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.home-search-inner {
    display: flex;
    flex: 9;
}

.react-multi-carousel-dot-list {
    margin-top: 20px;
}

.listing-heading-title {
    padding: 20px 50px 10px 0px;
    background-color: #F6F6F6;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #283B4C;
}

.listing-heading-title span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6B6B6B;
    cursor: pointer;
}

.listing-heading-title span:hover {
    color: #283B4C;
}

.listing-heading-title span:last-child {
    color: #283B4C;
}

.listing-filter-div {
    width: 100%;
    background-color: #FFFFFF;
}

.listing-filter-div .filter-box .filter-section-baseheight {
    min-height: 54px;
    height: 7vh;
    overflow-y: hidden;

    animation-duration: 2s;
    animation-name: open;
}

.listing-filter-div .filter-box .filter-section-maxheight {
    min-height: 54px;
    height: 10.5vh;
    overflow-y: hidden;
}

.listing-filter-div .filter-box .filter-section-maxheight-active {
    max-height: 50vh;
    overflow-y: auto;
    animation-duration: 2s;
    animation-name: open;
}

.listing-filter-div .header {
    padding: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.listing-filter-div .header h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #283B4C;
}

.listing-filter-div .header p {
    padding-bottom: 2px;
    border-bottom: 1px solid #0005;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #EF1111;
}

.listing-filter-div .filter-selected {
    padding: 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.listing-filter-div .filter-selected div.filters {
    padding: 4px 6px;
    margin-bottom: 6px;
    margin-right: 6px;
    border: 0.5px solid #D1D1D1;
    border-radius: 4px;
}

.listing-filter-div .filter-selected div.filters div {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    color: #283B4C;
}

.listing-filter-div .filter-selected div.filters div p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #283B4C;
}

.listing-filter-div .filter-selected div.filters div span {
    margin: 0px 0px -7px 10px;
    display: inline-block;
    cursor: pointer;
}

.listing-filter-div .filter-box {
    padding: 10px 12px;
    border-bottom: 1px solid #DDD;
    height: fit-content;
}

.listing-filter-div .filter-box h4 {
    margin-top: 10px;
    margin-bottom: 22px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: #283B4C;
}

.listing-filter-div .filter-box h4 p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #283B4C;
}

/* .listing-filter-div .filter-box .filter-section-baseheight{
    min-height: 54px;
    height: 7vh;
    overflow-y: hidden;
   
}

.listing-filter-div .filter-box .filter-section-maxheight{
    min-height: 54px;
    height: 10.5vh;
    overflow-y: hidden;
  
}
.listing-filter-div .filter-box .filter-section-maxheight-active{
    max-height: 50vh;
    overflow-y: auto;
} */


.listing-filter-div .filter-box .option-div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 54px;
    height: 20vh;
    overflow-y: auto;
    transition: transform 500ms ease 25ms;
    /* transition: height 2s; */
}

.listing-filter-div .filter-box .option-div-hide {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 0vh;
    overflow-y: hidden;
    /* transition: height 2s; */

    transform: translateX(0px) translateY(50px);
    margin-top: -10px;
    margin-bottom: -10px;
}

.filter_option {
    height: 15vh;
    /* transition: height 500ms ease 25ms; */

    /* animation-duration: 2s;
    animation-name: open; */
}


.filter_option_hide {
    display: none;
    height: 0;
    transition: height 500ms ease 25ms;
}

.listing-filter-div .filter-box .option-div-maxheight {
    min-height: 54px;
    height: 10.5vh;
    overflow-y: hidden;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.listing-filter-div .filter-box .option-div-active {
    max-height: 50vh;
    overflow-y: auto;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.listing-filter-div .filter-box .option-div .onethird {
    width: 33%;
}

.listing-filter-div .filter-box .option-div .onethird label {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.listing-filter-div .filter-box .option-div .half {
    width: 50%;
}

.listing-filter-div .filter-box .option {
    font-size: 14px;
    letter-spacing: 0.04em;
    /* color: #333; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    align-items: center;
    height: 3.5vh;
    min-height: 18px;
}

.listing-filter-div .filter-box .option input {
    margin-right: 6px;
    cursor: pointer;
}

.listing-filter-div .filter-box div.option p {
    color: #283B4C;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.listing-filter-div .filter-box p.more {
    font-family: 'Poppins', 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.04em;
    color: #1F5CA2;
    margin-bottom: 8px;
    margin-top: 4px;
    cursor: pointer;
}

.listing-filter-div .filter-box .location-filter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 14px 0;
}

.listing-filter-div .filter-box .location-filter p {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    font-size: 12px;
    color: #283B4C;
}

.listing-filter-div .filter-box .markers {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0 10px 0;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #707070;
    opacity: 0.8;
}

/* flip card */
.flip-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    /* border: 0.5px solid rgba(0, 0, 0, 0.18); */
}

.flip-card {
    margin-top: 1rem;
    height: 274px;
    width: 300px;
    position: relative;
    border-radius: 0.25rem;
    margin-right: 20px;
    margin-bottom: 50px;
}

.cardFront,
.cardBack {
    box-sizing: border-box;
    border-radius: 0.25rem;
    height: 100%;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    width: 100%;
    transition: transform 0.5s ease;
    position: absolute;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #F4F9FF;
}

.cardBack img {
    height: 238px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    width: 100%;
}

.cardBack {


    /* border: 0.5px solid rgba(0, 0, 0, 0.38); */

    border-radius: 0px 4px 4px 4px;
    transform: perspective(1000px) rotateY(180deg);
}

.cardBack.flipped {

    padding-left: 0;
    padding-right: 0;
    transform: perspective(1000px) rotateY(0deg);
}

.cardFront {
    padding: 10px;

    /* border: 0.5px solid rgba(0, 0, 0, 0.38); */

    border-radius: 0px 4px 4px 4px;
    transform: perspective(1000px) rotateY(0deg);
}

.cardFront.flipped {
    transform: perspective(1000px) rotateY(180deg);
}

.signin-div {
    width: 70%;
    min-width: 700px;
    max-width: 900px;
    background-color: #FFF;
    align-items: stretch;
    position: relative;
}

.signin-div>div {
    flex: 1;
    overflow-x: hidden;
}

.signin-div>div .signin-slider-div {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 400%;
    transition: all 1s;
}

.signin-div>div .signin-slider-div div {
    float: left;
}

.signin-slider {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.signin-slider img {
    height: auto;
    width: 100%;
}

.signin-slider div.sigin-slider-info h3 {
    text-align: center;
    font-weight: bold;
    font-size: 26px;
    line-height: 42px;
    background: linear-gradient(to right, #78DDA6 0%, #17A8E5 50%, #175CE5 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.signin-slider:nth-child(2) div.sigin-slider-info h3 {
    background: linear-gradient(to right, #E2AC5B 0%, #CF80D0 0%, #3854BA 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.signin-slider:nth-child(3) div.sigin-slider-info h3 {
    background: linear-gradient(to right, #DD78A8 0%, #E59317 0%, #E517B8 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.signin-slider p {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #283B4C;
    padding: 5px 20px 10px 20px;
}

.signin-slider-dots {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 20px;
}

.signin-slider-dots>div {
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background-color: #C8CACC;
    margin: 0 4px;
    transform: all 1s;
}

.signin-slider-dots>div.active {
    width: 14px;
    background-color: #0976B7;
}

.form-container {
    padding: 30px 20px;
    background-color: #FFF;
    width: fit-content;
    /* width: 48vw; */

}

.form-group {
    display: flex;
    flex-direction: row;
    /* width: 48vw; */
}


.form-container input::placeholder {
    color: grey;
}

.form-container .placeholder {
    color: gray
}

.form-container select {
    color: #ccc;
}

.form-container select:required:invalid {
    color: gray;
}

.form-container option {
    color: black;
}


.form-container input,
.form-container select {
    outline: 0;
    border: 0px solid #000;
    border-bottom: 1px solid rgba(106, 106, 106, 0.26);
    box-sizing: border-box;
    filter: drop-shadow(0px 4px 2px rgba(79, 161, 233, 0.1));
    flex: 1;
    padding: 8px 2px;

    margin-bottom: 28px;
    margin-right: 20px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #283B4C;

}

.form-container textarea {
    outline: 0;
    border: 0px solid #000;
    border-bottom: 1px solid rgba(106, 106, 106, 0.26);
    box-sizing: border-box;
    filter: drop-shadow(0px 4px 2px rgba(79, 161, 233, 0.1));
    flex: 1;
    padding: 8px 2px;
    height: auto;
    margin-right: 20px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #BABABA;
}

.form-container .submit-div {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 20px 20px 0px 0px;
    align-items: center;
}

.form-container .submit-div button {

    color: #FFFFFF;
    background: #0A72B0;
    border-radius: 4px;
    padding: 5px 36px;
    border: 0px solid #000;
    height: 35px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

}

.form-container .submit-div button:hover {
    background-color: #095A8B;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 10px;
}

.form-container .submit-div p {
    color: #0A72B0;
    cursor: pointer;
    padding: 12px 36px;
}

.form-container .send-div {
    display: flex;
    margin-top: 5vw;
}

.form-container .send-div button {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.171429px;
    min-width: 30vw;
    color: #FFFFFF;

    align-self: center;

    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    padding: 12px 36px;
    border: 0px solid #000;
    background: #0A72B0;
    /* background: linear-gradient(0deg, #1953E7 -22.83%, #30D3E0 118.48%); */
    box-shadow: 4px 4px 8px rgba(33, 131, 228, 0.26);
    border-radius: 5px;
}

.form-container .send-div button:hover {
    background: #095A8B;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 10px;
}

.form-container .send-div button:active {
    background: #4274B0;
    /* background: linear-gradient(0deg, #17A8E6 -22.83%, #1953E7 118.48%); */
    transform: translateY(1px);
}

.form-container .send-div button img {
    width: 17px;
    height: 17px;
}

.form-container .send-div p {
    color: #4274B0;
    padding: 12px 36px;
}


.form-container .form-upload-group {
    height: 60px;
    border: 1px dashed #4274B0;
    box-sizing: border-box;
    justify-content: space-between;
    padding: 0 16px 0 24px;
}

.form-container .form-upload-group h4 {
    color: #283B4C;
}

.form-container .form-upload-group>div {
    font-size: 16px;
    line-height: 24px;
    color: #4274B0;
    border: 1px solid #4274B0;
    padding: 4px 10px;
    filter: drop-shadow(1px 1px 2px rgba(66, 116, 176, 0.08));
    border-radius: 4px;
}

.form-container .form-upload-group>div:hover {
    background-color: #E2F4FF;

}

.form-container .upload-allow {
    font-size: 10px;
    line-height: 18px;
    color: #939599;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 4px 0 16px 0;
}

.form-container .upload-file-div {
    margin-bottom: 10px;
}

.form-container .upload-file-div .upload-content {
    flex: 1;
}

.form-container .upload-file-div .file-type-img img {
    margin-right: 16px;
    width: 40px;
    height: 50px;
}

.form-container .upload-file-div .upload-content>div p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #283B4C;
}

.form-container .upload-file-div .upload-content>div {
    justify-content: space-between;
    width: 100%;
}

.form-container .upload-file-div .upload-content>div:first-child {
    margin-bottom: 8px;
}

.form-container .upload-file-div .upload-content>div:nth-child(3) {
    margin-top: 8px;
}

.form-container .upload-file-div .upload-content>div:nth-child(3) p {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #707070;
}

.paid {

    background-color: #85E1B5;
    max-width: 60px;
    text-align: center;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 4px;

    color: #707070;
}

.form-container .submit-content {}

.form-container .submit-content h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #283B4C;
}

.form-container .submit-content .submit-note {
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: -20px;
    margin-right: -20px;
    height: 15vh;
    background-color: #F4F9FF;
    padding: 15px 20px;
}

.form-container .submit-content .submit-note h6 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #1E2228;
}

.form-container .submit-content .submit-note ul {
    list-style: none;
    padding: 0 15px;
    padding-top: 10px;
}

.form-container .submit-content .submit-note ul li::before {
    content: "\2022";
    color: #FD7E85;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
    margin-top: 2px;
}

.form-container .submit-content .submit-note ul li {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #707070;
}

.form-container .submit-content .agree-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 40px;
}

.form-container .submit-content .agree-content .agree-checkbox {
    width: 14px;
    flex: 0;
    margin: 0px 15px 0px 0px;
}

.form-container .submit-content .agree-content .agree-text {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #283B4C;
}

/* .form-container .submit-content .agree-content input[type="checkbox"] {
    width: 20px;
    background-color: red;
    border: 2px solid;
    margin-left: -100px;

} */

.form-container .submit-div .fee-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.CircularProgressbar {
    width: 60%;
    vertical-align: middle;
    cursor: pointer;
}

.CircularProgressbar .CircularProgressbar-path {
    stroke: #FD6C73;
}

.CircularProgressbar .CircularProgressbar-text {
    fill: #000;
    font-size: 35px;
}

/* dashboard details page */

.scroll {
    max-height: 900px;
    overflow-y: auto;
}

.scrollToTop {
    position: fixed;
    /* width: 100%; */
    left: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    bottom: 40px;
    border-radius: 50%;
    /* height: 20px; */
    font-size: 3rem;
    padding: auto;
    z-index: 1;
    cursor: pointer;
    background-color: white;

    opacity: 1;
}

.cursor-pointer {
    cursor: pointer;
}

.text-center {
    text-align: center;
}

.scroll-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
}

.scroll-container .indicator {
    height: 100px;
    background: #FD6C73;
}


.slick-dots {
    position: absolute;
    transform: translateX(-37%);
    left: 35vw;
}

.slick-dots.slick-thumb {
    display: flex;
    left: 1vw;
}

.slick-dots.slick-thumb li {
    width: 4vw;
    height: 0.2vw;
    margin: 0 3px;
    border-radius: 999px;
    transition: all 0.5s ease-in-out;
    will-change: width;
    background-color: #D9D9D9;
}

.slick-dots.slick-thumb li.slick-active {
    background-color: #FD6C73;
    width: 5vw;
}

/*testimonia slider end  */

.slick-dots li button::before {
    font-size: 20px;
    color: grey;
    opacity: 0.7;
}

.slick-dots li.slick-active button::before {
    color: #FD6C73;
    font-size: 20px;
    opacity: 1;
}

.slick-arrow.slick-prev,
.slick-arrow.slick-next {
    background-color: #FFFFFF;
    width: 40px;
    height: 40px;
    display: flex;
    margin-top: -40px;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    z-index: 1;
    align-self: center;
}

.educator-arrow {
    background-color: #FFFFFF;
    width: 40px;
    height: 40px;
    display: flex;
    margin-top: 0px;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    z-index: 1;
    align-self: center;
}

.slick-prev {
    margin-left: 5px;
}

.slick-next {
    margin-right: 5px;
}


.slick-prev:hover,
.slick-next:hover {
    background-color: rgb(226, 226, 226);
}

.slick-prev::before,
.slick-next::before {
    display: none;
    align-self: center;
}

.carousel-img-skel{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 8px;
}

.carousel-img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
    background: linear-gradient(180deg, rgba(236, 243, 251, 1) -9.27%, rgba(236, 243, 251, 1) 66.67%, rgba(0, 0, 0, 0.53) 109.27%);
    border-radius: 8px;
}

.carousel-experiance-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #707070;
    margin-top: 6px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.carousel-teacher-name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #283B4C;
    margin-left: -5px;
    margin-top: 10px;
}

.column-60 {
    height: 100%;
    width: 60%;
    min-height: 100px;

}

/* category backgorund new design*/

.column-60 .row-spaceBetween {
    height: 100%;

    display: flex;
    justify-content: space-between;
}

.column-40 {
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    width: 40%;
    min-height: 170px;
    margin-right: 60px;

}

.column-60 .category-card h3 {
    font-size: 1.5vw;
    align-self: flex-start;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    /* font-size: 20px; */
    line-height: 28px;
    margin-left: 23px;

}

.row-startCenter {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.category-card {
    flex-direction: column;
    align-items: flex-start;
    display: flex;

    padding: 10px;
    border-radius: 4px;
    /* margin-right: 15px; */
    border: 1px solid #E0E0E0;
    transition: 1.0s;
    /* transition: color 1.0s ease-in-out, box-shadow 1.0s ease-in-out; */
    background-color: #FFFFFF;
    box-shadow: inset 0 0 0 0 #7BC6CC;

    width: 30%;
    max-height: 270px;

    min-height: 150px;
    min-width: 150px;
}

.category-card .card-item {
    height: 300px;
    width: 100%;
    overflow-y: auto;
    align-items: flex-start;
    margin-left: 15px;
    margin-right: 15px;
}

.category-card .card-item h6 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #707070;
    transition: 1.0s;
    display: flex;
    width: fit-content;
    align-items: center;
    margin-top: 5px;
    padding: 4px 10px;
    border: 1px solid transparent;
    border-radius: 4px;
}

.category-card .card-item h6:hover {
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    color: #FFFFFF;
    background-color: #A9BBD9;
}


.category-card:hover {
    /* transition:  1.0s; */
    background-color: #86A8E7;
    /* background: linear-gradient(to bottom, #BE93C5, #86A8E7); */
    box-shadow: inset 110px 0 0 0 #7BC6CC;
    color: white;

}

.column-40 .category-feature-card {
    display: flex;
    flex-direction: column;
    width: 88%;
    height: 100%;

    min-width: 200px;
    margin-right: 15px;
    padding-left: 20px;
    /* background: linear-gradient( to bottom,  #BE93C5, #86A8E7 ); */
}

.category-feature-card img {
    width: 7px;
    height: 14px;
    /* top: -15px; */
}

.category-feature-card-item {
    margin-top: 20px;
    width: 90%;
    height: 70%;
    min-width: 10%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}


.category-feature-card-item p {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;

    color: #707070;
}

.column-40 .explore {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    color: #4274B0;
    transition: 1.0s;
    width: 130px;
    border-radius: 4px;
    padding-left: 5px;
    padding-right: 5px;

}

.column-40 .category-feature-card h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #000000;
}

.column-img1 {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 4px;

}

/* typing indicator */

.indicator {
    /* background-color: wheat; */
    height: 60vh;
    /* width: 50%; */
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    justify-content: space-evenly;
}

.row-spaceBetween {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    overflow-x: auto;

}

.left-right-icon {
    display: flex;
    height: 1vh;
    width: 0.7vh;
}

.modal-professor-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* border: 2px solid; */
    margin-top: 20px;
    margin-bottom: 20px;
}

.modal-professor-content img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.12);
    margin-right: 20px;

}


.modal-professor-content div h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #283B4C;
}

.modal-professor-content div p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #939599;
}

.modal-vertical-line {
    width: 0px;
    height: 100%;
    border: 0.5px solid rgba(0, 0, 0, 0.38);
}

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
}

/* collapse component */

.blue_text {
    color: #52C8E6;
}


.collapsible__body {
    transition: opacity 0.4s ease, max-height 0.4s ease;
    max-height: 0px;
    opacity: 0;
    padding: 0px 10px;
    overflow: hidden;
}

.button_icon__open {
    transform: rotate(180deg);
    transition: transform 0.4s ease-in-out;
    cursor: pointer;
}

.button_icon {
    transform: rotate(0deg);
    transition: transform 0.4s ease;
    cursor: pointer;
}

/*  */

/* filter */
/* collapse component */


.filter-div {
    width: 65%;
    background-color: #FFF;
    align-items: stretch;
    border-radius: 8px;
}

.filter-content-div {
    padding: 40px 48px;
    max-height: 60vh;
    overflow: hidden;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.filter-content-div-column {

    padding: 40px 48px;
    max-height: 30vh;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.filter-seacrh-content {
    border: 0.5px solid #707070;
    height: 35px;
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
    width: 50%;
}

.filter-seacrh-content input::before {
    color: #707070;
}

.filter-seacrh-content input {
    width: 100%;
    border: 0;
    outline: 0;
    padding-right: 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #283B4C;
}

.filter-content-div .close-icon {

    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    row-gap: 10px;

}

.filter-option-content {
    display: flex;
    width: 100%;
    height: 300px;
}

.filter-option {
    width: 33%;
    margin-bottom: 10px;
}

.filter-column {
    flex: 1;
    color: white;
}

.filter-close {
    margin-top: -50px;
    margin-right: -30px;
}

/* Modal */
.fmodal-input-header {
    padding: 10px 10px;
    min-height: 40px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: 'wrap';
}

.fmodal-input-content {
    border: 0.5px solid #707070;
    height: 35px;
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 0vw;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    align-items: 'center';
}

.fmodal-data-group {
    display: flex;
    padding: 10px 10px;
    max-height: 65vh;
    height: 300px;
    overflow-Y: auto;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 20px;
}

.svg-y {
    color: #6B6B6B;
    margin: 12px 30px 12px 12px;
    cursor: pointer;
}

.search-in{
    
        width: 200%;
        height: 30px;
        padding-left: 1px;
        margin-top: 50px;
        margin-bottom: 50px;
        border: none;

}
.rangeslider-horizontal {
    height: 5px;
    border-radius: 0;
    margin: 15px 9;
}

.rangeslider-horizontal .rangeslider__fill {
    background-color: #FD6C73;
    ;
}

.rangeslider-horizontal .rangeslider__handle:after {
    display: none;
}

.rangeslider-horizontal .rangeslider__handle {
    width: 12px;
    height: 12px;
    border-radius: 6px;
    border: 1px solid #FD6C73;
    box-shadow: 0 1px 3px rgb(0 0 0 / 0%), 0 -1px 3px rgb(0 0 0 / 0%);
}

/* CSS By Vishakha */
.w-38{
    display: flex ;flex-direction: row; width: 38%; justify-content: space-between;
}