.home-login-button-div {
    width: 228px;
    height: 40px;
    /* margin: 0 30px; */
    margin-right: 50px;
    border-radius: 4px;
    background-color: #4274B0;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 10;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
}

.profile-menu img {
    height: 22px;
    width: 24px;
}

.profile-menu p {
    margin-left: 10px;
    margin-bottom: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #283B4C;
}

.profile-menu .hline {

    border: 0.5px solid rgba(0, 0, 0, 0.16);
    margin-top: 8px;
    margin-bottom: 8px;
}