.footer {
    width: calc(100% - 50px);
    display: flex;
    background-color: #242F3D;
    padding: 60px 25px 40px 25px;
}

.footer .sections {
 /*    flex: 1; */
    margin-right: 30px;
    display: flex;
    flex-direction: column;
}

.footer .sections .logo-div {
    width: 360px;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFF;
    border-radius: 8px;
}

.footer .sections .connect-div h4 {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;

    color: #FFFFFF;

    margin-top: 26px;
    margin-bottom: 10px;


}

.footer .sections .connect-div p {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #6D83A1;
    margin-top: 5px;
}

.footer .sections .social-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;
    margin-bottom: 30px;
}

.footer .sections .social-div img {
    width: 33px;
    height: 33px;

    margin-right: 10px;
}

.footer .sections .app-div {
    margin: 20px 0;
}

.footer .sections .app-div img {
    max-width: calc(45% - 10px);
    width: auto;
    margin-right: 10px;
    height: 45px;

}

.footer .sections h3 {
    font-weight: 400;
    font-size: 24px;
    color: #FFF;
    margin-bottom: 14px;
}

.footer .sections p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #6D83A1;
    margin-bottom: 6px;
}

.footer .sections p:hover {
    color: #FFFFFF;
}

.footer .sections .inner-sections {
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
    flex-wrap: wrap;
}

.footer .sections .inner-sections div {
  /*   flex: 1 */
  margin-bottom: 25px;
  width: 25%;
}