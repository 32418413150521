.sub_header {
    height: 5vh;
    min-height: 40px;
    padding: 12px;
    padding-left: 35px;
    padding-right: 35px;
    overflow: hidden;
    overflow-x: auto;
    border: 1px solid #D7D5EA;
}

.sub_header_inner {
    width: 100%;
    min-height: 30px;
    min-width: 800px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    /* border:1px solid green; */
}

.search_left_sh {
    padding: 5px;
    width: 30%;
    min-width: 200px;
    /* border:1px solid blue; */
}

.search_sh {
    height: 100%;
    display: flex;
    padding-right: 8px;
    justify-content: space-between;
    border-radius: 10px;
    border: 1px solid #D7D5EA;
}

.search_img_sh {
    display: flex;
    align-items: center;
}

.search_sh input {
    margin-left: 10px;
    width: 80%;
    height: 100%;
    outline: 0;
    border: 0;
    background-color: inherit;
}

.filters_right_sh {
    padding: 5px;
    /* width: 40%; */
    /* min-width: 250px; */
    display: flex;
    justify-content: space-between;
    /* border:1px solid black; */
}

.fliter_container_sh {
    width: 62%;
    /* min-width: ; */
    display: flex;
    justify-content: space-between;
    padding: 3px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
    border: 1px solid #D7D5EA;
}

.filter_sh {
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* border:1px solid brown; */
}

.filter_sh input {
    width: 49%;
    outline: 0;
    border: 0;
    background-color: inherit;
}

.filter_sh font {
    display: flex;
    align-items: center;
}

.dropdown_sh {

    padding: 3px;
    padding-left: 10px;
    /* width: 30%; */
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    border: 1px solid #D7D5EA;
}

.dropdown_img_sh {
    display: flex;
    align-items: center;
}

.dropdown_sh select {
    outline: 0;
    border: 0;
    width: 80%;
    background: inherit;
}

.inputStudent {
    border: 0;
    outline: 0;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height, or 150% */
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: #334D6E;
}

/* .inputStudent::-webkit-calendar-picker-indicator{
    display: none;
    -webkit-appearance: none;
} */

.app {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    justify-content: center;
    display: flex;
    color: #283B4C;
    margin-bottom: '10px';
}

.smallheading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    /* or 133% */
    margin-bottom: '10px';
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.04em;
    justify-content: center;
    color: #283B4C;
}

.headingOption {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    margin-left: '20px';
    color: #283B4C
}


.headingCard {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #283B4C;
}

.smallSchoolHeading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    letter-spacing: 0.04em;
    margin-top: 5px;
    color: #707070;
}

.ClassStan {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #283B4C;
    margin-top: 5px;
}

.StatusButton {

    position: absolute;
    width: 125px;
    height: 36px;
    background: #FFFFFF;
    mix-blend-mode: darken;
    border: 0.666667px solid #0976B7;
    border-radius: 4px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    cursor: pointer;
    letter-spacing: 0.04em;
    color: #0976B7
}

.ProcessButton {
    position: absolute;
    width: 114px;
    height: 36px;
    border: 0;
    background: #FFF0C8;
    border-radius: 4px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #CF8C0A;
}

.rejectedButton {
    position: absolute;
    width: 114px;
    height: 36px;
    border: 0;
    background: #FFDADA;
    border-radius: 4px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #C15D5D;
}

.acceptedButton {
    position: absolute;
    width: 114px;
    height: 36px;
    border: 0;
    background: #CCF3C6;
    border-radius: 4px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #459A38;
}

.congrats {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    letter-spacing: 0.04em;

    /* Grey 100 */

    color: #1E2228
}

/* .slantDiv{
    background: '#F4F9FF';
    width:'200px';
    height:'25px'
} */

.dis-bottomdiv {
    background: #F4F9FF;
    /* margin-left: 820px; */
    width: 180px;
    padding: 5px;
    display: flex;
    justify-content: center;
    /* margin-top: 30px; */
    margin-block-end: auto;
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #707070;
    clip-path: polygon(0 0,
            100% calc(100% - 29vw),
            100% 100%,
            0 100%);
    border-top-right-radius: 12px;
}

.instiHeading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 133% */
    color: #354857;

}

.intiiiSmall {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* or 171% */
    color: #707070
}

.maindiv {
    background: #FFFFFF;
    border: 1px solid #D6D6D6;
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    display: 'flex';
    flex-direction: row;
    margin-bottom: 10px;
}

.dis-Review {
    background: #E1EFFF;
    height: 7vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px;

}

.dis-ReviewLable {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    color: #707070;
    /* border: 1px solid blue; */
    margin-top: 8px;
    width: 70%;

}

.dis-detailas {
    background: #FFFFFF;
    height: fit-content;
    margin: 15px;
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */

}

.dis-partition {
    background: #4274B0;
    width: 6px;
}

.dis-Reviewdiv {
    /* border:1px solid brown; */
    width: 40%;
    padding: 5px;

}

.dis-Reviewdiv2 {
    /* border:1px solid brown; */
    /* style={{ display: "flex", flexDirection: "row" }} */
    /* width:70% ; */
    padding: 5px;
    display: flex;
    flex-direction: row;

}




.dis-Schoolname {
    /* style={{ display: "flex", flexDirection: "row" }}  */
    display: flex;
    flex-direction: row;
    /* border: 1px solid navy; */
    margin-top: 15px;
    margin-left: 20px;
}

.dis-school {
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height, or 120% */


    color: #4274B0;
}

.dis-place {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height, or 123% */

    letter-spacing: 0.04em;

    color: #707070;

}

.dis-overall {
    width: 60%;
    justify-content: space-between;
    /* border:1px solid red; */

}

.dis-rating {
    /* style={{display:"flex" ,flexDirection:"row"}} */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px;

}

.dis-OvLable {
    background: #FFC600;
    border-radius: 3px;
    padding: 3px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 35px;

}

.dis-ReviewRating {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */
    color: #707070;
}




.dis-image {

    border: 1px solid #F0F0F0;
    padding: 2px;
    border-radius: 5px;

}

.headingOver {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */


    /* Grey 100 */

    color: #1E2228
}

.settingsMaindiv {
    background: #FFFFFF;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    margin: 15px;
    padding: 30px;
}

.line_purple {
    width: 37px;
    height: 4px;
    margin-top: 4px;
    margin-bottom: 8px;
    background: #FD6C73;
    ;
}

.settingButton {
    cursor: pointer;
    border: 0;
    outline: 0;
    padding: 8px;
    background: #4274B0;
    border-radius: 4px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #FFFFFF;
}

.tippp {
    position: absolute;
    background-color: #000;
    border-radius: 8px 0px;
    padding: 2px 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 1;
    width: 130px;
    /* border-bottom-left-radius: 4px;
    background-color: #000000;
    height: 20px;
    width: 80px;
    padding-left: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #FFF;
    font-size: 13px;
    right: 20px;
    font-weight: normal;
    letter-spacing: 0.04em;
    font-weight: 600; */
}

.loctext {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #FFFFFF;
    margin-top: 3px;
}

.estab {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #FFFFFF;
    position: relative;
    margin-top: 130px;
    margin-left: 10px;
}

.bottomdivcard {
    background: #F4F9FF;
    border-radius: 0px 0px 8px 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    padding: 8px;
    color: #283B4C;

    opacity: 0.9
}

.laticHeading {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    margin-top: 5px;
    margin-bottom: 5px;
    color: #283B4C;
}

.removeButton {
    background: #FFFFFF;
    border: 1px solid #DEDEDE;
    border-radius: 4px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 10px;
    color: #1E2228;
    cursor: pointer;
}

.OtpHeading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    margin-left: 20px;
    color: #1A2334;

}

.SendButton {
    background: #FFFFFF;
    border: 1px solid #4274B0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
    border-radius: 4px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    padding: 8px;
    line-height: 16px;
    letter-spacing: 0.01em;
    margin-left: 10px;
    color: #0976B7;
}

.inputOtp {
    width: 50px;
    height: 50px;
    border: none;
    outline: 0;
    border-bottom: 1px solid rgba(106, 106, 106, 0.6);
    justify-content: center;
    align-items: center;
    align-content: center;
    display: flex;
    text-align: center;
    /* filter: drop-shadow(0px 4px 4px rgba(79, 161, 233, 0.26));
    border-radius: 5px;
    transform: matrix(1, 0, 0, -1, 0, 0); */
}



.verifyButton {
    background: #4274B0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
    border-radius: 4px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    padding: 10px;
    margin-top: 20px;
    width: 100%;
    /* or 171% */
    border: none;
    color: #FFFFFF
}

.Otpqoute {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    /* or 133% */

    letter-spacing: 0.01em;

    color: #323C47;
}

.newheading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */


    color: #283B4C
}

.expandable {
    outline: 0;
    border: none;
    border-bottom: 1px solid rgba(106, 106, 106, 0.6);
    width: 48%;
    margin-bottom: 20px;
    line-height: 200%;
}

.expandableComp.pac-target-input:not(:-webkit-autofill),
.expandableComp {
    outline: 0;
    border: none;
    border-bottom: 1px solid rgba(106, 106, 106, 0.6);
    /* width: 50%; */
    margin-bottom: 20px;
    line-height: 200%;
}

.pac-target-input:not(:-webkit-autofill) {
    outline: 0;
    border: none;

}

.pac-target-input:not(:-webkit-autofill) {
    width: 100%;
}

.expandableShort {
    border: none;
    outline: 0;
    border-bottom: 1px solid rgba(106, 106, 106, 0.6);
    width: 24%;
    margin-bottom: 20px;
    line-height: 200%;
}

.ViewButton {
    outline: none;
    border: none;
    background: #0A72B0;
    border-radius: 4px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 10px;
    /* identical to box height, or 143% */

    cursor: pointer;
    color: #FFFFFF;

}

.ViewButton:disabled {
    background: #F1F1F1;
    color: #D7D7D7;
}

.StartTest {
    background: #FFFFFF;
    border: 1px solid #4274B0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
    border-radius: 4px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #0976B7;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 20px;
    padding-right: 20px;
}

.select-wrappers {
    width: 120px;
    position: relative;
    /* margin-right: 80px; */
}

.select-wrappers:after {
    /*  content: url(img/select-drop.svg);
      width: 0;
      height: 0;
      position: absolute;
      top: 0px;
      right: 10px; */
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #666;
    position: absolute;
    right: 20px;
    top: 10px;
    pointer-events: none;
}

.expandale-input {

    outline: 0;
    min-height: 23px;
    /* max-height: 90px; */
    border: none;
    /* border-bottom: 1px solid #E0E0E0; */
    /* font-weight: 400; */
    /* font-size: 13px; */
    /* line-height: 128.91%; */
    line-height: 200%;
    /* letter-spacing: 0.01em; */
    /* margin-bottom: 0px; */
    width: 100%;
    /* /* color: var(--proctur-black); */

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.expandale-input.w-200 {
    /* width: 200px; */
    margin-right: 80px;
    -webkit-appearance: none;
    border: none;
    outline: 0;


}

.parent-row {
    display: flex;
    margin: 0.5rem 0;
}

.chk-container {
    padding: 10px 0;
    margin-top: 8px;
    display: flex;
    align-items: flex-start;
    margin-right: 30px;
}

.chk-container input {
    width: 16px;
    height: 16px;
}

.parent-label {
    display: flex;
    flex-direction: column;
    margin-top: -6px;
    padding-left: 10px;
}

.parent-label .main-label {
    color: #283B4C;
    margin-bottom: 4px;
}

.parent-label .hint {
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    color: #354857;
}

.add-btn-sec {
    display: flex;
    align-items: center;
}

.add-btn-sec .add-child-btn {
    background: #FFFFFF;
    border: 1px solid #4274B0;
    color: #4274B0;
    font-size: 14px;
    border-radius: 4px;
    padding: 4px 22px;
    cursor: pointer;
}

.child-info-box {
    display: flex;
    justify-content: space-between;
    width: 44%;
    background: #F4F9FF;
    padding: 0.625rem 1.5rem;
    margin-right: 1rem;
    border-left: 4px solid #4274B0;
}

.child-info-box .child-name {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #283B4C;
}

.child-info-box .icons-sec {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.mar-btm-20 {
    margin-bottom: 20px;
}
.changediv {
    /* width: 343px;
    height: 48px;
    left: 568px;
    top: 220px; */
    padding: 10px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px 8px 0px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.flexv-p1 {
    border-bottom: 1px solid rgba(106, 106, 106, 0.6);
    width: 45%;
    display: inline-flex;
    align-items: center;
    margin-bottom: 20px
}

.flexv-p1>a {
    font-weight: 400;
    font-size: 13px;
    line-height: 128.91%;
    /* identical to box height, or 17px */
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #109CF1;

}


.pro-pic {
    height: 98px;
    width: 98px;
    margin-bottom: 16px;
    margin-left: 32px;
    cursor: pointer;
}

.basic-det {
    display: flex;
    flex-direction: column;
}

.basic-det input {
    color: #283B4C; 
}

.notification {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 133% */


    color: #283B4C
}

.formHeading {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 32px;
    /* identical to box height, or 133% */

    letter-spacing: 0.01em;

    color: #FFFFFF;
}

.formHeading2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 133% */

    display: flex;
    align-items: center;

    color: #283B4C;
}

.smallHeading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 133% */

    display: flex;
    align-items: center;
    text-align: right;

    color: #707070
}

.StudeeHeading {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 44px;
    /* identical to box height */

    letter-spacing: 0.01em;

    color: #333333
}

.addressHeading {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    /* identical to box height, or 200% */

    display: flex;
    align-items: center;
    letter-spacing: 0.01em;

    color: #333333
}

.Reciept {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    width: 22%;
    /* font-size: 18px; */
    /* line-height: 32px; */
    /* or 133% */


    color: #4274B0
}

.Appliedto {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height, or 100% */
    background: #E5F0FF;
    letter-spacing: 0.01em;
    color: #4274B0;
    width: 100px;
    height: 20px;
    display: flex;
    align-items: center;
}

.InstName {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 550;
    font-size: 13px;
    /* line-height: 1px; */
    /* identical to box height, or 100% */
    letter-spacing: 0.01em;
    /* padding: 5px; */
    color: #283B4C;
}

.Powered {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 114% */

    text-align: right;
    letter-spacing: 0.04em;

    color: #858E96;
    display: flex;
    justify-content: center;
    padding: 5px;
}

.Details {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 100% */

    display: flex;
    align-items: center;
    letter-spacing: 0.01em;

    color: #F5F7FA
}

.SchoolName {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    /* line-height: 24px; */
    /* identical to box height, or 133% */

    color: #707070
}



/*Add Child CSS*/

.mod-container {
    padding: 1.5rem;
}

.add-child-heading {
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
}

.fields-container {
    margin-top: 0.675rem;
}

.inp-row {
    display: flex;
    justify-content: space-between;
    margin: 30px 0;
}

.mob-no-sec {
    display: flex;
}

.mob-no-sec select {
    border: none;
    margin-right: 0.5rem;
}

.child-inp {
    border: none;
    outline: 0;
    width: 47%;
    border-bottom: 1px solid rgba(106, 106, 106, 0.6);
}

.width-70-per {
    width: 70%;
}

.button-sec {
    display: flex;
    justify-content: flex-end;
}

@media screen and (max-width: 1115px) {
    .dis-Reviewdiv2 {
        flex-direction: column;
    }

    .dis-overall {

        width: 100%;
    }

    /* .dis-detailas{
        flex-direction: column;
    } */


}

/* CSS By Vishakha */
.mt-20 {
    margin-top: 20px;
}

.w-f-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.navBar-out {
    display: flex;
    flex-direction: row;
    background: white;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
}

.navBar-out>div:not(.w-38) {
    margin-left: 30px;
}

/*  less than or equal to 400px */
@media only screen and (max-width: 400px) {
    .settingsMaindiv {
        box-shadow: none;
        padding: 0px;
    }

    .mt-20 {
        margin: 20px 0px 10px;
    }

    .w-f-wrap {
        flex-wrap: wrap;
    }

    .w-f-wrap>button {
        margin-top: 10px;
        margin-left: auto;
    }

    .navBar-out {
        margin-top: 5vh;
        margin-left: 0px;
        display: block;
    }

    .navBar-out>div:not(.w-38) {
        margin-left: 0px;
    }

    .navBar-out>div.w-38>div {
        margin-top: 8px;
    }

}


/* Between 400px to 600px */
@media only screen and (max-width: 600px) and (min-width:400px) {}

/*  greater than or equal to 600px */
@media only screen and (min-width:600px) and (max-width: 800px) {}

/*  greater than or equal to 600px */
@media only screen and (min-width:800px) and (max-width: 1024px) {}