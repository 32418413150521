.offer-div {
    width: 498px;
    height: 600px;
    overflow: hidden;
    align-items: stretch;
    background: linear-gradient(180deg, #E8F7F2 -2.56%, #FFFFFF 61.24%);
    border-radius: 4px;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.offer-div .offer-img-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.offer-div img {
    width: 247px;
    height: 182.29px;
}

.offer-div .offer-img-content p:nth-child(2) {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 42px;
    background: linear-gradient(129.59deg, #65D3B4 14.55%, #1892E6 53.21%, #186CE6 90.3%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    margin-top: 20px;
    margin-bottom: 20px;
}

.offer-div .offer-img-content p:nth-child(3) {
    width: 70%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #283B4C;
}

.offer-div .offer-btn {
    background-color: #0A72B0;

    mix-blend-mode: darken;
    border-radius: 48px;
    height: 43px;
    width: 196px;
}

.offer-div .offer-btn p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
}

.offer-div .offer-btn:hover {
    background-color: #095A8B;
    /* box-shadow: 0 3px 10px rgb(0 0 0 / 0.2); */
    box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
}