.enquiry-popup-container {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    z-index: 1001;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    left: 0px;
    top: 0px;
}

.enquiry-popup-container .enquiry-div {
    width: 498px;
    height: 600px;
    overflow: hidden;
    background-color: #FFF;
    align-items: stretch;

}

.enquiry-popup-container .enquiry-div .enquiry-heder-div {
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.enquiry-popup-container .enquiry-div .enquiry-heder-div h2 {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    color: #283B4C;
}

.enquiry-popup-container .enquiry-div .enquiry-heder-div .horline {
    background-color: #FD6C73;
    width: 100px;
    height: 2px;
    margin-top: 8px;
}

.enquiry-popup-container .enquiry-div .enquiry-heder-div .enquiry-close {
    margin-top: -30px;
}

.enquiry-popup-container .enquiry-div .enquiry-form {
    padding: 20px;
}

.enquiry-popup-container .enquiry-div .enquiry-form div:nth-child(5) {
    margin-top: 40px;
}

.enquiry-popup-container .enquiry-div .enquiry-form h4 {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #283B4C;
}

.enquiry-popup-container .enquiry-div .enquiry-form input {

    outline: 0;
    border: 0px solid #000;
    border-bottom: 1px solid rgba(106, 106, 106, 0.26);
    box-sizing: border-box;
    width: 100%;

    padding: 8px 2px;
    margin-top: 4px;
    margin-bottom: 28px;
    margin-right: 20px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #283B4C;
}

.enquiry-popup-container .enquiry-div .enquiry-form .enquiry-btn {
    background-color: #4274B0;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 4px;
    margin-bottom: -20px;
    cursor: pointer;
}

.enquiry-popup-container .enquiry-div .enquiry-form .enquiry-btn p {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}