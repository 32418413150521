.location-div {
    padding: 40px 48px;
    max-height: 80vh;
    overflow-y: auto;
}

.location-div h2 {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 30px;
    color: #283B4C;

    overflow: hidden;

    display: -webkit-box;
 
    -webkit-box-orient: vertical;
}

.location-div h4 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #283B4C;
    margin-bottom: 10px;
    margin-top: 40px;
}

.location-div div.separator {
    height: 3px;
    width: 80px;
    background-color: #FD6C73;
    margin: 12px 0 40px 0;
}

.location-div .close-icon {
    margin-top: -20px;
    margin-right: -25px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.location-div .detect-location {
    cursor: pointer;
    background: #F3F9FF;
    padding: 12px;
    margin-left: 14px;
}

.location-div .detect-location img {
    margin: 0 10px 0 10px;
}

.location-div p.conly {
    margin: 5px 10px 0 0;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #707070;
}

.location-div .edu-level {
    margin: 20px 0;
}

.location-div .search-parent {
    width: 65%;
}

.location-div .search-div {
    width: 100%;
    height: 48px;
    background-color: #F1F3F4;
}

.location-div .search-div input {
    color: #283B4C;
}

.location-div .search-button {
    background-color: #4274B0;
    height: 48px;
    width: 48px;
    justify-content: center;
    border-radius: 4px;
    margin: 0 20px 0 8px;
}

.location-div .edu-level button {
    padding: 12px 40px;
    border: 1px solid rgba(0, 0, 0, 0.38);
    border-radius: 4px;
    display: inline-block;
    margin: 0 8px 8px 0;
    color: #707070;
    font-size: 16px;
    cursor: pointer;
    background: none;
}

.location-div .edu-level button.active {
    border: 1px solid #4274B0;
    color: #4274B0;
}

.location-div .edu-level button:disabled {
    cursor: auto;
}

.location-div .standard-level div {
    padding: 9px 18px;
    border: 0.5px solid rgba(0, 0, 0, 0.38);
    border-radius: 24px;
    display: inline-block;
    margin: 0 10px 8px 0;
    color: #707070;
    font-size: 16px;
    cursor: pointer;
}

.location-div .standard-level div.active {
    background-color: #4274B0;
    border: 0.5px solid #4274B0;
    color: #FFF;
}
.button-container {
    display: flex;
    justify-content: flex-end;
    padding-top: 1.25rem;
}
.submit-button {
    display: flex;
    background-color: #4274B0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 10;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    padding: 0.75rem 1.25rem;
    border-radius: 4px;
    border: none;
}

.submit-button:disabled {
    background-color: #D7D7D7;
}