.home-header-div {
    box-sizing: border-box;
    width: 100%;
    padding-left: 50px;
    /* overflow: hidden; */
    /* padding-right: 20px; */
    height: 72px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 100;
    background-color: #FFF;
}
.header-space{
    margin-top: 72px;
}

.home-header-div img.avatar {
    width: 36px;
    height: 36px;
    border-radius: 18px;
    margin-right: 12px;
}

.home-header-div h4 {
    font-size: 17px;
}


.home-header-select {
    border-width: 0px;
}

.home-header-div .dropdown {

    position: absolute;
    z-index: 3;
    background-color: #FFF;
    top: 8vh;
    /* margin-right: 30px; */
    height: max-content;
    right: 0px;
    border-radius: 4px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
}