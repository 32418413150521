.changedivv {
    /* width: 343px;
    height: 48px;
    left: 568px;
    top: 220px; */
    padding: 10px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px 8px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.ThankYou {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    /* identical to box height, or 125% */


    color: #283B4C
}

.Congo {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    /* or 133% */

    text-align: center;

    color: #707070
}

.buttonView {
    background: linear-gradient(0deg, #1953E7 -22.83%, #30D3E0 118.48%);
    box-shadow: 4px 4px 8px rgba(33, 131, 228, 0.26);
    border-radius: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 133% */
    text-align: center;
    color: #FFFFFF;
    outline: 0;
    border: 0;
    padding: 5px;
    width: 100%;
    cursor: pointer;
}

.buttonHomePage {
    filter: drop-shadow(4px 4px 8px rgba(33, 131, 228, 0.26));
    border-radius: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 133% */

    text-align: center;

    background: linear-gradient(0deg, #1953E7 -22.83%, #30D3E0 118.48%), #FFFFFF;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    outline: 0;
    border: 1px solid #1953E7;
    padding: 5px;
    width: 100%;
    cursor: pointer;
}


.cancelButton {
    filter: drop-shadow(4px 4px 8px rgba(33, 131, 228, 0.26));
    border-radius: 5px;
    border: 1px solid #1953E7;
    padding: 5px;
    cursor: pointer;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 133% */
    text-align: center;
   width: 48%;
    background: linear-gradient(0deg, #1953E7 -22.83%, #30D3E0 118.48%), #FFFFFF;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    
}

.submitButton {

    background: linear-gradient(0deg, #1953E7 -22.83%, #30D3E0 118.48%);
    box-shadow: 4px 4px 8px rgba(33, 131, 228, 0.26);
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 133% */
    width: 48%;
    text-align: center;
    outline: 0;
    border:0;
    color: #FFFFFF;
}