.content-section {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0.5rem auto 0;
  max-width: 1425px;
}

.wid-75 {
    width: 75%;
}

.organization {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 34px 20px 25px 20px;
  background: #fff;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}

.organization .title-section {
  display: flex;
  padding-top: 1.25rem;
}

.organization .org_thumbnail {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 1px 1px 14px rgba(0, 0, 0, 0.25);
}

.organization .title-section .title {
  color: #283b4c;
  font-size: 14px;
}

.organization .title-section .verified {
  width: 12px;
  height: 15px;
  margin-left: 8px;
  margin-top: 2px;
  object-fit: contain;
}

.organization .location {
  font-size: 14px;
  color: #707070;
}

.organization .loc-icon {
  width: 8px;
  height: 11px;
  margin-right: 6px;
}

.org-info {
  max-width: 288px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 2.5rem;
}

.org-info .info-item {
  display: flex;
  flex: 50%;
  margin-bottom: 2rem;
}

.org-info .info-item .icon {
  width: 1rem;
  height: 1rem;
  border-radius: 0.25rem;
  margin-top: 0.375rem;
}

.text-section {
  display: flex;
  flex-direction: column;
  margin-left: 0.625rem;
}

.text-section .category {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #656565;
  opacity: 0.8;
}

.text-section .value {
  font-size: 14px;
  color: #707070;
}

.nav-section {
  display: flex;
  justify-content: space-between;
  margin: 1.25rem auto 0;
  max-width: 1425px;
}

.nav-section .back-container {
    display: flex;
}

.nav-section .back-container .back {
    font-size: 14px;
    color: #283B4C;
}

.nav-section .nav-links {
    display: flex;
}

.nav-section .nav-links img {
    width: 26px;
    height: 26px;
}
