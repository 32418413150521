.apply-div {
    padding: 10px 30px;
    width: calc(100% - 60px);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.apply-div .apply-school-div {
    width: calc(47% - 18px);
    margin-right: 18px;
    background-color: #FFF;
}

.apply-div .apply-form-div {
    width: 100%;
    /* width: fit-content; */
}

.apply-div .apply-school-div h1 {


    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #283B4C;
    padding: 26px;
}

.apply-div .apply-school-div img {
    width: 100%;
}

.apply-div .apply-school-div .school-info {
    padding: 24px 0 15px 0;
}

.apply-div .apply-school-div .school-info>div {
    padding: 16px 24px;
    margin-bottom: 7px;
    height: 58px;
}

.apply-div .apply-school-div .school-info>div h4,
.apply-div .apply-school-div .school-info>div p {
    flex: 1;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #283B4C;
}

.apply-div .apply-school-div .school-info>div p {
    flex: 1;
    text-align: right;
}



.apply-div .apply-header {
    background-color: #FFF;
    margin-bottom: 16px;
    height: 90px;
    padding: 24px 0px 0px 20px;
}

.apply-div .apply-header div.inner {
    flex: 1;
}

.apply-div .apply-header div.inner div p {
    flex: 1;
    height: 4px;
    background-color: #EBEBF2;
}

.apply-div .apply-header div.inner div p.hide {
    background-color: #EBEBF200;
}

.apply-div .apply-header div.inner div div.step {
    display: flex;
    width: 44px;
    height: 44px;
    border-radius: 22px;
    justify-content: center;
    align-items: center;
    background-color: #EBEBF2;
    color: #707070;
}

.apply-div .apply-header div.inner h4 {


    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #283B4C;

    margin: 5px 0 0 0;
    height: 48px;
}

.apply-div .apply-header div.active div p,
.apply-div .apply-header div.active div div.step {
    background-color: #FD6C73;
    color: #FFF;

    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-align: center;

}

.apply-div .apply-header div.active h4 {
    color: #283B4C;
}

/* CSS By Vishakha */
.listing-heading-title.apply-p {
    padding-left: 10px;
}

.form-container{
    width: 100%;
    padding: 8px;
}

/*  less than or equal to 400px */
@media only screen and (max-width: 400px) {
    .apply-div {
        display: block;
        padding: 8px;
        width: 100%;
        box-sizing: border-box;
    }

    .apply-div .apply-school-div {
        width: 100%;
        margin-right: 0px;
    }

    .apply-div .apply-header {
        padding: 0px;
    }

    .form-container input:not(:last-child),
    .form-container select:not(:last-child),
    .form-container input#dob {
        margin-right: 12px;
    }

    .form-container input:last-child,
    .form-container select:last-child {
        margin-right: 0px;
    }
    .form-container.apply-p .form-upload-group{
        padding: 8px;
    }
}

/* Between 400px to 600px */
@media only screen and (max-width: 600px) and (min-width:400px) {
    .apply-div {
        display: block;
        padding: 8px;
        width: 100%;
        box-sizing: border-box;
    }

    .apply-div .apply-school-div {
        width: 100%;
        margin-right: 0px;
    }

    .apply-div .apply-header {
        padding: 0px;
    }

    .form-container input:not(:last-child),
    .form-container select:not(:last-child),
    .form-container input#dob {
        margin-right: 12px;
    }

    .form-container input:last-child,
    .form-container select:last-child {
        margin-right: 0px;
    }
    .form-container.apply-p .form-upload-group{
        padding: 8px;
    }
}

/*  greater than or equal to 600px */
@media only screen and (min-width:600px) and (max-width: 800px) {
    .apply-div {
        display: block;
        padding: 8px;
        width: 100%;
        box-sizing: border-box;
    }

    .apply-div .apply-school-div {
        width: 100%;
        margin-right: 0px;
    }

    .apply-div .apply-header {
        padding: 0px;
    }

    .form-container input:not(:last-child),
    .form-container select:not(:last-child),
    .form-container input#dob {
        margin-right: 12px;
    }

    .form-container input:last-child,
    .form-container select:last-child {
        margin-right: 0px;
    }
    .form-container.apply-p .form-upload-group{
        padding: 8px;
    }
}

/*  greater than or equal to 600px */
@media only screen and (min-width:800px) and (max-width: 1024px) {
    .apply-div {
        display: block;
        padding: 8px;
        width: 100%;
        box-sizing: border-box;
    }

    .apply-div .apply-school-div {
        width: 100%;
        margin-right: 0px;
    }

    .apply-div .apply-header {
        padding: 0px;
    }

    .form-container input:not(:last-child),
    .form-container select:not(:last-child),
    .form-container input#dob {
        margin-right: 12px;
    }

    .form-container input:last-child,
    .form-container select:last-child {
        margin-right: 0px;
    }
    .form-container.apply-p .form-upload-group{
        padding: 8px;
    }
}