.compare-content {
    margin: 20px 30px;
    width: calc(100% - 61px);
    border: 0.5px solid rgba(0, 0, 0, 0.18);
}

.compare-content>div {
    flex: 1;
    border-right: 0.5px solid rgba(0, 0, 0, 0.18);
    text-align: center;
}

.compare-content>div p {
    background-color: #F4F9FF;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    color: #283B4C;

}

.compare-content>div:first-child p {
    font-weight: 400;
    font-size: 14px;
    color: #707070;
}

.compare-content>div.active p {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #283B4C;
}

.compare-content>div p:nth-child(2n+1) {
    background-color: white;
}

.compare-content>div.active p:nth-child(2n+1) {
    background-color: #E7F2FF;
}

.compare-content>div:last-child {
    border-right: 0px solid rgba(0, 0, 0, 0.18);
}

.compare-content>div p.star div {
    background-color: #FFC600;
    display: inline-block;
    height: 26px;
    width: 50px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #FFF;
}

.compare-content>div p.facility {

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.compare-header {
    margin: 20px 30px;
    width: calc(100% - 60px);
    align-items: flex-start;
}

.compare-header>div h4 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #283B4C;
    margin-bottom: 8px;
}

.compare-header>div p {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #707070;
}

.compare-header>div {
    flex: 1;
    padding: 10px;
}

.compare-header .schoolName {
    display: block;
    overflow: hidden;
    word-wrap: break-word;
    text-overflow: ellipsis;
    color: "#4274B0";

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    cursor: pointer;
}

.compare-header>div div.imgdiv {
    width: 100%;
    height: 160px;
    margin-bottom: 16px;
}

.compare-header>div img.imgdiv {
    width: 100%;
    height: 160px;
    margin-bottom: 16px;
    object-fit: contain;
}

.compare-header .compare-apply {
    justify-content: space-between;
    margin-top: 20px;
}

.compare-header .compare-apply button {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    background: #0A72B0;
    border-radius: 4px;
    padding: 5px 30px;
    border: 0px solid #CCC;
    height: 40px;
}

.compare-header .compare-apply button:hover {
    background-color: #095A8B;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

.compare-header .compare-apply .shortlist-div {
    background: #FFFFFF;
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    width: 40px;
    height: 40px;
    justify-content: center;
    cursor: pointer;
}

.compare-header .compare-apply .shortlist-div:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

.compare-blank .select-compare {
    border: 0.5px solid rgba(0, 0, 0, 0.38);
    box-sizing: border-box;
    border-radius: 4px;
    width: calc(100%);
    margin-top: 10px;

}

.compare-blank .select-compare .select-first {
    width: calc(31% - 1px);
    border-right: 0.5px solid rgba(0, 0, 0, 0.38);
    padding: 2%;
    background-color: #FFF;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.compare-blank .select-compare .select-first select,
.compare-blank select {
    background-color: #0000;
    border: 0px solid #000;
    outline: 0;
    color: #707070;
    width: 100%;
}

.compare-blank select.sschool {


    background-color: #FFF;
    height: 40px;
    border: 0.4px solid rgba(112, 112, 112, 0.38);
    border-radius: 5px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #283B4C;
    padding: 0 10px;

    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    padding-right: 15px;
    background-image: url('../../assets/icon/arrowDown.png');
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
    background-size: 10px;
}

.compare-blank .select-compare input[type="text"] {
    background-color: #0000;
    border: 0px solid #000;
    outline: 0;
    color: #707070;
    padding: 2%;

}

.compare-blank p.or {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 10px;
    color: #707070;
    text-align: center;
    width: 100%;
    padding: 12px 0;
}


.compare-main-div {
    overflow-x: auto;
}

.compare-child-div {
    min-width: 1100px;
}


/* CSS By Vishakha */

/*  less than or equal to 400px */
@media only screen and (max-width: 400px) {

    .flex-row.compare-header,
    .flex-row.compare-content {
        margin: 8px;
        width: calc(100% - 16px);
    }

    .flex-row.compare-header>div:first-child,
    .flex-row.compare-content>div:first-child {
        display: none;
    }

    .compare-header>div,
    .compare-content>div {
        flex: initial;
        width: calc(100% / 3);
    }

    .compare-child-div {
        min-width: 100%;
    }

    .compare-header>div div.imgdiv {
        height: 60px;
    }

    .compare-blank .select-compare input[type="text"] {
        overflow-y: hidden;
        width: 70%;
        font-size: 15px;
    }

    .compare-blank .select-compare input[type="text"]:placeholder-shown {
        text-overflow: ellipsis;
        word-break: break-all;
        line-clamp: 1;
        -webkit-line-clamp: 1;
    }

    .compare-blank select.sschool {
        padding: 0px 10px 0px 4px;
        height: 30px;
        text-overflow: ellipsis;
    }

    .compare-header>div img.imgdiv {
        height: 60px;
        padding: 4px;
        width: auto;
    }

    .flex-row.compare-apply {
        flex-wrap: wrap;
    }

    .flex-row.compare-header>div:nth-child(2) {
        text-align: center;
    }

    .compare-header .compare-apply button {
        width: 100%;
        padding: 5px;
    }

    .shortlist-div.flex-row {
        margin: 8px auto;
    }
}

/* Between 400px to 600px */
@media only screen and (max-width: 600px) and (min-width:400px) {}

/*  Between 600px to 800px  */
@media only screen and (min-width:600px) and (max-width:800px) {}

/*  Between 800px to 1024px  */
@media only screen and (min-width:600px) and (max-width:1024px) {}