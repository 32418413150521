/*  ------------- Listing page --------------*/
@media only screen and (min-width: 2880px) {
    .banner-girl {
        margin-top: 15vh;
    }

    .home-search-container .banner-girl img {
        z-index: 1;
        width: calc(95% + 40px);
    }
}

@media only screen and (max-width: 2880px)and (min-width: 1400px) {
    .carousel-img-container {
        max-width: 20vw;

    }

    .carousel-img-container:hover {
        max-width: 20vw;
    }

    .home-search-div {
        width: 100%;
    }

    .home-search-container .banner-girl img {
        z-index: 1;
        width: calc(95% + 40px);
    }
}

@media only screen and (max-width: 1400px)and (min-width: 1160px) {
    .home-header-div {
        height: 64px;
        /* overflow: hidden; */
    }
    .header-space{
        margin-top: 64px;
    }

    .home-login-button-div {
        height: 40px;
        /* margin: 0 40px; */
        font-size: 18px;
    }

    .home-search-div {
        width: 87%;
    }

    .contact-mail-div .contact-city-content {
        height: fit-content;
    }

    .contact-mail-div .contact-city-content .city-card {

        padding-bottom: 20px;
        background: #EEF6FF;
        border-radius: 12px;
    }

    .details-sidebar .recommend-content .filter-section-baseheight {
        height: 50vh;
        overflow-y: scroll;
    }

    .home-search-container h1 {
        font-size: 3.0vw;
    }

    .home-logo-content {
        margin-bottom: 20px;
    }

    .home-logo-content h2 {
        font-size: 3.0vw;
    }

    .home-skill-list-div {
        padding: 16px 1.5% 18px 1.5%;
    }

    .home-skill-list-div h4 {
        font-size: 18px;
    }

    .home-skill-list-div p {
        font-size: 12px;
    }

    .home-view-more {
        font-size: 16px;
    }

    .home-main-heading {
        font-size: 22px;
    }

    .home-institute-card h4 {
        font-size: 20px;
        margin: 6px 10px;
    }

    .home-educator-card .content table .attrName,
    .home-educator-card .content table .attrValue {
        font-size: 15px;
    }

    .home-educator-card .avatar-content h5 {
        font-size: 20px;
    }

    .home-educator-card .avatar-content p {
        font-size: 14px;
    }

    .home-blog .blog-section .blog-img {
        height: 220px;
    }

    .home-blog .blog-section .blog-content h4 {
        font-size: 21px;
    }

    .home-blog .blog-section .blog-content p {
        font-size: 15px;
    }

    .home-categories .category-section {
        height: 390px;
    }

    .home-categories .category-section div {
        font-size: 27px;
    }

    /* testimonial */
    .testimonial-container {
        height: 90vh;
    }

    .figure-container {
        height: 80vh;
    }

    .figure-row-div {
        width: 30vh;
    }

    .figure-row-div2 {
        width: 30vw;
    }

    .testimonial-btn {
        width: 6vh;
        height: 6vh;
    }

    .left-right-icon {
        height: 2vh;
        width: 1vh;
    }

    .img-fluid0:hover {
        box-shadow: 0 0 0 7px #4274B0;
    }

    .img-fluid1:hover {
        box-shadow: 0 0 0 8px #4274B0;
    }

    .img-fluid2:hover {
        box-shadow: 0 0 0 7px #4274B0;
    }

    .indicator1 {
        width: 2vw;
        height: 2vw;
    }

    .indicator1::before,
    .indicator1::after {
        height: 3vw;
        width: 3vw;
        bottom: 2vw;
        right: 2vw;
    }

    .indicator1::after {
        height: 5vw;
        width: 5vw;
        right: 4vw;
        bottom: 4.8vw;
    }

    .indicator2 {
        width: 2vw;
        height: 2vw;
    }

    .indicator2::before,
    .indicator2::after {
        bottom: -0.5vw;
        right: 3vw;
        height: 3.5vw;
        width: 3.5vw;
    }

    .indicator2::after {
        height: 5vw;
        width: 5vw;
        right: 8vw;
        bottom: -1vw;
    }

    .indicator3 {
        width: 2vw;
        height: 2vw;
    }

    .indicator3::before,
    .indicator3::after {
        height: 3.2vw;
        width: 3vw;
        bottom: -4vw;
        right: 2vw;
    }

    .indicator3::after {
        height: 5vw;
        width: 5vw;
        right: 5vw;
        bottom: -9vw;
    }


    /*  */
    .home-search-container .banner-girl img {
        width: 35vw;
        object-fit: contain;
        height: 45vw;
    }

    .banner-girl {
        display: flex;
        width: 35%;
        justify-content: flex-start;
        overflow: hidden;
        margin-top: -19vh;
        margin-left: -16vh;
    }

    .carousel-img-container {
        max-width: 20vw;
    }

    .carousel-img-container:hover {
        max-width: 20vw;
    }

    .certificate-container-text {
        font-size: 0.8em
    }

    .carousel-educator-content {
        margin-top: -4px;
        padding-top: 15px;
        width: 17.6vw;
        min-height: 18vh;
    }

    .carousel-teacher-name {
        font-size: 0.95em;
        margin-left: -5px;
    }

    .carousel-school-text {
        font-size: 1em;
    }

    .carousel-experiance-text {
        font-size: 0.8em;
    }

    .educator-qua-container {
        width: 18vw;
    }

    .educator-content {
        min-width: 17vw;
    }

    .educator-qua-text {
        font-size: 0.7em;
    }

    .educator-qua-value-text {
        font-size: 0.7em;
    }

    .visit-btn {
        width: 9vw;
    }

    .visit-btn a {
        padding: 3px 10px;
        font-size: 11px;
    }

    .blog-content {
        /* overflow-x: auto;
        overflow-y: hidden; */
    }

    .blog-column-50 {
        width: 60vw;
        margin-right: 10px;
    }

    .blog-visibtn-content {
        /*        width: 45vw; */
        width: 100%;
    }

    .blog-column-60-child {
        margin-right: 10px;
        width: 49%;
    }

    .blog-child-overlay-content {
        width: 100%;
    }

    .blog-child-img {
        width: 100%;
    }

    .category-card {
        margin-right: 10px;
    }

    .form-container {
        padding: 14px;
    }

}

@media only screen and (max-width: 1160px) and (min-width: 800px) {
    .home-banner-container{
        height: 20vh;
    }
    .home-banner-container .slick-dots{
        bottom: 16vh;
    }
    .home-banner-btn{
        position: static;
    }
    .listing-header-div .login-button {
        display: none;
    }

    .listing-header-div .search-div {
        width: calc(100% - 250px);
    }

    .listing-content {
        flex-direction: column;
    }

    .listing-content .listing-filters {
        margin-top: 20px;
    }

    .listing-content .listing-filters .listing-filter-div {
        display: none;
    }

    .listing-filter-div .filter-box .full {
        width: auto;
    }

    .listing-content .listing-content-div {
        margin-left: 0;
    }

    .listing-card-div .card .info-div .info-content .info-table .info-tr p {
        font-size: 14px;
    }

    .listing-card-div .card .info-div .info-content .info-table .info-tr h4 {
        font-size: 16px;
    }

    .contact-mail-div {
        width: 50%;
        height: -moz-fit-content;
        /* height: fit-content; */

    }

    .contact-mail-div .contact-mail-container {
        padding: 40px 30px;
        width: auto;
        height: 30vh;
        background-color: #FFFFFF;
    }

    .contact-mail-div .contact-city-content {
        width: auto;
        height: 35vh;
        background-color: #FFFFFF;
    }



    .contact-mail-card {
        width: 42%;
    }

    /*  */
    .footer {
        flex-direction: column;
    }

    .home-blog .blog-section {
        width: calc(50% - 23px);
        display: none;
    }

    .home-blog .blog-section:nth-child(1),
    .home-blog .blog-section:nth-child(2) {
        display: block;
    }

    .home-categories .category-section {
        width: calc(50% - 20px);
        margin-bottom: 20px;
    }

    .home-skill-list-div {
        width: calc(30.33% - 15px);
        ;
    }

    .listing-login {
        display: none;
    }

    .listing-search-div {
        width: 100%;
    }

    .listing-content .filterButton {
        display: flex;
    }

    .apply-div {
        flex-direction: column;
    }

    .apply-div .apply-school-div,
    .apply-div .apply-form-div {
        width: 100%;
        margin-bottom: 24px;
    }

    /* testimonial */
    .figure-container {
        height: 57vh;
    }

    .figure-row-div {
        width: 14vh;
    }

    .figure-row-div2 {
        width: 30vw;
    }

    .img-fluid0:hover {
        box-shadow: 0 0 0 7px #4274B0;
    }

    .img-fluid1:hover {
        box-shadow: 0 0 0 8px #4274B0;
    }

    .img-fluid2:hover {
        box-shadow: 0 0 0 7px #4274B0;
    }

    .indicator1 {
        width: 2vw;
        height: 2vw;
    }

    .indicator1::before,
    .indicator1::after {
        height: 3vw;
        width: 3vw;
        bottom: 2vw;
        right: 2vw;
    }

    .indicator1::after {
        height: 5vw;
        width: 5vw;
        right: 4vw;
        bottom: 4.8vw;
    }

    .indicator2 {
        width: 2vw;
        height: 2vw;
    }

    .indicator2::before,
    .indicator2::after {
        bottom: -0.5vw;
        right: 3vw;
        height: 3.5vw;
        width: 3.5vw;
    }

    .indicator2::after {
        height: 5vw;
        width: 5vw;
        right: 8vw;
        bottom: -1vw;
    }

    .indicator3 {
        width: 2vw;
        height: 2vw;
    }

    .indicator3::before,
    .indicator3::after {
        height: 3.2vw;
        width: 3vw;
        bottom: -4vw;
        right: 2vw;
    }

    .indicator3::after {
        height: 5vw;
        width: 5vw;
        right: 5vw;
        bottom: -9vw;
    }

    /*  */
    .home-search-container .banner-girl img {
        width: 35vw;
        object-fit: contain;
        height: 45vw;
    }

    .banner-girl {
        display: flex;
        width: 35%;
        justify-content: flex-start;
        overflow: hidden;
        margin-top: -9vh;
        margin-left: -16vh;
    }

    .carousel-img-container {
        max-width: 20vw;
    }

    .carousel-img-container:hover {
        max-width: 20vw;
    }

    .certificate-container-text {
        font-size: 0.6em
    }

    .carousel-educator-content {
        margin-top: -4px;
        padding-top: 15px;
        width: 17.6vw;
        min-height: 18vh;
    }

    .carousel-teacher-name {
        font-size: 0.95em;
        margin-left: -5px;
    }

    .carousel-school-text {
        font-size: 0.7em;
    }

    .carousel-experiance-text {
        font-size: 0.65em;
    }

    .educator-qua-container {
        width: 18vw;
    }

    .educator-content {
        min-width: 17vw;
    }

    .educator-qua-text {
        font-size: 0.6em;
    }

    .educator-qua-value-text {
        font-size: 0.6em;
    }

    .visit-btn {
        width: 9vw;
    }

    .visit-btn a {
        padding: 3px 10px;
        font-size: 10px;
    }

    .blog-content {
        overflow-x: auto;
        overflow-y: hidden;
    }

    .blog-column-50 {
        width: 50%;
        margin-right: 10px;
    }

    .blog-visibtn-content {
        width: 45vw;
    }

    .blog-column-60-child {
        margin-right: 10px;
    }

    .category-card {
        margin-right: 10px;
    }

    /* contact */


    .category-img-container {
        width: 14vw;
    }

    .category-content {
        width: 14vw;
    }
   
}



@media only screen and (max-width:800px) {

    .hide-cross-div {
        display: none;
    }

    /*  .home-search-div {
        width: 49vw;
    } */
}

@media only screen and (max-width: 800px) and (min-width:600px) {

    .home-search-div-input2,
    .home-search-div-input3 {
        flex: none;
        margin-bottom: 10px;
    }

    .category-img-container {
        width: 18vw;
    }

    .category-content {
        width: 18vw;
    }

    .category-text {
        font-size: 12px;
    }


    h4 {
        font-size: 19px;
        line-height: 28px;
    }

    .main-search-div div {
        width: 100%;
        margin-left: -2%;
    }

    .home-search-div {
        flex-direction: column;
        flex: 1,
    }

    .home-search-div .home-search-input-div {
        margin-bottom: 0px;
    }
    .home-search-div .no-margin-right {
        flex: 1;
    }

    .home-search-div p {
        padding-left: 0px;
        margin-left: -8px;
        margin-top: 12px;
        margin-bottom: 0px;
    }

    .home-section-header {
        margin-bottom: 6px;
    }

    .whyus-idiv {
        padding: 0 30px;
    }

    .signin-div,
    .feedback-div {
        width: 90%;
        max-width: 450px;
        min-width: 100px;
        position: relative;
    }

    .signin-div .signin-left-div {
        display: none;
    }

    .signin-div .signin-right-div {
        padding: 15px 15px;
    }

    .signin-right-div .close img {
        margin-right: 0;
    }

    .listing-search-div .home-search-input-div {
        width: 100%;
        padding: 16px;
    }

    .list-header-div {
        flex-direction: column;
        height: auto;
        padding: 20px 0;
        width: 100%;
    }


    .listing-content {
        flex-direction: column;
    }

    .listing-content .listing-filters {
        margin-top: 10px;
    }

    .listing-content .listing-filters .listing-filter-div {
        display: none;
    }

    .listing-filter-div .filter-box .full {
        width: auto;
    }

    .listing-content .listing-content-div {
        margin-left: 0;
    }

    .listing-card-div .card .info-div {
        flex-direction: column;
    }

    .listing-card-div .card .info-div .info-content .info-table .info-tr h4 {
        font-size: 18px;
    }

    .listing-card-div .card .info-div .info-content .title {
        flex-direction: column;
    }

    .listing-card-div .card .info-div .edu-img {
        margin: 15px 15px 0 15px;
        display: flex;
    }

    .listing-card-div .card .info-div .info-content .view-share div.view {
        margin: 0 30px 0 0px;
    }

    .listing-card-div .card .info-div .edu-img>div {
        width: 100%;
        height: 200px;
    }

    .location-div .scomplete {
        flex-direction: column;
        align-items: flex-start;
    }

    .location-div .search-parent {
        width: 100%;
        margin: 10px 0;
    }

    .location-div .detect-location {
        margin-bottom: 10px;
    }

    .location-div-parent {
        width: 90%;
        max-width: 90%;
    }

    .details-main-content {
        flex-direction: column;
    }

    .details-main-content .detail-content-div {
        width: 100%;
    }

    .details-main-content .details-sidebar {
        flex-direction: row;
        display: flex;
        width: 100%;
    }

    .details-sidebar div {
        flex: 1;
        margin-right: 5px;
    }

    .details-experience div.right-div {
        margin-right: 0;
    }

    .details-experience .left-div,
    .details-experience .middle-div {
        margin-right: 10px;
    }

    .details-title-section .title-main h2 {
        font-size: 22px;
        text-align: center;
    }

    .detail-info-table .detail-div div.separator {
        display: none;
    }

    .feedback-div .feedback-inner-div .review-main {
        flex-direction: column;
    }

    .feedback-div .feedback-inner-div .left-div {
        border-right: 0px solid rgba(0, 0, 0, 0.18);
        padding: 0;
    }

    .feedback-div .feedback-inner-div .right-div {
        padding: 20px 0;
    }

    /*  */
    /* banner page */
    .home-search-container {
        margin-top: 64px;
        flex-wrap: wrap;
        align-items: center;
    }

    .hide-cross-div {
        display: none;
    }

    .home-banner-div {
        height: auto;
    }

    .home-header-menu {
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        margin-top: 0px;
        box-sizing: border-box;
        /* display: flex; */
        /* flex-direction: row;
        justify-content: center;
        align-items: center; */
    }

    .header-menu {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .header-menu img {
        width: 2.4vh;
        height: 2.4vh;
    }

    .header-menu h2 {
        text-align: center;
    }

    .contact-div {
        flex-direction: column;
        height: auto;
        width: fit-content;
    }

    .contact-container .form-container {
        padding: 0px 0px;
        background-color: #FFF;
    }

    .contact-div .contact-form-div {
        width: 100%;
    }

    .form-container {
        width: 100%;
    }

    .form-container .send-div {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

    }

    .form-group {
        width: 100%;
    }

    .form-container .send-div button {
        width: 70%;
    }

    .contact-div .contact-mail-div {
        width: fit-content;
        height: fit-content;

    }

    .contact-mail-div {
        margin-top: 20px;
        width: 50%;
        height: -moz-fit-content;
    }



    .contact-mail-card {
        width: 42%;
        display: flex;
        word-break: break-word;
    }

    .contact-mail-card img {
        width: 41.28px;
        height: 40px;
    }

    .contact-mail-header .contact-title-content {
        width: 100%
    }

    .contact-mail-header .contact-title-content h5 {

        word-break: break-word;
    }

    /* category page */
    .category-container {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }

    .column-60 {
        width: 93%;
    }

    .column-60 .category-card h3 {
        font-size: 2.2vw;
    }

    .column-60 .category-card h6 {
        font-size: 1.8vw;
    }

    .column-40 {
        margin-top: 20px;
        width: 100%;
    }

    .column-40 .explore {
        padding-left: 0px;
        font-size: 2.0vw;
    }

    .column-40 .category-feature-card h3 {
        font-size: 3.2vw;
    }

    .column-40 .category-feature-card {
        width: 100%;

        margin-right: 0px;
        padding-left: 0px;
    }

    .category-feature-card-item p {
        font-size: 2.1vw;
    }

    /*  */
    .blog-content {
        flex-direction: column;
        height: fit-content;
        overflow-y: hidden;
    }

    .blog-column-50 {
        width: 100%;
    }

    .blog-column-60-content {
        width: 100%;
        margin-top: 10px;
        min-width: 800px;
    }

    .blog-visibtn-content {

        width: 100%;
    }

    .blog-column-60 {
        margin-top: 2vh;
        width: 100%;
        height: 45vh;
        overflow-x: auto;
        overflow-y: hidden;
    }

    .blog-child-img {
        width: 100%;
    }

    .blog-column-60-child {
        width: 49%;
    }

    .blog-child-overlay-content {
        width: 100%;
    }

    /* testimonial */
    .figure-row-div2 {
        height: 6vh;
    }

    .figure-row-div {
        height: 12vh;
    }

    .img-fluid0:hover {
        box-shadow: 0 0 0 4px #4274B0;
    }

    .img-fluid1:hover {
        box-shadow: 0 0 0 5px #4274B0;
    }

    .img-fluid2:hover {
        box-shadow: 0 0 0 4px #4274B0;
    }

    .figure-container {
        display: none;
    }

    .indicator {
        display: none;
    }

    .testimonial-slider {
        width: 90%;
        height: 32vh;
    }

    .testimonial-btn {
        height: 5vh;
        width: 5vh
    }

    .left-right-icon {
        height: 1vh;
        width: 0.7vh;
    }


    .slick-dots {
        left: -5vh;
        /* background-color: grey; */
    }

    /*  */

    .carousel-img-container {
        max-width: 25vw;

    }

    .carousel-img-container:hover {
        max-width: 25vw;
    }

    .carousel-school-text {
        font-size: 0.9;
    }

    .certificate-container-text {
        font-size: 0.7em
    }

    /*  */
    .testimonial-slider {
        margin-left: 0px;
    }

    .testimonial-title {
        font-size: 1.1em;
    }

    .testimonial-card {
        height: 35vw;
    }

    .testimonial-card-text {
        font-size: 0.7em;
    }

    .testimonial-card-name {
        z-index: 10;
        font-size: 1.5vh;
        bottom: 11vh;
    }

    .slick-dots {
        left: 50%;
        transform: translateX(-50%);
        top: 35vh;
    }

    .slick-dots.slick-thumb {
        flex-direction: row;
        justify-content: center;
        left: 28vh;
        z-index: 1;
        top: 23vh
    }

    .slick-dots.slick-thumb li.slick-active {
        width: 5.5vh;
        height: 0.5vh;
    }

    .slick-dots.slick-thumb li {
        width: 4vh;
        height: 0.5vh;
    }

    .header-menu h4 {
        font-size: 0.55em;
    }

    .home-banner-header {
        font-size: 1.5em;
        margin-top: 30px;

        margin-bottom: 0px;
    }

    .home-banner-text {
        /* margin-top: 17vw; */
        font-size: 0.8em;
    }

    .home-banner-btn {
        margin-left: 30px;
        bottom: 0px;
    }

    .home-banner-btn p a {
        font-size: 0.9em;
    }


    .home-search-container-inner h1 {
        font-size: 1.8em;
    }

    .home-logo-content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        overflow: hidden;
        margin-bottom: 0px;
    }

    .home-logo-content h2 {
        font-size: 1.3em;
    }

    .home-search-container h1 {
        padding-top: 0px;
        font-size: 2em;
    }

    .home-logo-content .banner-text {
        font-size: 1em;
    }

    .home-search-div-input1 {
        display: flex;
        width: 100%;
        height: 10vh;
        flex-direction: column;
        align-self: flex-start;
    }

    .home-search-div-input2 {
        display: flex;
        width: 100%;
        /*    height: 10vh; */
        flex-direction: column;
        align-self: flex-start;
    }

    .home-search-div-input3 {
        display: flex;
        width: 100%;
        /*     height: 10vh; */
        flex-direction: column;
        align-self: flex-start;
    }

    .home-search-input {
        /*       height: 5vh; */
        height: 30px;
        display: flex;
        align-items: center;
    }

    .home-search-div p {
        padding-left: 0px;
        margin-left: 10px;
    }

    .banner-girl {
        display: flex;
        width: 100%;
        justify-content: center;
        overflow: hidden;
        margin-top: 0vh;
        margin-left: 0vh;
    }

    .home-search-container-inner {
        width: 100%;
    }

    .carousel-img-container {
        max-width: 25vw;
    }

    .carousel-img-container:hover {
        max-width: 26vw;
    }

    .certificate-container-text {
        font-size: 0.6em
    }

    .carousel-educator-content {
        margin-top: -4px;
        padding-top: 15px;
        width: 20vw;
        min-height: 27vh;
    }

    .testimonial-container {
        box-sizing: border-box;
    }

    .carousel-teacher-name {
        font-size: 0.95em;
        margin-left: -5px;
    }

    .carousel-school-text {
        font-size: 0.7em;
    }

    .carousel-experiance-text {
        font-size: 0.65em;
    }

    .educator-qua-container {
        width: 21vw;
    }

    .educator-content {
        min-width: 20vw;
    }

    .educator-qua-text {
        font-size: 0.6em;
    }

    .educator-qua-value-text {
        font-size: 0.6em;
    }

    .visit-btn {
        width: 13vw;
    }

    .visit-btn a {
        padding: 3px 10px;
        font-size: 10px;
    }
    .location-div {
        padding: 12px;
        max-height: none;
        height: 100vh;
    }

    .signin-div.flex-row {
        flex-wrap: wrap;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        flex-direction: column-reverse;
    }

    .signin-div.flex-row {
        padding-top: 50px;
    }

    .flex-row.scomplete {
        flex-wrap: wrap;
    }

    .flex-row.scomplete>div {
        width: 100%;
        margin-bottom: 8px;
    }

    .modal-container {
        width: 100vw;

        height: 100vh;
        box-sizing: border-box;
    }

    .signin-right-div>div {
        padding: 0px 12px;
    }

    .signin-div.flex-row>div {
        width: 100%;
        flex: none;
        box-sizing: border-box;
        padding: 0px;
    }

    .signin-right-div .signin p.loginp,
    .signin-right-div .signin h4.inst {
        margin: 8px auto 0px;
    }

    .location-div .edu-level div {
        padding: 12px;
    }

    .location-div .standard-level div {
        width: calc(25% - 48px);
        text-align: center;
    }

    .w-38 {
        width: 100%;
        display: block;
    }

    .w-38 input {
        width: 100%;
    }
    .modal-container {
        width: 100vw;

        height: 100vh;
        box-sizing: border-box;
    }
    .signin-div {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
    }
    .location-div {
        padding: 12px;
        max-height: none;
        height: 100vh;
    }
    .location-div .close-icon {
        margin-top: 20px;
        margin-right: 0px;
    }
    .footer .sections .inner-sections{
        display: block;
    }
    .footer .sections .inner-sections div{
        width: 100%;
    }
}

@media only screen and (max-width:600px) {

    .home-search-div {
        z-index: 0;
    }

    .grad-img {
        height: 85vw;
        width: 85vw;
        right: 20%;
    }

    .signin-arrow {
        height: 40vw;
        top: 3%;
        left: 60%;
    }
}

.footer .sections:first-child {
    margin-right: 24px;
}

@media only screen and (max-width: 600px) and (min-width:400px) {
    body {
        overflow-x: hidden;
    }

    h4 {
        font-size: 18px;
        line-height: 26px;
    }

    .home-logo {
        width: 100px;
        height: 30px;
    }

    .home-padded-div-two {
        padding: 20px 10px 30px 10px
    }

    .home-categories .category-section {
        width: calc(100% - 20px);
        margin-bottom: 20px;
        height: 250px;
    }

    .home-categories .category-section div {
        font-size: 24px;
    }

    .home-institute-card {
        margin: 10px;
        margin-bottom: 30px;
    }

    .home-main-heading {
        font-size: 20px;
        letter-spacing: -1px;
    }

    .home-view-more {
        padding: 7px;
        font-size: 14px;
    }

    .home-blog .blog-section,
    .home-blog .blog-section:nth-child(2) {
        width: calc(100% - 20px);
        display: none;
    }

    .home-blog .blog-section:first-child {
        display: block;
    }

    .home-skill-list-div {
        width: calc(47% - 16px);
        padding: 12px 1.5% 16px 1.5%;
    }

    .home-skill-list-div h4 {
        font-size: 16px;
    }

    .home-skill-list-div .imgdiv {
        height: 34px;
    }

    .home-skill-list-div .imgdiv img {
        height: 34px;
    }

    
    .grad-container {
        top:20%
    }

    .whyus-idiv {
        padding: 0 0px;
    }

    .whyus-div .react-multiple-carousel__arrow::before,
    .whyus-div .react-multiple-carousel__arrow::after {
        font-size: 28px;
        font-weight: bold;
    }

    .footer .sections h3 {
        font-size: 22px;
    }

    .footer .sections .app-div {
        margin-bottom: 50px;
        margin-top: 0px;
    }

    .footer .sections {
        margin-right: 0;
    }

    .listing-header-div .login-button {
        display: block;
        width: 94%;
        margin-top: 10px;
    }

    .listing-headlist {
        margin-left: 30px;
    }

    .listing-card-heading {
        flex-direction: column;
        padding: 10px 10px;
        border-radius: 4px;
        align-items: flex-start;
        height: auto;
    }

    .listing-card-heading .lheading {
        margin-bottom: 10px;
    }

    .listing-card-heading .lheading p {
        margin: 0 10px;
    }

    .listing-card-heading .lheading h2 {
        font-size: 22px;
    }

    .listing-card-heading .lheading h3 {
        font-size: 18px;
    }

    .listing-content {
        padding: 5px;
    }

    .listing-heading-title {
        padding: 20px 0 0 20px;
    }

    .listing-card-div .card .info-div .info-content .view-share div.share {
        margin: 0;
    }

    .listing-card-div .card .info-div .info-content .view-share div {
        margin: 10px 20px 0 0;
    }

    .listing-card-div .card .info-div .info-content .info-table {
        flex-direction: column;
    }

    .listing-card-div .card .info-div .info-content .info-table .info-tr {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .listing-card-div .card .footer {
        flex-direction: column;
        height: auto;
        padding: 10px;
    }

    .listing-card-div .card .footer .fee-div {
        margin-bottom: 10px;
    }

    .listing-card-div .card .card-footer .fee-div {
        font-size: 12px;
    }

    .listing-card-div .card .card-footer .enquire-div .compare {
        margin-right: 0;
    }

    .footer .sections .logo-div {
        width: 100%;
    }

    .location-div {
        padding: 20px 14px;
    }



    /* ------------- details ---------------*/
    .detail-container {
        padding: 10
    }

    .detail-container h2 {
        font-size: 20px;
    }

    .details-experience div.right-div {
        margin-right: 0;
    }

    .details-experience div.right-div div {
        flex-direction: column;
    }

    .details-experience .left-div h4 {
        width: 60px;
        font-size: 11px;
    }

    .details-dmsg,
    .details-qualification>div div.detdiv {
        flex-direction: column;
    }

    .details-events-sec>div {
        width: calc(100% - 0px);
    }

    .details-events-sec>div {
        margin-right: 0;
    }

    .details-events>div,
    .details-admission>div,
    .details-management>div>div,
    .details-alumni>div>div,
    .details-scholar>div {
        flex-direction: column;
        align-items: flex-start;
    }

    .details-events>div div.leftdiv {
        width: calc(100% - 20px);
        align-items: flex-start;
        padding-left: 20px;
    }

    .details-admission>div div.content {
        text-align: left;
        padding-left: 0px;
    }

    .details-management a {
        margin-bottom: 10px;
        display: inline-block;
    }

    .details-management img.comma {
        margin: 10px 10px 0 0px;
        width: 20px
    }

    .details-alumni p.descr,
    .details-alumni .alumni-links {
        margin-left: 0;
    }

    .details-facilities div {
        width: calc(50% - 5px);
        margin: 0;
        margin-left: 10px;
    }

    .details-facilities div:nth-child(5n+1) {
        margin-left: 10px;
    }

    .details-facilities div:nth-child(2n+1) {
        margin-left: 0px;
    }

    .details-standard .stan-heading {
        width: 100%;
    }

    .details-standard,
    .details-standard .tinfo,
    .details-teacher,
    .details-teacher .tinfo,
    .details-school-img {
        flex-direction: column;
        align-items: flex-start;
    }

    .details-standard .tinfo>div {
        margin-bottom: 5px;
    }

    .details-teacher .imgavatar {
        height: 100px;
        margin: 10px;
        border-radius: 50px;
    }

    .details-teacher .tinfo>div {
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .detail-container .fee-structure {
        width: 100%;
        overflow-x: auto;
        ;
    }

    .details-school-img img {
        width: 100%;
        margin: 0 0 10px 0;
        flex-wrap: wrap;
    }

    .details-school-det div,
    .details-school-det div:nth-child(3n) {
        width: 100%;
        margin: 0 0 10px 0;
    }

    .detail-info-table .detail-div,
    .details-title-section {
        flex-direction: column;
        align-items: flex-start;
    }

    .detail-info-table .detail-div,
    .details-title-section .claim {
        width: 100%;
    }

    .detail-info-table .detail-div div {
        margin-bottom: 5px;
    }

    .detail-info-table .detail-div {
        margin: 0;
    }

    .detail-info-table .detail-div div.info-tr {
        margin-left: 20px;
    }

    .details-main-content .details-sidebar>div.latest-news {
        display: none;
    }

    .detail-slider-info>div>div {
        flex-direction: column;
        align-items: flex-end;
    }

    .detail-slider .detail-slider-info div a {
        margin-bottom: 4px;
        display: inline-block;
    }

    .details-title-section .title-main,
    .details-title-section .title-compare {
        margin-top: 20px;
        width: 100%;
        align-items: flex-start;
    }

    .details-title-section .title-compare>div,
    .details-title-section .title-main h2 {
        margin: 0 0 10px 0;
        justify-content: flex-start;
    }

    /* ------------------- Apply Div ------------------- */
    .apply-div {
        padding: 10px 20px;
        width: calc(100% - 40px);
    }

    .apply-div .apply-school-div h1 {
        font-size: 26px;
        padding: 18px;
    }

    .apply-div .apply-header div.inner div div.step {
        width: 30px;
        height: 30px;
        border-radius: 15px;
        font-size: 12px;
    }

    .apply-div .apply-header div.inner h4 {
        font-size: 11px;
    }

    .form-group {
        flex-direction: column;
    }

    .form-container input,
    .form-container select {
        margin-right: 0px;
    }

    .form-container .form-upload-group {
        padding: 0 6px 0 10px;
    }

    .form-container .form-upload-group h4 {
        font-size: 14px;
        letter-spacing: -1px;
    }

    .form-container .form-upload-group>div {
        padding: 6px 6px;
        font-size: 14px;
        letter-spacing: -1px;
    }

    .form-container .submit-div button {
        padding: 12px 20px;
    }

    .details-reviews .rating-div,
    .details-reviews .review-div .review {
        flex-direction: column;
    }

    .details-reviews .rating-div .left-div {
        width: 100%;
        border-right: 0px solid rgba(112, 112, 112, 0);
    }

    .details-reviews .rating-div .right-div {
        width: 100%;
        padding: 20px 0;
    }

    .details-reviews .review-div .review-tags>div {
        font-size: 13px;
        margin: 0 6px 6px 0;
    }

    .details-reviews .review-div h4.tagline {
        font-size: 18px;
    }

    .details-reviews .review-div .heading-div {
        flex-direction: column;
        align-items: flex-start;
    }

    .details-reviews .review-div .heading-div .share {
        margin: 10px 0;
    }

    .details-reviews .review-div .helpful-div p {
        margin-right: 5px;
    }

    /*  */
    /* banner page */
    .home-search-container {
        margin-top: 17vw;
        flex-wrap: wrap;
        align-items: center;
    }

    .home-banner-div {
        height: auto;
    }

    .home-header-menu {
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        margin-top: 0px;
        box-sizing: border-box;
        /* display: flex; */
        /* flex-direction: row;
        justify-content: center;
        align-items: center; */
    }

    .testimonial-container {
        box-sizing: border-box;
    }

    .header-menu {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .header-menu img {
        width: 2.0vh;
        height: 2.0vh;
    }

    .header-menu h2 {
        text-align: center;
    }

    .contact-div {
        flex-direction: column;
        height: auto;
        width: fit-content;
    }

    .contact-container .form-container {
        padding: 0px 0px;
        background-color: #FFF;
        width: 100%;
    }

    .contact-container {
        width: auto;
        padding: 30px;
    }

    .contact-div .contact-form-div {
        width: 100%;

    }

    .form-container {
        width: 100%;
    }

    .form-container .send-div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .form-group {
        width: 100%;
    }

    .form-container .send-div button {
        width: 80%;
    }

    .contact-div .contact-mail-container .row-spaceBetween {
        flex-direction: column;
        width: 100%;
        height: 23vh;
    }

    .contact-div .contact-mail-div {
        width: fit-content;
        height: fit-content;

    }

    .contact-mail-div {
        margin-top: 20px;
        width: 50%;
        height: -moz-fit-content;
    }

    .contact-mail-div .contact-city-content {
        height: 40vh;
    }

    #cityLabel {
        margin-bottom: 20px;
    }

    .contact-mail-div .contact-city-content .city-card {

        overflow: hidden;
        overflow-y: auto;
        word-break: break-word;
    }

    .contact-mail-div .contact-city-content .city-card h4 {
        font-size: 14px;
    }

    .contact-mail-div .contact-city-content .city-card h5 {
        font-size: 12px;
    }

    .contact-mail-div .contact-city-content .city-card p {
        font-size: 10px;
    }

    .city-card img {
        width: 40px;
        height: 40px;
    }

    .contact-mail-card h4 {
        font-size: 1.5em;
    }

    .contact-mail-card p {
        font-size: 0.8em;
    }

    .contact-mail-card {
        width: -webkit-fill-available;
        display: flex;
        word-break: break-word;
    }

    .contact-mail-card img {
        width: 41.28px;
        height: 40px;
    }

    .contact-mail-header .contact-title-content {
        width: 100%
    }

    .contact-mail-header .contact-title-content h5 {

        word-break: break-word;
    }

    /* category page */
    .category-container {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }

    .column-60 {
        width: 93%;
    }

    .column-60 .category-card h3 {
        font-size: 2.2vw;
    }

    .column-60 .category-card h6 {
        font-size: 1.8vw;
    }

    .column-40 {
        margin-top: 20px;
        width: 100%;
    }

    .column-40 .explore {
        padding-left: 0px;
        font-size: 2.0vw;
    }

    .column-40 .category-feature-card h3 {
        font-size: 3.2vw;
    }

    .column-40 .category-feature-card {
        width: 100%;

        margin-right: 0px;
        padding-left: 0px;
    }

    .category-feature-card-item p {
        font-size: 2.1vw;
    }

    /*  */
    .blog-content {
        flex-direction: column;
        height: fit-content;
        overflow-y: hidden;
    }

    .blog-column-50 {
        width: 100%;
    }

    .blog-column-60-content {
        width: 100%;
        margin-top: 10px;
        min-width: 800px;
    }

    .blog-visibtn-content {

        width: 100%;
    }

    .blog-column-60 {
        margin-top: 2vh;
        width: 100%;
        height: 45vh;
        overflow-x: auto;
        overflow-y: hidden;
    }

    .blog-child-img {
        width: 100%;
    }

    .blog-column-60-child {
        width: 49%;
    }

    .blog-child-overlay-content {
        width: 100%;
    }

    /* testimonial */
    .figure-row-div2 {
        height: 6vh;
    }

    .figure-row-div {
        height: 12vh;
    }

    .img-fluid0:hover {
        box-shadow: 0 0 0 4px #4274B0;
    }

    .img-fluid1:hover {
        box-shadow: 0 0 0 5px #4274B0;
    }

    .img-fluid2:hover {
        box-shadow: 0 0 0 4px #4274B0;
    }

    .figure-container {
        display: none;
    }

    .indicator {
        display: none;
    }

    .testimonial-slider {
        width: 100%;
        height: 32vh;
    }

    .testimonial-btn {
        height: 5vh;
        width: 5vh
    }

    .left-right-icon {
        height: 1vh;
        width: 0.7vh;
    }


    /* .slick-dots {
        left: -5vh;
        background-color: grey;
    } */

    /*  */

    .carousel-img-container {
        max-width: 34vw;
    }

    .carousel-img-container:hover {
        max-width: 34vw;
    }

    .carousel-school-text {
        font-size: 0.8em;
    }

    .certificate-container-text {
        font-size: 0.6em
    }

    .header-menu h4 {
        font-size: 2vw;
    }

    .testimonial-slider {
        margin-left: 0px;
    }

    .testimonial-title {
        font-size: 1.1em;
    }

    .testimonial-card {
        height: 240px;
        padding-top: 0px;
    }

    .testimonial-card-text {
        font-size: 0.7em;
        line-height: 20px;
    }

    .testimonial-card-name {
        z-index: 10;
        font-size: 1.5vh;
        bottom: 2vh;
    }

    .slick-dots {
        left: 50%;
        transform: translateX(-50%);
        top: 25vh;
    }
    .testimonial-slider-content{
        margin-bottom: 12px;
    }

    .slick-dots.slick-thumb {
        flex-direction: row;
        justify-content: center;
        left: 23vh;
        z-index: 1;
        top: 25vh
    }

    .slick-dots.slick-thumb li.slick-active {
        width: 5.5vh;
        height: 0.5vh;
    }

    .slick-dots.slick-thumb li {
        width: 4vh;
        height: 0.5vh;
    }

    .home-banner-header {
        font-size: 1.5em;
        margin-top: 0px;
        line-height: 28px;
        padding: 28px 0px 0px;
        margin-bottom: 0px;
    }

    .home-banner-text {
        margin-top: 2vh;
        font-size: 0.8em;
    }

    .home-banner-btn {
        margin-left: 30px;
        bottom: 14vh;
    }

    .home-banner-btn p a {
        font-size: 0.9em;
    }


    .home-search-container-inner h1 {
        font-size: 1.8em;
    }

    .home-logo-content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        overflow: hidden;
        margin-bottom: 0px;
    }

    .home-logo-content h2 {
        font-size: 1.3em;
    }

    .home-search-container h1 {
        padding-top: 0px;
        font-size: 2em;
    }

    .home-logo-content .banner-text {
        font-size: 1em;
    }

    .home-search-div {
        width: calc(100% - 16px);
        /*   height: 30vh; */
        margin-top: 2vh;
        margin-bottom: 0px;
        flex-direction: column;
    }

    .home-search-div-input1 {
        display: flex;
        width: calc(100%);
        /*   height: 10vh; */
        flex-direction: column;
        align-self: flex-start;
    }

    .home-search-div-input2 {
        display: flex;
        width: calc(100% );
        /*   height: 10vh; */
        flex-direction: column;
        align-self: flex-start;
    }

    .home-search-div-input3 {
        display: flex;
        width: calc(100%);
        /*    height: 10vh; */
        flex-direction: column;
        align-self: flex-start;
    }

    .home-search-input {
        /*   height: 5vh; */
        height: 30px;
        display: flex;
        align-items: center;
    }

    .home-search-div p {
        padding-left: 0px;
        margin-left: 10px;
        margin-bottom: 0px;
        margin-top: 8px;
    }

    .banner-girl {
        display: flex;
        width: 100%;
        justify-content: center;
        overflow: hidden;
        margin-top: 0vh;
        margin-left: 0vh;
    }

    .home-search-container-inner {
        width: 100%;
        align-items: center;
        padding: 0px 8px;
    }

    .carousel-img-container {
        max-width: 32vw;
    }

    .carousel-img-container:hover {
        max-width: 35vw;
    }

    .certificate-container-text {
        font-size: 0.6em
    }

    .carousel-educator-content {
        margin-top: -4px;
        padding-top: 15px;
        width: 28.8vw;
    }

    .carousel-teacher-name {
        font-size: 0.95em;
        margin-left: -5px;
    }

    .carousel-school-text {
        font-size: 0.7em;
    }

    .carousel-experiance-text {
        font-size: 0.65em;
    }

    .educator-qua-container {
        width: 31vw;
    }

    .educator-content {
        min-width: 29vw;
    }

    .educator-qua-text {
        font-size: 0.6em;
    }

    .educator-qua-value-text {
        font-size: 0.6em;
    }

    .visit-btn {
        width: 20vw;
    }

    .visit-btn a {
        padding: 3px 10px;
        font-size: 10px;
    }

    .category-img-container {
        width: 21vw;
    }

    .category-content {
        width: 21vw;
    }

    .category-text {
        font-size: 12px;
    }

    .footer .sections .app-div img {
        max-width: 100%;
    }

    .footer .sections .inner-sections div {
        width: 45%;
        margin-left: 5%;
    }
    .location-div {
        padding: 12px;
        max-height: none;
        height: 100vh;
    }

    .signin-div.flex-row {
        flex-wrap: wrap;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        flex-direction: column-reverse;
    }

    .signin-div.flex-row {
        padding-top: 50px;
    }

    .flex-row.scomplete {
        flex-wrap: wrap;
    }

    .flex-row.scomplete>div {
        width: 100%;
        margin-bottom: 8px;
    }

    .modal-container {
        width: 100vw;

        height: 100vh;
        box-sizing: border-box;
    }

    .signin-right-div>div {
        padding: 0px 12px;
    }

    .signin-div.flex-row>div {
        width: 100%;
        flex: none;
        box-sizing: border-box;
        padding: 0px;
    }

    .signin-right-div .signin p.loginp,
    .signin-right-div .signin h4.inst {
        margin: 8px auto 0px;
    }

    .location-div .edu-level div {
        padding: 12px;
    }

    .location-div .standard-level div {
        width: calc(25% - 48px);
        text-align: center;
    }

    .w-38 {
        width: 100%;
        display: block;
    }

    .w-38 input {
        width: 100%;
    }
    .modal-container {
        width: 100vw;

        height: 100vh;
        box-sizing: border-box;
    }
    .signin-div {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
    }
    .location-div {
        padding: 12px;
        max-height: none;
        height: 100vh;
    }
    .location-div .close-icon {
        margin-top: 20px;
        margin-right: 0px;
    }
    .home-logo-spaceBetween {
        justify-content: space-between;
        margin-bottom: 8px;
        flex-wrap: wrap;
    }
    .svg-y {
        margin: 0px;
    }
    .home-banner-container{
        height: 30vh;
    }
    .home-login-button-div {
        height: 4vh;
        width: 11vh;
        font-size: 0.9em;
        margin-left: auto;
        margin-right: 8px;
    }
}

@media only screen and (max-width: 400px) {
    .footer .sections {
        margin: 0px;
    }

    .home-logo-spaceBetween {
        justify-content: space-between;
        margin-bottom: 8px;
        flex-wrap: wrap;
    }

    .svg-y {
        margin: 0px;
    }

    .blog-column-50 {
        width: 100%;
        height: 166px;
    }

    .blog-news {
        font-size: 1.1em;
    }

    .blog-child-visibtn-content {
        width: 100%;
    }

    .blog-visibtn-content {
        width: 100%;
        height: 166px;
    }

    .blog-column-60 {
        justify-content: space-evenly;
        width: 100%;
        height: auto;
        overflow-x: auto;
        overflow-y: hidden;
        position: initial;

    }

    .blog-column-60-child {
        width: 100%;
        margin: 0px;
        position: initial;
        margin-top: 2vh;

    }

    .blog-child-img {
        width: 100%;
    }

    .blog-content {
        flex-direction: column;
        height: fit-content;
        overflow-y: hidden;
    }

    .blog-column-60-content {
        width: 100%;
        flex-direction: column;
        position: relative;
    }

    .visible-btn {
        width: 20vw;
    }

    .blog-child-overlay-content {
        width: 100%;
    }

    /* testimonial */
    .figure-row-div2 {
        height: 8vh;
    }

    .figure-row-div {
        height: 6vh;
    }

    .img-fluid0:hover {
        box-shadow: 0 0 0 2px #4274B0;
    }

    .img-fluid1:hover {
        box-shadow: 0 0 0 3px #4274B0;
    }

    .img-fluid2:hover {
        box-shadow: 0 0 0 2px #4274B0;
    }

    .testimonial-container {
        height: 35vh;
        width: auto;
        padding: 0px;
    }

    .testimonial-slider {
        width: 90%;
        height: 30vh;
        overflow: visible;
        /* background-color: yellowgreen; */
    }

    .testimonial-slider-content {
        padding-top: 15px;
        margin-bottom: 0px;
    }

    .testimonial-btn-container {
        width: 15vh;
    }

    .testimonial-btn {
        height: 4vh;
        width: 4vh
    }

    .testimonial-card {
        min-height: 22vh;
        overflow-y: auto;
    }


    .testimonial-card-text {
        font-size: 12px;
        line-height: 14px;
    }

    .testimonial-card-name {
        z-index: 10;
        font-size: 1vh;
        text-align: start;
        position: absolute;
        bottom: 1vh;
    }

    .left-right-icon {
        height: 1vh;
        width: 0.7vh;
    }

    .testimonial-slider .slick-slider .slick-dots.slick-thum .slick-dots {
        left: 15vh;
        top: 16vh;
    }

    .home-banner-card {
        height: 25vh;
    }

    .home-banner-header {
        font-size: 1.1em;
        margin-top: 16px;
        margin-bottom: 0px;
        line-height: 24px;
    }

    .home-banner-text {
        line-height: 16px;
        font-size: 0.6em;

    }

    .home-banner-btn {
        margin-left: 30px;
        bottom: 55px;
    }

    .home-banner-btn p a {
        font-size: 0.7em;

    }

    .grad-container {
        top:20%
    }

    .slick-dots {
        left: 19vh;
        top: 20vh;
    }

    .slick-dots.slick-thumb {
        flex-direction: row;
        justify-content: center;
        /* background-color: yellow; */
        left: 35vw;
        z-index: 1;
        top: 52vw
    }

    .slick-dots.slick-thumb li.slick-active {
        width: 5.5vh;
        height: 0.5vh;
    }

    .slick-dots.slick-thumb li {
        width: 4vh;
        height: 0.5vh;
    }

    .home-banner-div {
        height: auto;
    }

    .home-header-menu {
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        margin-top: 0px;
    }

    .header-menu img {
        width: 3vh;
        height: 3vh;
        /* margin-bottom: 10px; */
        overflow: hidden;
    }

    .header-menu h4 {
        font-size: 1.1vh;
    }

    .home-login-button-div {
        height: 4vh;
        width: 11vh;
        font-size: 0.9em;
        margin-left: auto;
        margin-right: 8px;
    }

    .home-search-container-inner h1 {
        font-size: 1.8em;
    }

    .home-logo-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        margin-bottom: 0px;
    }

    .home-logo-content h2 {
        font-size: 1.3em;
        font-size: 19px;
        line-height: 29px;
    }

    .home-search-container h1 {
        padding-top: 90px;
        font-size: 19px;
        line-height: 21px;
    }

    .home-logo-content .banner-text {
        font-size: 15px;

        line-height: 18px;
    }

    .home-search-div {
        width: calc(100% - 16px);
        /*    height: 30vh; */
        margin-top: 2vh;
        margin-bottom: 0px;
        flex-direction: column;
    }

    /* .home-search-input-div {
        height: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background-color: #FFF;
        padding: 0px 10px;
        margin-left: 10px;
        border-radius: 3px;
        margin: 0px 10px;
    } */

    .home-search-div-input1 {
        display: flex;
        width: 100%;
        height: 10vh;
        flex-direction: column;
        align-self: flex-start;
    }

    .home-search-div-input2 {
        display: flex;
        width: 100%;
        /* height: 10vh; */
        flex-direction: column;
        align-self: flex-start;
    }

    .home-search-div-input3 {
        display: flex;
        width: 100%;
        /*     height: 10vh; */
        flex-direction: column;
        align-self: flex-start;
    }

    .home-search-input {
        /*     height: 5vh; */
        height: 30px;
        display: flex;
        align-items: center;
    }

    .home-search-div p {
        padding-left: 0px;
        margin-left: 10px;
        margin-bottom: 0px;
        margin-top: 8px;
    }

    .home-search-input-div {
        margin-top: 4px;
    }
   
    .banner-girl {
        display: flex;
        width: 100%;
        justify-content: center;
        overflow: hidden;
        margin-top: 0vh;
        margin-left: 0vh;
    }

    .home-search-container-inner {
        width: 100%;
        align-items: center;
        padding: 0px 8px;
    }

    .home-search-container .banner-girl img {
        display: flex;
        margin-top: 10px;
        width: calc(95% + 10px);
        height: calc(90% - 40px);
        object-fit: cover;
        background-size: cover;
    }

    .category-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }

    .category-class {
      /*   height: auto; */
    }

    .column-60 {
        height: 100%;
        width: 70%;
        min-height: 100px;
    }

    .column-60 .row-spaceBetween {
        height: 100%;
        /* width: 80%; */
        display: flex;
        overflow-x: auto;
        justify-content: space-between;
    }

    .category-card {
        flex-direction: column;
        align-items: flex-start;
        display: flex;
        padding: 10px;
        margin-right: 10px;
        border-radius: 4px;
        border: 1px solid #E0E0E0;
        transition: 1.0s;

        background-color: #FFFFFF;
        box-shadow: inset 0 0 0 0 #7bc6cc;
        width: 30%;
        max-height: 270px;
        min-height: 150px;
        min-width: 150px;
    }

    .column-60 .category-card h3 {
        font-size: 0.8em;
    }

    .category-card .card-item h6 {

        font-size: 0.8em;
        line-height: 24px;
        margin-top: 5px;
        padding: 4px 10px;
    }

    .column-40 {
        width: 64%;
        margin-top: 25px;
    }

    .column-40 .category-feature-card {
        width: 92%;
        height: 100%;
        min-width: 200px;
        margin-right: 15px;
        padding-left: 0px;
    }

    .category-feature-card-item p {
        font-size: 12px;
    }

    .column-40 .category-feature-card .explore {
        min-width: 130px;
        padding-left: 5px;
        padding-right: 5px;
        margin-top: 10px;
    }

    .testimonial-title {
        font-size: 1em;
    }

    .home-banner-container {
        width: 100%;
        height: 25vh;
        margin-top: 50px;
    }

    /*  */

    /* testimonial */
    .figure-row-div2 {
        height: 6vh;
    }

    .figure-row-div {
        height: 12vh;
    }

    .img-fluid0:hover {
        box-shadow: 0 0 0 4px #4274B0;
    }

    .img-fluid1:hover {
        box-shadow: 0 0 0 5px #4274B0;
    }

    .img-fluid2:hover {
        box-shadow: 0 0 0 4px #4274B0;
    }

    .figure-container {
        display: none;
    }

    .indicator {
        display: none;
    }

    .testimonial-slider {
        width: 90%;
        height: 32vh;
    }

    .testimonial-btn {
        height: 5vh;
        width: 5vh
    }

    .left-right-icon {
        height: 1vh;
        width: 0.7vh;
    }

    /*  */

    .carousel-img-container {
        max-width: 53vw;
    }

    .carousel-img-container:hover {
        max-width: 53vw;
    }

    .certificate-container-text {
        font-size: 0.6em
    }

    .carousel-educator-content {
        margin-top: -4px;
        padding-top: 15px;
        width: 45vw;
    }

    .carousel-teacher-name {
        font-size: 0.95em;
        margin-left: -5px;
    }

    .carousel-school-text {
        font-size: 0.7em;
    }

    .carousel-experiance-text {
        font-size: 0.65em;
    }

    .educator-qua-container {
        width: 47vw;
    }

    .educator-content {
        min-width: 44vw;
    }

    .educator-qua-text {
        font-size: 0.6em;
    }

    .educator-qua-value-text {
        font-size: 0.6em;
    }

    .visit-btn {
        width: 20vw;
    }

    .visit-btn a {
        padding: 3px 10px;
        font-size: 10px;
    }

    /* contact */
    .contact-div {
        flex-direction: column;
    }


    .form-container {
        padding: 0px;
        width: 100%;
    }

    .form-container .send-div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .form-container .send-div button {
        width: 70%;
        margin: 0px auto;
    }

    .contact-div .contact-mail-div {
        width: fit-content;
        height: fit-content;
    }

    .contact-div .contact-mail-container {
        padding: 12px;
    }

    .contact-div .contact-mail-container .row-spaceBetween {
        flex-direction: column;
        height: 23vh;
    }

    .contact-mail-card img {
        width: 30px;
        height: 30px;
    }

    .contact-mail-card h4 {
        font-size: 1em;
    }

    .contact-mail-card p {
        font-size: 0.7em;
    }

    #cityLabel {
        font-size: 1em;
        margin-bottom: 10px;
    }

    .contact-city-content .row-spaceBetween {
        flex-direction: column;
    }

    .contact-mail-div .contact-city-content {
        height: auto;
    }

    .contact-mail-div .contact-city-content .city-card {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-bottom: 10px;
        margin-bottom: 10px
    }

    .city-card-img-400px {
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        display: flex;
    }

    .category-img-container {
        width: 20vw;
    }

    .category-content {
        width: 20vw;
    }

    .category-text {
        font-size: 12px;
    }

    .signin-div {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
    }

    .location-div .close-icon {
        margin-top: 20px;
        margin-right: 0px;
    }

    .location-div p.conly {
        margin: 8px 0px;
    }

    .location-div {
        padding: 12px;
        max-height: none;
        height: 100vh;
    }

    .signin-div.flex-row {
        overflow: scroll;
        height: 100vh;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        flex-direction: column-reverse;
    }

    .signin-div.flex-row {
        padding-top: 50px;
    }

    .flex-row.scomplete {
        flex-wrap: wrap;
    }

    .flex-row.scomplete>div {
        width: 100%;
        margin-bottom: 8px;
    }

    .modal-container {
        width: 100vw;

        height: 100vh;
        box-sizing: border-box;
    }

    .signin-right-div>div {
        padding: 0px 12px;
    }

    .signin-div.flex-row>div {
        width: 100%;
        flex: none;
        box-sizing: border-box;
        padding: 0px;
    }

    .signin-right-div .signin p.loginp,
    .signin-right-div .signin h4.inst {
        margin: 8px auto 0px;
    }

    .location-div .edu-level div {
        padding: 12px;
        background: none;
    }

    .location-div .standard-level div {
        width: calc(25% - 48px);
        text-align: center;
    }

    .w-38 {
        width: 100%;
        display: block;
    }

    .w-38 input {
        width: 100%;
    }
}

/*  */
/* testimonial */
@keyframes blink {
    50% {
        opacity: 1;
    }
}

@keyframes pulse {
    50% {
        transform: scale(1.05);
    }
}

@keyframes open {
    from {
        height: 7vh;
    }

    to {
        height: 50vh;
    }
}


/*  */