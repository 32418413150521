.w-100p{
    width: 100% ;
}
.h-100p-imp{
    height: 100% !important;
}
.h-2em-imp{
    height:2em !important
}
.w-60p{
    width: 60%;
}
.w-70p{
    width: 70%;
}
.w-30p{
    width: 60%;
}