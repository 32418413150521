.signin-right-div {
    padding: 15px 30px;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
}

.signin-right-div .close {
    display: flex;
    justify-content: flex-end;
    height: 22px;
    top: 12px;
    padding: 0px;
    flex: 0;
    margin-right: 12px;
    background-color: #fff;
}

.signin-div .close img {
    width: 22px;
    height: 22px;
    cursor: pointer;
    margin-right: -13px;
}

.signin-right-div>div {
    flex: 1;
}

.signin-right-div>p {
    height: 24px;
    text-align: center;
    color: #283B4C;
}

.signin-right-div .signin {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.signin-right-div .signin h2 {
    font-size: 44px;
    font-weight: 500;
    color: #283B4C;
}

.signin-right-div .signin p.loginp {
    font-size: 16px;
    line-height: 24px;
    color: #283B4C;
    font-weight: 300;
    margin-bottom: 15%;
    margin-top: 10px;
}

.signin-right-div input[type="text"],
.signin-right-div input[type="tel"],
.signin-right-div input[type="number"],
.signin-right-div select.institiute {
    border: 0;
    border-bottom: 1px solid rgba(106, 106, 106, 0.26);
    box-sizing: border-box;
    filter: drop-shadow(0px 4px 2px rgba(79, 161, 233, 0.1));
    padding: 10px 0;
    margin: 10px 0 20px 0;
    outline: 0;
    width: 100%;
}

.signin-right-div .otpinput>div {
    margin: 0 20px 0 0;
    align-items: center;
}

.signin-right-div .otpinput>div:last-child {
    margin: 0;
}

.signin-right-div .otpinput>div input[type="text"],
.signin-right-div .otpinput>div input[type="number"] {
    text-align: center;
    margin: 10px 0 5px 0;
}

.signin-right-div img.country {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

.signin-right-div select.ccode {
    border: 0px solid rgba(106, 106, 106, 0.3);
    border-bottom: 0.5px solid rgba(106, 106, 106, 0.3);
    outline: 0;
    padding: 1px 15px 7px 15px;
    margin-right: 10px;
    color: #707070;
    line-height: 20px;
    -moz-appearance: none;
    /* for Chrome */
    -webkit-appearance: none;
}

.signin-right-div div p.resend {
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    color: #4274B0;
    margin-bottom: 15%;
    cursor: pointer;
}

.signin-right-div button {
    background: linear-gradient(0deg, #1953E7 -22.83%, #30D3E0 118.48%);
    box-shadow: 4px 4px 8px rgba(33, 131, 228, 0.26);
    border-radius: 5px;
    border: 0;
    height: 48px;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.171429px;
    color: #FFFFFF;
    margin-bottom: 10px;
}

.signin-right-div button img {
    margin-left: 10px;
}

.signin-right-div .signin h4.inst {
    text-align: center;
    margin: 10px 0 10% 0;
}

.signin-right-div .signin h4.inst a {
    color: #283B4C;
}

.signin-right-div .signin div.loginProgress {
    width: 100%;
    text-align: center;
}

.signin-right-div .signin img.loginProgress img {
    width: 70%;
}

.signin-right-div .signin h4.inst a span {
    color: #4274B0;
}

.signin-right-div .signtype {
    width: 100%;
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.signin-right-div .signtype>div {
    flex: 1;
    display: flex;
    justify-content: center;
    padding: 12px 0;
    font-size: 16px;
    line-height: 19px;
    color: #283B4C;
    cursor: pointer;
    border-bottom: 2px solid #0000;
}

.signin-right-div .signtype>div.active {
    border-bottom: 2px solid #FD6C73;
}

.signin-right-div div.separator {
    height: 3px;
    width: 80px;
    background-color: #FD6C73;
    margin: 12px 0;
}

.signin-right-div .inst-type-div>div {
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #707070;
    padding: 6px 8px;
    border: 0.5px solid rgba(0, 0, 0, 0.38);
    border-radius: 24px;
    margin: 0 8px 8px 0;
}

.signin-right-div .inst-type-div>div.active {
    color: #4274B0;
    border: 0.5px solid #4274B0;
    background-color: #F4F9FF;
}