.err-msg {
    color: red;
    font-size: 0.8em;
    margin-top: -25px;
    margin-bottom: 10px
}

/*Contact Css*/

.contact-header {
    display: flex;
    flex-direction: row;
    align-self: flex-start;
}

.contact-header img {
    width: 6px;
    height: 6px;
}

.contact-header .contact-title-content h4 {
    margin-left: 20px;
    left: 40px;
    margin-top: 0px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
}

.contact-header .contact-title-content h5 {


    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #707070;

    letter-spacing: 0.04em;
    left: 40px;

    margin-left: 20px;
    margin-top: 10px;
    line-height: 24px;
}

.contact-parent-container h3 {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;

    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    margin-top: 40px;
    margin-bottom: 40px;

    background: linear-gradient(146.68deg, #78DDD7 0%, #17A8E6 20%, #175CE6 80%), #707070;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;

}

/*Getintouch Css*/

.contact-div {
    padding: 10px 30px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: row;
    height: fit-content;
    /* width: 100%; */
}

.contact-div .contact-form-div {
    width: 40vw;
    margin-right: 18px;
    background-color: #FFF;
    flex-direction: column;
    align-items: center;

}

.contact-div .contact-form-div h1 {
    font-family: 'Poppins', 'Lato';
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 40px;
    color: #283B4C;
    padding: 26px;
}

.contact-div .contact-form-div img {
    width: 100%;
    position: relative;
    width: 46px;
    height: 46px;
}

.contact-container {
    display: flex;
    display: -ms-flexbox;
    flex-direction: column;
    align-self: auto;
    justify-content: space-between;
    padding: 30px 70px 17px;
    width: auto;
    height: fit-content;
}

.contact-div .contact-mail-div {
    /* width: fit-content; */
    /* height: fit-content; */

    /* background:red; */
background: #fff;
}
.form-container .send-div{
    margin-top: 5.4vh;
}
.contact-div .contact-mail-container {
    display: flex;
    display: -ms-flexbox;
    flex-direction: column;
    align-self: auto;
    justify-content: space-between;
    padding: 30px 31px;
    background: #FFFFFF;
}

.contact-div .contact-mail-container .contact-mail-header {
    display: flex;
    flex-direction: row;
    align-self: flex-start;
}

.contact-div .contact-mail-container.contact-mail-content {
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contact-mail-header img {
    width: 46px;
    height: 46px;
}

.contact-mail-header .contact-mail-title-content {
    width: fit-content;
}

.contact-mail-header .contact-mail-title-content h4 {
    margin-left: 20px;
    left: 40px;
    color: #000000;
    margin-top: 0px;

    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: #000000;
}

.contact-mail-header .contact-mail-title-content h5 {


    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    color: #707070;

    left: 40px;
    margin-left: 20px;
    margin-top: 10px;
    line-height: 24px;
}

.contact-div .contact-mail-container .row-spaceBetween {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.contact-mail-card {
    background: #EEF6FF;
    border-radius: 5px;
    height: 65px;
    width: 44%;
    padding: 10px 15px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

}

.contact-mail-card h4 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #283B4C;
}

.contact-mail-card img {
    width: 61.28px;
    height: 60px;
}

.contact-mail-card p:hover {
    color: #175CE5;
}

.contact-mail-card p {
    cursor: pointer;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #1E2228;
}

.contact-mail-content h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;

    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    margin-top: 20px;
    margin-bottom: 40px;

    background: linear-gradient(146.68deg, #78DDD7 0%, #17A8E6 20%, #175CE6 80%), #707070;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;

}

.contact-mail-div .contact-city-content {
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-self: auto;
    padding: 30px;
    margin-top: 20px;
    height: 35vh;
}

#cityLabel {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #283B4C;
    padding-bottom: 8px;
}

.contact-mail-div .contact-city-content .city-card {
box-sizing: border-box;
    width: 48%;
    padding: 12px;
    background: #EEF6FF;
    border-radius: 12px;
    margin-right: 20px;
}

.contact-mail-div .contact-city-content .city-card:nth-child(2) {
    margin-right: 0px;
}

.contact-city-content .row-spaceBetween {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: fit-content;
    /* background: red; */
}

.city-card img {
    width: 56px;
    height: 56px;
    left: 670px;
    top: 654px;
    padding: 20px;
}

.contact-mail-div .contact-city-content .city-card h4 {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    padding-left: 20px;
    padding-bottom: 7px;
    margin-top: -10px;
}

#delhi {
    background: linear-gradient(129.75deg, #DD78A8 11.89%, #E69317 53.2%, #E617B8 94.52%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

#pune {
    background: linear-gradient(146.68deg, #78DDD7 0%, #17A8E6 50%, #175CE6 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

#noida {
    background: linear-gradient(129.59deg, #7D32C8 14.55%, #464DF4 53.21%, #3854BA 90.3%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.contact-mail-div .contact-city-content .city-card h5 {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #1E2228;
    padding-left: 20px;
}

.contact-mail-div .contact-city-content .city-card p {
    display: flex;
    flex-wrap: wrap;
    padding-left: 20px;
    margin-top: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #1E2228;
}

.contact-mail-div .contact-city-content .city-card p:hover {
    color: #175CE5;
    cursor: pointer;
}

.row-spaceBetween {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

}

/* Responsive CSS By Vishakha */
@media only screen and (max-width: 400px) {
    .contact-div {
        padding: 0px;
        margin: 0px;
    }

    .contact-div .contact-form-div {
        width: 100%;
        height: auto;
        margin-right: 0px;
        padding: 12px;
        box-sizing: border-box;
    }
    .form-container,.form-container,.contact-container,.contact-container{
        padding: 0px;
    }
    .contact-city-content .row-spaceBetween{
        padding-bottom: 20px;
    }
    .form-container .form-group input,.form-container .form-group textarea {
        margin-right: 0px;
    }
    .contact-mail-content h3 {
        margin-bottom: 12px;
    }
    .contact-mail-card {
        width: 100%;
        padding-left: 8px;
        padding-right: 8px;
        box-sizing: border-box;
        margin-bottom: 8px;
    }
    .contact-mail-div .contact-city-content {
        padding: 12px;
    }
    .contact-mail-div .contact-city-content .city-card{
        margin-right: 0px;
    }
    .contact-div>div{
margin-top: 16px;
    }
}

@media only screen and (max-width: 600px) and (min-width:400px) {
    .contact-div {
        padding: 0px;
        margin: 0px;
    }

    .contact-div .contact-form-div {
        width: 100%;
        height: auto;
        margin-right: 0px;
        padding: 12px;
        box-sizing: border-box;
    }
    .form-container,.form-container,.contact-container,.contact-container{
        padding: 0px;
    }
    .contact-city-content .row-spaceBetween{
        padding-bottom: 20px;
    }
    .form-container .form-group input,.form-container .form-group textarea {
        margin-right: 0px;
    }
    .contact-mail-content h3 {
        margin-bottom: 12px;
    }
    .contact-mail-card {
        width: 100%;
        padding-left: 8px;
        padding-right: 8px;
        box-sizing: border-box;
        margin-bottom: 8px;
    }
    .contact-mail-div .contact-city-content {
        padding: 12px;
    }
    .contact-mail-div .contact-city-content .city-card{
        margin-right: 0px;
    }
    .contact-div>div{
margin-top: 16px;
    }
}

@media only screen and (max-width: 800px) and (min-width:600px) {
    .contact-div {
        padding: 0px;
        margin: 0px;
    }

    .contact-div .contact-form-div {
        width: 100%;
        height: auto;
        margin-right: 0px;
        padding: 12px;
        box-sizing: border-box;
    }
    .form-container,.form-container,.contact-container,.contact-container{
        padding: 0px;
    }
    .contact-city-content .row-spaceBetween{
        padding-bottom: 20px;
    }
    .form-container .form-group input,.form-container .form-group textarea {
        margin-right: 0px;
    }
    .contact-mail-content h3 {
        margin-bottom: 12px;
    }
    .contact-mail-card {
        width: 100%;
        padding-left: 8px;
        padding-right: 8px;
        box-sizing: border-box;
        margin-bottom: 8px;
    }
    .contact-mail-div .contact-city-content {
        padding: 12px;
    }
    .contact-mail-div .contact-city-content .city-card{
        margin-right: 0px;
    }
    .contact-div>div{
margin-top: 16px;
    }
}

@media only screen and (max-width: 1160px) and (min-width: 800px) {}

@media only screen and (max-width: 1400px)and (min-width: 1160px) {}

@media only screen and (max-width: 2880px)and (min-width: 1400px) {}

@media only screen and (min-width: 2880px) {}