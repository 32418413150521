.collapsible__container {

    background: #FFFFFF;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.16);
    border-radius: 8px;

    display: flex;
    flex-flow: column;
    overflow: hidden;
    padding: "5px 0px 5px 10px";
    margin-bottom: 10px;
}

.collapsible__title-container {

    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.collapsible__title_text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #283B4C;
}